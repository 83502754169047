<template>
    <div>
        <error-banner v-model="invalid" />
        <validation-observer ref="validator">
            <h3 class="mb-1 mt-3">Payer Information</h3>
            <p class="input-label mb-1">
                PAYER's name, street address, city or town, state or province,
                country, ZIP or foreign postal code, and telephone no.
            </p>
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <fly-row
                :fillTop="false"
                :schema="sections[1].schema"
                :data="sections[1].data"
            />
            <fly-col :schema="sections[2].schema" :data="sections[2].data" />
            <div class="my-3">
                <h3>Boxes 1, 2, 4</h3>
                <p>
                    Boxes 1 and 2 should not be filled at the same time
                </p>
            </div>
            <fly-col :schema="sections[3].schema" :data="sections[3].data" />
            <h3 class="mb-1 mt-4">Boxes 10a-11</h3>
            <fly-row :schema="sections[4].schema" :data="sections[4].data" />
        </validation-observer>
    </div>
</template>

<script>
import incomeMixin from './mixins';

export default {
    mixins: [incomeMixin],
    mounted() {
        this.$watch('sections.3.data.G1099_unemployment_compensation', () => {
            this.checkField1And2();
        });
        this.$watch('sections.3.data.G1099_state_tax_refunds', () => {
            this.checkField1And2();
        });
        this.checkField1And2();
    },
    methods: {
        checkField1And2() {
            let field1 = this.sections[3].data.G1099_unemployment_compensation;
            let field2 = this.sections[3].data.G1099_state_tax_refunds;

            if (field1) {
                this.sections[3].schema[1].disabled = true;
            } else {
                this.sections[3].schema[1].disabled = false;
            }
            if (field2) {
                this.sections[3].schema[0].disabled = true;
            } else {
                this.sections[3].schema[0].disabled = false;
            }
        },
    },
};
</script>
