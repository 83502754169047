<template>
    <div>
        <div class="col-lg-8 mx-auto">
            <h1>State Return Not Required</h1>
            <p class="lead">
                Good news, {{ context.first_name }} – you are not required to
                file a state tax return for {{ context.year }}.

                <br />
                <br />

                You'll still need to complete and file your federal tax return,
                though.
            </p>
            <p class="text-small text-muted">Please click next to continue.</p>
            <validation-observer ref="validator"> </validation-observer>
        </div>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'state_return_unneeded',
            noFormSubmit: true,
        };
    },
};
</script>
