<template>
    <div>
        <div class="d-flex justify-content-between">
            <slot name="back-btn"> </slot>
            <doc-upload
                :docType="docType"
                :on-submit="(file) => submitThisIncomeDocForParsing(file)"
                :getQRCodeLink="getQRCodeLink"
                :getMobileTaskStatus="getMobileTaskStatus"
                ref="uploader"
                @completion-acknowledged="acknowledgeParseCompletion"
            >
                <template v-slot:completion-view-title>
                    <i class="fas fa-check"></i> Success
                </template>
                <template v-slot:completion-view-body="{ scanMethod }">
                    <template v-if="scanMethod === FILE_UPLOAD">
                        The W2 you uploaded was scanned successfully.
                    </template>
                    <template v-if="scanMethod === TAKE_PHOTO">
                        The picture from your phone of your W2 was scanned
                        successfully.
                    </template>
                    <br />
                    <br />
                    <strong>Check over</strong> the information scanned by our
                    system <strong>before moving on</strong>.
                    <strong>Boxes 12 and 14</strong> are most likely to fail
                    because their format differs widely among forms.
                </template>
            </doc-upload>
        </div>
        <validation-observer ref="validator">
            <error-banner v-model="invalid" />
            <p class="mt-2 mb-1 text-danger">* Required</p>

            <h3 class="">Employer Type</h3>
            <fly-col
                class="mb-3"
                :schema="sections[0].schema"
                :data="sections[0].data"
            />
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="mb-3 d-lg-none">Employer Information</h3>
                    <fly-col
                        :schema="sections[1].schema"
                        :data="sections[1].data"
                    />
                    <fly-col
                        :schema="sections[2].schema"
                        :data="sections[2].data"
                        class=""
                    />
                    <fly-row
                        :fillTop="false"
                        :schema="sections[3].schema"
                        :data="sections[3].data"
                    />
                </div>

                <div class="col-lg-6">
                    <h3 class="my-3 d-lg-none">Boxes 1-6</h3>
                    <fly-row
                        align="end"
                        :schema="sections[4].schema"
                        :data="sections[4].data"
                    />
                    <fly-row
                        align="end"
                        :schema="sections[5].schema"
                        :data="sections[5].data"
                    />
                    <fly-row
                        align="end"
                        :schema="sections[6].schema"
                        :data="sections[6].data"
                    />
                </div>
            </div>

            <div class="row d-flex justify-content-end mb-4">
                <div class="col-lg-6">
                    <div class="row d-flex align-items-end">
                        <div class="col-lg-6 order-md-2">
                            <div class="d-lg-none my-3">
                                <h3>Boxes 12a-12d</h3>
                                <p>
                                    Copy in your codes. Some or all of these
                                    rows may be blank.
                                </p>
                            </div>
                            <fly-row
                                :schema="sections[7].schema"
                                :data="sections[7].data"
                            />
                            <fly-row
                                :schema="sections[8].schema"
                                :data="sections[8].data"
                            />
                            <fly-row
                                :schema="sections[9].schema"
                                :data="sections[9].data"
                            />
                            <fly-row
                                :schema="sections[10].schema"
                                :data="sections[10].data"
                            />
                        </div>

                        <div class="col-lg-6">
                            <div class="d-lg-none my-3">
                                <h3>Box 14</h3>
                                <p>
                                    Copy in your codes. Some or all of these
                                    rows may be blank.
                                </p>
                            </div>
                            <fly-row
                                fill-top
                                :schema="sections[11].schema"
                                :data="sections[11].data"
                            />
                            <fly-row
                                :fillTop="false"
                                :schema="sections[12].schema"
                                :data="sections[12].data"
                            />
                            <fly-row
                                :fillTop="false"
                                :schema="sections[13].schema"
                                :data="sections[13].data"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h3 class="mb-2 mt-4 d-lg-none">Boxes 15-20 — First Row</h3>
                <fly-row
                    row-breakpoint="lg"
                    :cols="[1, 2]"
                    @blur="handleRowBlur($event)"
                    :schema="sections[14].schema"
                    :data="sections[14].data"
                />
                <div class="d-lg-none mb-2 mt-4">
                    <h3>Boxes 15-20 — Second Row</h3>
                    <p>Only fill out this section if there are two states listed on your W2</p>
                </div>
                <fly-row
                    row-breakpoint="lg"
                    :cols="[1, 2]"
                    @blur="handleRowBlur($event)"
                    :schema="sections[15].schema"
                    :data="sections[15].data"
                    no-margin-bottom
                />
                <div style="font-size: 14px" class="d-none d-lg-block">
                <header-tooltip tooltip-font-size-inherit>
                    Do I need to fill out both rows above?
                    <template v-slot:content>
                        If there is only one state listed on your W2, fill
                        out only the first row, and leave the second row blank.
                        If there are two states listed on your W2, fill out one
                        row for each state on your W2.
                    </template>
                </header-tooltip>
                </div>
            </div>

            <div
                class="border-bottom w-100 mb-4 mt-1"
                v-if="formsetsPresent(formsets) || stateIncomeLowerThanFederal"
            ></div>

            <fly-col
                v-if="stateIncomeLowerThanFederal"
                :schema="sections[16].schema"
                :data="sections[16].data"
            />

            <div
                v-if="
                    formsets.visa_clarifiers && formsets.visa_clarifiers.length
                "
            >
                <fly-col
                    v-for="vc in formsets.visa_clarifiers"
                    :key="`visa-clarifier-${vc.context.visa_type}`"
                    :data="vc.sections[0].data"
                    :schema="vc.sections[0].schema"
                />
            </div>

            <template v-if="showVisaQualifiersIncomeDifferenceError">
                <div
                    class="text-danger lead"
                    v-if="visaQualifiersIncomeDifference != 0"
                >
                    <i class="fas fa-warning mr-2"> </i> Please ensure that the
                    income made on all visas adds up to ${{ fedIncome }}
                </div>
            </template>

            <modal v-model="showStateFederalDifference">
                <template v-slot:title>
                    State Wages Not Equal to Federal Wages
                </template>

                <template v-slot:body>
                    Your state, wages, tips, etc. (box 16) and your federal
                    wages, tips, and other compensation (box 1) are different.
                    Please confirm that this is indeed what is shown on your
                    W-2, and then click "Done" again to continue.
                </template>

                <template v-slot:footer>
                    <btn
                        @click="showStateFederalDifference = false"
                        app
                        size="sm"
                        color="info"
                    >
                        Cancel
                    </btn>

                    <btn
                        @click="
                            showStateFederalDifference = false;
                            differenceAcknowledged = true;
                        "
                        app
                        size="sm"
                        color="primary"
                    >
                        I Confirm
                    </btn>
                </template>
            </modal>

            <modal v-model="showNoState">
                <template v-slot:title> No State Entered </template>

                <template v-slot:body>
                    You didn't enter anything in boxes 15-17 of your W-2 state
                    returns. Generally, these boxes are required. If these boxes
                    are indeed empty on your W-2, click "I Confirm". If these
                    boxes include values on your W-2, please click "Back" and
                    correct your entries.
                </template>

                <template v-slot:footer>
                    <btn
                        @click="showNoState = false"
                        app
                        size="sm"
                        color="info"
                    >
                        Cancel
                    </btn>

                    <btn
                        @click="
                            showNoState = false;
                            noStateAcknowledged = true;
                            submitFunc(true);
                        "
                        app
                        size="sm"
                        color="primary"
                    >
                        I Confirm
                    </btn>
                </template>
            </modal>

            <modal v-model="unsupportedCode12">
                <template v-slot:title>
                    MSA and HSA Not Currently Supported
                </template>

                <template v-slot:body>
                    We are currently not supporting contributions to Archer
                    medical savings accounts (MSA) or Health Savings accounts
                    (HSA). We are expected to add these features in the near
                    future. In the meantime, you should file offline.
                </template>
            </modal>

            <modal v-model="showCaliLocalityModal">
                <template v-slot:title>
                    {{ currentCaliLocality }} Notice
                </template>

                <template v-slot:body>
                    If {{ currentCaliLocality }} was withheld from your wages by
                    a single employer at a rate of more than 1.0% of your social
                    security wages, you may have to contact the employer for a
                    refund.
                </template>
            </modal>
        </validation-observer>
    </div>
</template>

<script>
import incomeMixin from './mixins';
import { parseableIncomeDocMixin } from './mixins';
import taxFreeStates from './tax-free-states';
import docUpload from '@/components/utilities/doc-upload';
import { FILE_UPLOAD, TAKE_PHOTO } from '@/components/utilities/doc-upload';
import { strToNum } from '@/views/utilities';

export default {
    components: { docUpload },
    mixins: [incomeMixin, parseableIncomeDocMixin],
    props: { submitFunc: { type: Function, required: true } },
    data() {
        return {
            showCaliLocalityModal: false,
            currentCaliLocality: null,
            showStateFederalDifference: false,
            differenceAcknowledged: false,
            noStateAcknowledged: false,
            showNoState: false,
            unsupportedCode12: false,
            showVisaQualifiersIncomeDifferenceError: false,
            stateIncomeLowerThanFederal: false,
        };
    },
    computed: {
        visaQualifiersIncomeDifference() {
            if (
                !(
                    this.formsets.visa_clarifiers &&
                    this.formsets.visa_clarifiers.length
                )
            ) {
                return 0;
            }
            var income = this.fedIncome;
            var vc_amt = 0;
            for (var vc of this.formsets.visa_clarifiers) {
                vc_amt += strToNum(vc.sections[0].data.amt);
            }

            var diff = income - vc_amt;

            if (vc_amt < income) {
                if (Math.abs(diff) < 3) {
                    diff = 0;
                }
            }
            return diff;
        },
        fedIncome() {
            return strToNum(this.sections[4].data.W2_wages_tips);
        },
    },
    methods: {
        handleRowBlur(eventD) {
            var stateIncome = 0;

            if (
                (!this.sections[14].data.W2_stateInfoRow_a_state &&
                    !this.sections[15].data.W2_stateInfoRow_b_state) ||
                ((this.sections[14].data.W2_stateInfoRow_a_state_wages ==
                    null ||
                    this.sections[14].data.W2_stateInfoRow_a_state_wages ==
                        '') &&
                    (this.sections[15].data.W2_stateInfoRow_b_state_wages ==
                        null ||
                        this.sections[15].data.W2_stateInfoRow_b_state_wages ==
                            ''))
            ) {
                this.stateIncomeLowerThanFederal = false;
                return;
            }
            if (this.sections[14].data.W2_stateInfoRow_a_state) {
                var stateWages = strToNum(this.sections[14].data
                    .W2_stateInfoRow_a_state_wages);

                if (!isNaN(stateWages)) {
                    stateIncome += stateWages;
                }
            }
            if (this.sections[15].data.W2_stateInfoRow_b_state) {
                var stateWages = strToNum(this.sections[15].data
                    .W2_stateInfoRow_b_state_wages);

                if (!isNaN(stateWages)) {
                    stateIncome += stateWages;
                }
            }

            this.stateIncomeLowerThanFederal = stateIncome < this.fedIncome;
        },
        code12IsValid() {
            var codes = [
                this.sections[7].data.W2_box12a_code,
                this.sections[8].data.W2_box12b_code,
                this.sections[8].data.W2_box12c_code,
                this.sections[9].data.W2_box12d_code,
            ];
            for (var code of codes) {
                if (['R', 'W'].includes(code)) {
                    return false;
                }
            }

            return true;
        },
        visaQualifiersValid() {
            return this.visaQualifiersIncomeDifference === 0;
        },
        stateChoicesValid() {
            if (
                this.sections[14].data.W2_stateInfoRow_a_state &&
                !taxFreeStates.includes(
                    this.sections[14].data.W2_stateInfoRow_a_state,
                )
            ) {
                var federalWages = strToNum(this.sections[4].data.W2_wages_tips);
                var stateWages = strToNum(
                    this.sections[14].data.W2_stateInfoRow_a_state_wages,
                );
                if (this.sections[15].data.W2_stateInfoRow_b_state_wages) {
                    stateWages += strToNum(
                        this.sections[15].data.W2_stateInfoRow_b_state_wages,
                    );
                }
                if (
                    stateWages != federalWages &&
                    !this.differenceAcknowledged
                ) {
                    return false;
                }
            }
            return true;
        },
        noStateValid() {
            if (!this.noStateAcknowledged) {
                if (
                    !this.sections[14].data.W2_stateInfoRow_a_state &&
                    !this.sections[15].data.W2_stateInfoRow_b_state
                ) {
                    return false;
                }
            }
            return true;
        },
        runCustomValidation(payload, shouldValidate) {
            this.handleStateRowBInput(this.sections[15].data, true);
            this.handleStateRowAInput(this.sections[14].data, true);

            if (shouldValidate) {
                if (!this.visaQualifiersValid()) {
                    this.showVisaQualifiersIncomeDifferenceError = true;
                    return false;
                }
                if (!this.code12IsValid()) {
                    this.unsupportedCode12 = true;
                    return false;
                }
                if (!this.noStateValid()) {
                    this.showNoState = true;
                    return false;
                }
                if (!this.stateChoicesValid()) {
                    this.showStateFederalDifference = true;
                    return false;
                }
            } else {
                payload.is_completed =
                    payload.is_completed &&
                    this.visaQualifiersValid() &&
                    this.code12IsValid() &&
                    this.noStateValid() &&
                    this.stateChoicesValid();
            }
            return true;
        },
        initiateCaliforniaLocalityField(row) {
            let newChoices = [
                ['SDI', 'SDI'],
                ['VPDI', 'VPDI'],
                ['Others', 'Others'],
            ];
            if (row == 'a') {
                this.sections[14].schema[6].choices = newChoices;
                this.sections[14].schema[6].type = 'select-input';
            } else if (row == 'b') {
                this.sections[15].schema[6].choices = newChoices;
                this.sections[15].schema[6].type = 'select-input';
            }
        },
        clearLocalityFieldFormatting(row) {
            if (row == 'a') {
                this.sections[14].schema[6].type = 'text-input';
            } else if (row == 'b') {
                this.sections[15].schema[6].type = 'text-input';
            }
        },
        promptLocalityNotice(locality) {
            if (locality == 'VPDI' || locality == 'SDI') {
                this.currentCaliLocality = locality;
            }
        },
        addRequirementStateWages(state, section) {
            let rules;
            if (!state || taxFreeStates.includes(state)) {
                rules = '';
            } else {
                rules = 'required';
            }
            this.sections[section].schema[2].validation_rules = rules;
            this.sections[section].schema[3].validation_rules = rules;
        },
        shouldShowVPDI(section) {
            if (section.data.W2_stateInfoRow_a_state == 'California') {
                return true;
            } else return false;
        },
        initiateConditionalFormatting() {
            if (
                this.sections != null &&
                this.sections.length &&
                'W2_payer_type' in this.sections[0].data
            ) {
                if (this.shouldShowVPDI(this.sections[14])) {
                    this.initiateCaliforniaLocalityField('a');
                }
                if (this.shouldShowVPDI(this.sections[15])) {
                    this.initiateCaliforniaLocalityField('b');
                }
            } else {
                setTimeout(this.initiateConditionalFormatting, 50);
                return;
            }
        },
        handleStateRowAInput(val, onSubmit) {
            var rules = '';
            for (var item in val) {
                if (val[item]) {
                    rules = 'required';
                }
            }
            this.sections[14].schema[0].validation_rules = rules;
            if (onSubmit) {
                if (
                    !this.sections[14].data.W2_stateInfoRow_a_state &&
                    rules == 'required'
                ) {
                    this.$refs.validator.setErrors({
                        W2_stateInfoRow_a_state: ['This field is required.'],
                    });
                    this.$refs.validator.flags.valid = false;
                } else {
                    this.$refs.validator.reset();
                }
            }
        },
        handleStateRowBInput(val, onSubmit) {
            var rules = '';
            for (var item in val) {
                if (val[item]) {
                    rules = 'required';
                }
            }
            this.sections[15].schema[0].validation_rules = rules;
            if (onSubmit) {
                if (
                    !this.sections[15].data.W2_stateInfoRow_b_state &&
                    rules == 'required'
                ) {
                    this.$refs.validator.setErrors({
                        W2_stateInfoRow_b_state: ['This field is required.'],
                    });
                    this.$refs.validator.flags.valid = false;
                } else {
                    this.$refs.validator.reset();
                }
            }
        },
    },
    mounted() {
        var shouldShowScanModal = true;
        this.sections.forEach(section => {
            Object.entries(section.data).forEach(entry => {
                if (entry[1]) {
                    shouldShowScanModal = false;
                }
            });
        });
        if (shouldShowScanModal) {
            this.$refs.uploader.showDecideIfScanView();
        }

        this.initiateConditionalFormatting();
        this.handleRowBlur();

        this.$watch('sections.14.data.W2_stateInfoRow_a_state', (newVal) => {
            if (newVal == 'California') {
                this.initiateCaliforniaLocalityField('a');
            } else {
                this.clearLocalityFieldFormatting('a');
            }
        });
        this.$watch('sections.15.data.W2_stateInfoRow_b_state', (newVal) => {
            if (newVal == 'California') {
                this.initiateCaliforniaLocalityField('b');
            } else {
                this.clearLocalityFieldFormatting('b');
            }
        });

        this.$watch('sections.14.data.W2_stateInfoRow_a_state', (val) => {
            this.addRequirementStateWages(val, 14);
        });
        this.$watch('sections.15.data.W2_stateInfoRow_b_state', (val) => {
            this.addRequirementStateWages(val, 15);
        });
        this.$watch(
            'sections.15.data',
            (val) => {
                this.handleStateRowBInput(val, false);
            },
            { deep: true },
        );
        this.$watch(
            'sections.14.data',
            (val) => {
                this.handleStateRowAInput(val, false);
            },
            { deep: true },
        );
        this.$watch(
            'sections.7.data',
            (newVal) => {
                if (['R', 'W'].includes(newVal.W2_box12a_code)) {
                    this.unsupportedCode12 = true;
                }
            },
            { deep: true },
        );
        this.$watch(
            'sections.8.data',
            (newVal) => {
                if (['R', 'W'].includes(newVal.W2_box12b_code)) {
                    this.unsupportedCode12 = true;
                }
            },
            { deep: true },
        );
        this.$watch(
            'sections.9.data',
            (newVal) => {
                if (['R', 'W'].includes(newVal.W2_box12c_code)) {
                    this.unsupportedCode12 = true;
                }
            },
            { deep: true },
        );
        this.$watch(
            'sections.10.data',
            (newVal) => {
                if (['R', 'W'].includes(newVal.W2_box12d_code)) {
                    this.unsupportedCode12 = true;
                }
            },
            { deep: true },
        );
    },
    created() {
        this.docType = 'W2';
        this.FILE_UPLOAD = FILE_UPLOAD;
        this.TAKE_PHOTO = TAKE_PHOTO;
    },
};
</script>
