<template>
    <div>
        <div class="interview-header">
            Add a <strong>shipping service</strong>
            <div class="interview-subheader">
                <div v-if="0">
                    <p>
                        You are required to mail an 8843 form to the IRS for
                        yourself and for every one of your family members on a
                        dependent visa.
                    </p>
                    <p class="mb-5">
                        Thriftax offers a shipping service (along with a
                        tracking number) of 8843 form(s) for only $16.90 for the
                        first form and an additional $9.90 for each dependent’s
                        form.
                    </p>
                    <p class="font-weight-bold">
                        Yes, add a shipping service only for my personal 8843.
                        $16.90
                    </p>
                    <p class="font-weight-bold">
                        Yes, add a shipping service for myself and my
                        dependents.
                        {{ tot }}
                    </p>
                    <p class="font-weight-bold">
                        No thanks, I’m not interested in a shipping service.
                    </p>
                </div>
                <div v-else>
                    You are required to mail your 8843 form to the IRS. Thriftax
                    offers a shipping service, along with a tracking number, for
                    only $16.90.
                </div>
            </div>
        </div>
        <validation-observer ref="validator">
            <div class="d-flex justify-content-center">
                <fly-input
                    :field="sections[0].schema[0]"
                    :data="sections[0].data"
                />
            </div>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'is_shipping_8843',
        };
    },
    created() {
        this.sections[0].schema[0].larger = true;
    },
};
</script>
