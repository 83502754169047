<template>
    <div>
        <div class="interview-header">
            Have you ever
            <strong>applied for a green card or U.S. citizenship?</strong>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'applied_for_green_card',
        };
    },
};
</script>
