<template>
    <div>
        <div class="interview-header">
            Tell us a little
            <strong> about yourself</strong>
            <div class="interview-subheader" v-if="context.previous_customer">
                You filed with us previously, so we have a lot of the
                information required for this tax return already.  You'll just
                need to review this information and answer a few more questions.
            </div>
        </div>
        <validation-observer ref="validator">
            <fly-row
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-row>
            <fly-row
                :schema="sections[1].schema"
                :data="sections[1].data"
            />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'intro',
        };
    }
};
</script>
