<template>
  <div>
    <div class="interview-header">
      About your
      <strong>tax payments last year</strong>

      <div class="interview-subheader">
        You told us you filed taxes last year — the IRS needs to know a little
        about that return. You may also be eligible for tax refunds.
      </div>
    </div>
    <validation-observer ref="validator">
      <fly-col :schema="sections[0].schema" :data="sections[0].data" />
      <div v-if="formsets.state_overpayments">
        <p class="interview-header-2">
          You also told us that you filed state taxes in
          <strong>{{ context.year - 1 }}</strong
          >. Please list <strong> all of the states</strong> for which you
          <strong>filed state taxes</strong>
        </p>
        <div
          v-for="payment in stateOverpaymentsFiltered"
          class="mb-4"
          :key="payment.displayId"
        >
          <div class="formset-card">
            <fly-col
              :schema="payment.sections[0].schema"
              :data="payment.sections[0].data"
            />
            <fs-delete @click="dirty = true" v-model="payment.delete">
              Remove state
            </fs-delete>
          </div>
        </div>
        <btn disable-on-forms-purchased @click="addForm" app size="sm" color="info"
          ><i class="fas fa-plus mr-2"></i> Add State</btn
        >
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from "@/views/interview/formset";
import baseInterviewMixin from "@/views/interview/mixins";

export default {
  mixins: [baseInterviewMixin],
  data() {
    return {
      pageName: "previous_return_info"
    };
  },
  computed: {
    stateOverpaymentsFiltered() {
      return filterDeletedForms({
        formset: this.formsets.state_overpayments
      });
    }
  },
  methods: {
    addForm() {
      addFormsetItem(this.formsets.state_overpayments);
    }
  }
};
</script>
