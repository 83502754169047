<template>
    <div>
        <div class="interview-header">
            Good news, you
            <strong>do not owe taxes</strong> for any of the following tax
            returns
            <div class="interview-subheader">
                You'll still need to send your tax forms to the authorities
                though
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <span>
                <div
                    class="media"
                    v-for="ret in context.returns"
                    :key="ret.title"
                >
                    <span
                        class="icon icon-sm icon-primary icon-circle mt-1 mr-3"
                    >
                        <i class="fas fa-check fa-lg text-light"></i>
                    </span>
                    <div class="media-body pt-1">
                        <h2>
                            {{ ret.title }}
                        </h2>
                    </div>
                </div>
            </span>
        </div>
        <validation-observer ref="validator"> </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'no_diff_return',
            noFormSubmit: true,
        };
    },
};
</script>
