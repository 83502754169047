<template>
    <div>
        <validation-observer ref="validator">
            <div class="interview-header">
                About your
                <strong>spouse</strong>
            </div>
            <fly-row :schema="sections[0].schema" :data="sections[0].data" />
            <fly-col :schema="sections[1].schema" :data="sections[1].data" />
            <fly-col :schema="sections[2].schema" :data="sections[2].data" />
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title> Filing Jointly With Your Spouse</template>

            <template v-slot:body>
                If you're married filing jointly, both you and your spouse can
                file as a U.S. citizen, even though only your spouse is a U.S.
                citizen. Please use a tax-filing software that supports U.S.
                citizens. If you made a mistake, correct your answers and
                continue filing with Thriftax.
            </template>
        </modal>

        <modal v-model="showSpouseNotice">
            <template v-slot:title> Your spouse needs to file</template>

            <template v-slot:body>
                Your spouse needs to file a tax return of their own due to their
                income. If they join Thriftax, they can file their tax return
                the same way that you are doing currently.
            </template>
            <template v-slot:footer>
                <btn @click="acknowledge('')" app color="secondary" small>
                    Ok
                </btn>
            </template>
        </modal>

        <modal v-model="showSpouseNoticeVisa">
            <template v-slot:title> Your spouse needs to file</template>

            <template v-slot:body>
                Since your spouse is not on a dependent visa, they will need to
                file with us separately. You may invite your spouse to open a
                separate account on Thriftax and receive a referral $ bonus.
            </template>

            <template v-slot:footer>
                <btn @click="acknowledge('Visa')" app color="secondary" small>
                    Ok
                </btn>
            </template>
        </modal>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'marital_status',

            unsupported: false,

            acknowledged: false,
            acknowledgedVisa: false,

            showSpouseNotice: false,
            showSpouseNoticeVisa: false,
        };
    },
    methods: {
        aboveIncomeCutoff() {
            return (
                this.sections[2].data.had_income == true ||
                this.sections[2].data.had_income == 'true'
            );
        },
        nonDepVisa() {
            var needsFilingVisas = ['Other visa'];
            var is_US_citizen = this.sections[2].data.is_US_citizen;
            return (
                this.boolEq(false, is_US_citizen) &&
                needsFilingVisas.includes(this.sections[2].data.visa_type)
            );
        },
        acknowledge(issue) {
            this[`acknowledged${issue}`] = true;
            this.$store.commit('changeDirection', 'next');
            this.$store.dispatch('submitAndMoveOn', {
                shouldSubmit: true,
                form: this,
                direction: 'next',
            });
        },
        continueSubmit() {
            if (
                this.boolEq(true, this.sections[2].data.is_US_citizen) &&
                this.sections[2].data.relationship_filing_status == 'joint'
            ) {
                this.unsupported = true;
                return false;
            }

            if (this.isDirty()) this.isSubmitting = true;
            else this.isSubmitting = false;

            if (this.aboveIncomeCutoff() && !this.acknowledged) {
                this.showSpouseNotice = true;
                return false;
            } else if (this.nonDepVisa() && !this.acknowledgedVisa) {
                this.showSpouseNoticeVisa = true;
                return false;
            }

            return true;
        },
    },
    created() {
        this.$watch('sections.2.data.relationship_filing_status', (val) => {
            if (val == 'joint') {
                this.unsupported = true;
            }
        });
    },
};
</script>
