<template>
    <div>
        <div class="interview-header">
            Did you
            <strong>pay interest on a student loan</strong>
            in {{ context.year }}?
            <header-tooltip>
                What's a student loan?
                <template v-slot:content>
                    A qualified student loan is a loan you took out solely to
                    pay for higher education expenses (tuition, fees and other
                    related expenses that are required for enrollment or
                    attendance)
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'paid_interest_on_student_loan',
        };
    },
};
</script>
