<template>
    <div>
        <error-banner v-model="invalid" />
        <transition :name="direction" mode="out-in">
            <div v-if="stage === 'parent'" key="parent">
                <div class="interview-header">
                    Which bank or brokerage sent you a 1099-B for these sales?

                    <div class="interview-subheader">
                        If you have more than one bank / brokerage, add separate
                        items for each one
                    </div>
                </div>
                <validation-observer ref="validator">
                    <fly-col
                        :data="sections[0].data"
                        :schema="sections[0].schema"
                    />
                </validation-observer>
            </div>

            <div v-else-if="stage === 'children'" key="children">
                <template v-if="editMode">
                    <template v-if="editModeLoading">
                        <interview-page-loading :interview-height="false" />
                    </template>
                    <template v-else>
                        <validation-observer ref="validator">
                            <fly-col
                                :data="editEntrySections[0].data"
                                :schema="editEntrySections[0].schema"
                            />
                            <fly-row
                                :data="editEntrySections[1].data"
                                :schema="editEntrySections[1].schema"
                            />
                            <fly-row
                                :data="editEntrySections[2].data"
                                :schema="editEntrySections[2].schema"
                            />
                            <fly-col
                                :data="editEntrySections[3].data"
                                :schema="editEntrySections[3].schema"
                            />
                        </validation-observer>
                    </template>
                </template>
                <template v-else>
                    <h2>Your {{ sections[0].data.payer_name }} Sales</h2>
                    <table
                        class="table material-table hoverable-rows table-fixed"
                    >
                        <col class="w-35" />
                        <col class="w-20" />
                        <col class="w-20" />
                        <col class="w-10" />
                        <col class="w-10" />
                        <thead class="thead-light">
                            <tr>
                                <th>Description</th>
                                <th>Proceeds</th>
                                <th>Cost</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="entry in entries"
                                :key="entry.id"
                                @click="enterEditMode(entry.id)"
                            >
                                <td class="no-wrap-ell align-middle">
                                    {{
                                        entry.description
                                            ? entry.description
                                            : 'Untitled'
                                    }}
                                </td>

                                <td>
                                    {{
                                        formatDollarAmount(
                                            entry.proceeds ? entry.proceeds : 0,
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        formatDollarAmount(
                                            entry.cost ? entry.cost : 0,
                                        )
                                    }}
                                </td>
                                <td>
                                    <badge
                                        class="m-0"
                                        color="success"
                                        v-if="entry.is_completed"
                                    >
                                        Completed
                                    </badge>

                                    <badge color="danger" v-else>
                                        Not Completed
                                    </badge>
                                </td>
                                <td
                                    class="d-flex justify-content-end ctrl-cell"
                                >
                                    <btn
                                        @click.stop="deleteEntry(entry.id)"
                                        class="btn-ghost-danger btn-icon-sm"
                                        color=""
                                        disable-on-forms-purchased
                                    >
                                        <i class="fas fa-trash-alt"> </i>
                                    </btn>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <btn
                        @click="createEntry"
                        :loading="createEntryLoading"
                        color="info"
                        app
                        size="sm"
                        disable-on-forms-purchased
                        ><i class="fas fa-plus mr-2"></i>Add sale</btn
                    >
                </template>
            </div>
        </transition>

        <div class="ctrl-btn-wrapper">
            <back-button
                @click="switchStages('back')"
            />
            <next-button :text="stage == 'children' ? 'Done' : 'Next'" @click="switchStages('next')" />
        </div>
    </div>
</template>

<script>
import nextButton from '@/components/interview/next-button';
import backButton from '@/components/interview/back-button';
import incomeMixin from './mixins';
import cleanFormData from '@/api/clean-form-data';
import interviewPageLoading from '@/components/interview/page-loading';
import { cleanSubmissionDataWithFormsets } from '@/api/clean-submission-data';

export default {
    props: { showBackBtn: { type: Boolean } },
    mixins: [incomeMixin],
    data() {
        return {
            direction: '',

            showCtrlBtns: false, // for parent component

            stage: '',
            valid: { parent: false },

            entries: [],
            entriesLoading: false,

            createEntryLoading: false,

            editMode: false,
            editModeLoading: false,

            editEntrySections: [],
            editEntryId: null,
        };
    },

    watch: {
        stage(to) {
            if (to === 'children') {
                this.fetchChildrenStageData();
            }
        },
    },

    created() {
        this.stage = this.formData.nonFormFields.current_stage;
        this.fetchChildrenStageData();
    },

    methods: {
        async submit(shouldValidate) {
            /* When the back button in the parent component is
             * clicked, this method is called. Save whatever needs to
             * be saved without validating, as this will never be
             * called with shouldValidate asserted */

            if (shouldValidate)
                throw 'Should validate should not be asserted in MultiB1099 submit method';

            if (this.stage === 'parent') {
                let valid = (await this.submitParentStage(shouldValidate))[1];

                if (this.childrenStageIsValid() && valid) {
                    this.updateParentCompletionStatus(true);
                }
            } else if (this.stage === 'children') {
                this.updateParentCompletionStatusChildrenStage();
            }

            return true;
        },
        async exitEditMode(id) {
            this.editMode = false;
            this.$emit('update:showBackBtn', true);
        },
        async enterEditMode(id) {
            this.editModeLoading = true;
            var response = await this.$store.getters.client.get(
                '/multi-b1099-entry/',
                {
                    params: { id },
                },
            );
            this.editEntrySections = cleanFormData(response.data).sections;

            this.editEntryId = id;

            this.editModeLoading = false;
            this.$emit('update:showBackBtn', false);
            this.editMode = true;
        },
        async deleteEntry(id) {
            var response = await this.$store.getters.client.delete(
                '/multi-b1099-entry/',
                {
                    params: { id },
                },
            );
            this.$store.commit('updateLastCompletedPage', 'income');

            this.entries = this.entries.filter((entry) => entry.id != id);
        },
        async createEntry() {
            this.createEntryLoading = true;

            var response = await this.$store.getters.client.post(
                '/multi-b1099-entry/',
                null,
                {
                    params: { multi_b1099: this.context.id },
                },
            );

            this.entries.push(response.data);
            this.$store.commit('updateLastCompletedPage', 'income');

            this.createEntryLoading = false;
        },
        async fetchChildrenStageData() {
            this.entriesLoading = true;

            var response = await this.$store.getters.client.get(
                '/multi-b1099-entry/',
                {
                    params: { multi_b1099: this.context.id },
                },
            );

            this.entries = response.data;

            this.entriesLoading = false;

        },
        async switchStages(direction) {
            var shouldValidate = direction === 'next' ? true : false;

            this.direction = direction;
            if (this.stage === 'parent') {
                let valid = (await this.submitParentStage(shouldValidate))[0];

                if (direction === 'next') {
                    if (valid) {
                        this.stage = 'children';
                    }
                }
                if (direction === 'back') {
                    this.$emit('close');
                }
            } else if (this.stage === 'children') {
                if (this.editMode) {
                    if (await this.submitEditEntry(shouldValidate)) {
                        this.exitEditMode();
                    }
                } else {
                    if (direction === 'back') {
                        this.stage = 'parent';
                    } else if (direction === 'next') {
                        this.updateParentCompletionStatusChildrenStage();
                        this.$emit('close');
                    }
                }
            }
        },
        updateParentCompletionStatus(isCompleted) {
            this.$store.commit('updateDocInDocs', {
                id: this.context.id,
                is_completed: isCompleted,
            });
            return this.$store.getters.client.put(
                '/income-doc/',
                { is_completed: isCompleted },
                { params: { id: this.context.id } },
            );
        },

        updateParentCompletionStatusChildrenStage() {
            var isCompleted = this.childrenStageIsValid();

            return this.updateParentCompletionStatus(isCompleted);
        },

        childrenStageIsValid() {
            var valid = true;

            if (!this.entries.length) valid = false;

            for (var entry of this.entries) {
                if (!entry.is_completed) {
                    valid = false;
                }
            }
            return valid;
        },
        async submitEditEntry(shouldValidate) {
            var entry = this.entries.filter(entry => entry.id == this.editEntryId)[0];
            
            if (this.earlyReturn(shouldValidate, entry.is_completed)) return true;

            let payload = cleanSubmissionDataWithFormsets({
                sections: this.editEntrySections,
                formsets: {},
            });


            if (!(await this.runBaseValidation(payload, shouldValidate)))
                return false;

            try {
                await this.$store.getters.client.put(
                    '/multi-b1099-entry/',
                    payload,
                    { params: { id: this.editEntryId } },
                );
            } catch (e) {
                console.dir(e);
                return false;
            }

            this.$store.commit('updateLastCompletedPage', 'income');

            this.errorOnPreviousSubmit = false;

            await this.fetchChildrenStageData();

            await this.updateParentCompletionStatusChildrenStage();

            return true;
        },
        async submitParentStage(shouldValidate) {
            if (this.earlyReturn(shouldValidate, this.stage !== 'parent'))
                return [true, true];

            let payload = cleanSubmissionDataWithFormsets({
                sections: this.sections,
                formsets: this.formsets,
            });

            var valid = await this.runBaseValidation(payload, shouldValidate);

            if (!(await this.runBaseValidation(payload, shouldValidate)))
                return [false, false];

            if (payload.is_completed === false) {
                payload.current_stage = 'parent';
            } else {
                payload.current_stage = 'children';
            }

            await this.completeSubmit(payload);

            return [true, payload.is_completed];
        },
    },
    components: {
        interviewPageLoading,
        nextButton,
        backButton,
    },
};
</script>
