<template>
    <div>
        <div class="interview-header">
            <strong>When </strong>did you make your <strong>income</strong>?
            <div class="interview-subheader font-size-base">
                You are deemed a part-year resident of California for the year
                of
                {{ context.year }}. The state of California requires you to
                distinguish between the income you made before and after you
                became a California resident. Please note that the income
                generated in the period you were a California resident will be
                taxed.
                <br />
                <br />
                For income types that are hard to relate to a specific period,
                you may divide the income proportionately to the period that you
                were a California resident in {{ context.year }}.

                <tooltip>
                    Show me an example

                    <template v-slot:content>
                        Based on your information, you were a California
                        non-resident for
                        {{ Math.round(fracNR * 100) }}% of
                        {{ context.year }} and a California resident for
                        {{ 100 - Math.round(fracNR * 100) }}%. If, for example,
                        you had $100 of foreign interest income during
                        {{ context.year }} that is hard to relate to a specific
                        period, you would enter $100 x {{ fracNR.toFixed(2) }} =
                        ${{ Math.round(100 * fracNR) }} into "Amount Earned
                        Before {{ context.residency_date }}" and enter $100 x
                        {{ 1 - fracNR.toFixed(2) }} = ${{
                            Math.round(100 * (1 - fracNR))
                        }}
                        into "Amount Earned After {{ context.residency_date }}"
                    </template>
                </tooltip>
            </div>
        </div>
        <validation-observer ref="validator">
            <div v-if="docs.length">
                <table class="table table-fixed">
                    <col class="w-20" />
                    <col class="w-30" />
                    <col class="w-30" />
                    <col class="w-20" />
                    <thead class="thead-light">
                        <tr>
                            <th>Income Source</th>
                            <th>
                                Amount Generated Before
                                {{ context.residency_date }}
                            </th>
                            <th>
                                Amount Generated After
                                {{ context.residency_date }}
                            </th>
                            <th>Amount All Year</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="doc in docs" :key="doc.id">
                            <th class="no-wrap-ell align-middle">
                                {{ displayDocType(doc.type) }} –
                                {{ doc.payer_name }}
                            </th>

                            <td>
                                <fly-row
                                    no-margin-bottom
                                    :data="doc.sections[0].data"
                                    :schema="doc.sections[0].schema.slice(0, 1)"
                                />
                            </td>
                            <td>
                                <fly-row
                                    no-margin-bottom
                                    :data="doc.sections[0].data"
                                    :schema="doc.sections[0].schema.slice(1)"
                                />
                            </td>
                            <td>
                                ${{ doc.amount }}
                                <div class="small">
                                    <span
                                        class="text-danger"
                                        v-if="
                                            doc.amount >
                                            getAgg(doc.sections[0].data)
                                        "
                                        >(You still need ${{
                                            doc.amount -
                                            getAgg(doc.sections[0].data)
                                        }}
                                        more)</span
                                    >
                                    <span
                                        class="text-danger"
                                        v-else-if="
                                            doc.amount <
                                            getAgg(doc.sections[0].data)
                                        "
                                        >(You are ${{
                                            getAgg(doc.sections[0].data) -
                                            doc.amount
                                        }}
                                        over)</span
                                    >
                                    <span class="text-success" v-else
                                        >Looks good!</span
                                    >
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="div1099s.length">
                <div class="interview-header-2">
                    Your <strong>Dividends</strong> and
                    <strong>Capital Distributions</strong>
                </div>
                <table class="table table-fixed">
                    <col class="w-25" />
                    <col class="w-15" />
                    <col />
                    <thead class="thead-light">
                        <tr>
                            <th>Form and Payer</th>
                            <th>Amount</th>
                            <th>
                                The amounts reported on this form were paid to
                                you after {{ context.residency_date }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="div1099 in div1099s" :key="div1099.id">
                            <th class="no-wrap-ell">
                                {{ div1099.type }} – {{ div1099.payer_name }}
                            </th>
                            <td>${{ div1099.amount }}</td>
                            <td class="d-flex justify-content-end">
                                <fly-input
                                    :data="div1099.sections[0].data"
                                    :field="div1099.sections[0].schema[0]"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { displayDocType } from '@/views/utilities';
import { strToNum } from '@/views/utilities';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'cali_time_of_income',
        };
    },
    computed: {
        div1099s() {
            return this.formsets.Documents.filter((doc) => {
                return doc.is_dividends;
            });
        },
        docs() {
            return this.formsets.Documents.filter((doc) => {
                return !doc.is_dividends;
            });
        },
        fracNR() {
            var residencyDate = new Date(this.context.residency_date);
            var start = new Date(this.context.year, 0, 0);
            var diff = residencyDate - start;
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);
            return day / 365;
        },
    },
    methods: {
        displayDocType(type) {
            return displayDocType(type);
        },
        getAgg(data) {
            let tot = 0;
            if (data.amount_gen_before) {
                tot += strToNum(String(data.amount_gen_before).replace(',', ''))
            }
            if (data.amount_gen_after) {
                tot += strToNum(String(data.amount_gen_after).replace(',', ''))
            }
            return tot;
        },
        runCustomValidation() {
            this.docs.forEach((doc) => {
                if (this.getAgg(doc.sections[0].data) != doc.amount) {
                    return false;
                }
            });
            return true;
        },
    },
};
</script>
