function addFormsetItem(items) {
    let newForm = {};
    let copied = items[0];
    newForm = JSON.parse(JSON.stringify(copied));
    for (let i = 0; i < newForm.sections.length; i++) {
        for (let key in newForm.sections[i].data) {
            newForm.sections[i].data[key] = null;
        }
    }
    let newId = 0;
    for (var item of items) {
        if (item.sections[0].schema[0].id >= newId) {
            newId = item.sections[0].schema[0].id + 1;
        }
    }
    newForm.sections.forEach((section) => {
        section.schema.forEach((field) => {
            field.id = newId;
            field.invalid = false;
        });
    });

    newForm.TEMPLATE = false;
    newForm.delete = false;

    /* id is the id for backend logic. This id will be null if form
     * has just been created. It will have a value if the form had
     * been created previously and was fetched from the backend.
     * displayId is used in display logic such as formset rendering
     * and DOM id's  */
    newForm.id = null;
    newForm.displayId = newId;

    if (newForm.errors && newForm.errors.length) {
        newForm.errors = [];
    }
    items.push(newForm);
}

function cleanFormsetOnSubmit(params) {
    var items = params.formset;
    var response = params.response;
    var modelRelatedName = params.modelRelatedName;

    if (!items) {
        return items;
    }
    var newItems = [];
    var template = null;
    for (var i = 0; i < items.length; i++) {
        if (items[i].TEMPLATE) {
            template = items[i];
        } else if (!items[i].delete) {
            newItems.push(items[i]);
        }
    }
    for (var i = 0; i < response.data.formsets[modelRelatedName].length; i++) {
        newItems[i].id = response.data.formsets[modelRelatedName][i].id;
    }
    if (template != null) {
        newItems.push(template);
    }
    return newItems;
}

function filterDeletedForms(params) {
    return params.formset.filter((form) => {
        return !form.delete;
    });
}

export { addFormsetItem, cleanFormsetOnSubmit, filterDeletedForms };
