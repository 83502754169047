import { cleanSubmissionDataWithFormsets } from '@/api/clean-submission-data';
import formsetsPresent from './formsets_present';

var incomeMixin = {
    data() {
        return {
            invalid: false,
            sections: [],
            formsets: {},
            context: {},
            errorOnPreviousSubmit: false,
        };
    },
    props: {
        formData: {},
        id: null,
    },

    created() {
        this.sections = this.formData.sections;
        this.formsets = this.formData.formsets;
        this.context = this.formData.context;
    },
    methods: {
        formsetsPresent(obj) {
            return formsetsPresent(obj);
        },

        earlyReturn(shouldValidate, completed) {
            if (
                !this.errorOnPreviousSubmit &&
                !this.$refs.validator.flags.dirty &&
                (!shouldValidate || completed)
            ) {
                return true;
            }

            return false;
        },

        async runBaseValidation(payload, shouldValidate) {
            await this.$refs.validator.validate();

            if (shouldValidate) {
                if (!this.$refs.validator.flags.valid) {
                    this.invalid = true;
                    this.errorOnPreviousSubmit = true;
                    return false;
                }
            } else {
                payload.is_completed = this.$refs.validator.flags.valid;
                payload.should_validate = false;
            }

            return true;
        },

        async completeSubmit(payload) {
            this.errorOnPreviousSubmit = false;
            await this.$store.dispatch('submitIncomeDoc', {
                payload,
                id: this.id,
            });
            this.invalid = false;
        },

        async submit(shouldValidate) {
            if (
                this.earlyReturn(
                    shouldValidate,
                    this.$store.getters.currDocCompleted,
                )
            )
                return true;

            let payload = cleanSubmissionDataWithFormsets({
                sections: this.sections,
                formsets: this.formsets,
            });

            if (!(await this.runBaseValidation(payload, shouldValidate)))
                return false;

            if (this.runCustomValidation != undefined) {
                if (!this.runCustomValidation(payload, shouldValidate)) {
                    this.errorOnPreviousSubmit = true;
                    return false;
                }
            }

            await this.completeSubmit(payload);

            return true;
        },
    },
    mounted() {
        if (this.$refs.validator) {
            this.$refs.validator.reset();
        }
        this.$emit('init');
    },
};

import { docParserMixin } from '@/views/interview/mixins';

const SUCCESS = 1;
const FAILURE = 0;

var parseableIncomeDocMixin = {
    created() {
        this.SUCCESS = SUCCESS;
        this.FAILURE = FAILURE;
    },
    data() {
        return {
            parseCompletionStatus: SUCCESS,
        };
    },
    mixins: [docParserMixin],
    methods: {
        getMobileEndpointQueryParams() {
            return {
                'id': this.$store.getters.editDocId,
                'doc_type': this.docType,
            };
        },
        async submitThisIncomeDocForParsing(file) {
            await this.submitFileForParsing(file, this.$store.getters.editDocId, this.docType);
        },
        acknowledgeParseCompletion() {
            if (this.parseCompletionStatus === SUCCESS) {
                this.$emit('reload');
            }
        },
        async getQRCodeLink() {
            var params = this.getMobileEndpointQueryParams();
            return await this.getQRCodeLinkBase(params);
        },
        async getMobileTaskStatus() {
            var params = this.getMobileEndpointQueryParams();
            var status = await this.getMobileTaskStatusBase(params);
            return status;
        },
    },
};

export default incomeMixin;
export { parseableIncomeDocMixin };
