<template>
	<body>
		<header
			id="header"
			class="header header-bg-transparent header-abs-top py-3 a"
		>
			<div class="header-section">
				<div id="logoAndNav" class="container">
					<nav class="navbar">
						<a
							role="button"
							class="navbar-brand"
                            @click="$router.push({name: 'Home'})"
							aria-label="Front"
						>
							<img src="@/assets/logo.png" alt="Logo" />
						</a>
					</nav>
				</div>
			</div>
		</header>
		<!-- ========== HEADER ========== -->
		<div class="d-flex space-top-2">
			<div class="container d-flex align-items-center vh-100">
				<div class="row justify-content-md-center flex-md-grow-1 text-center">
					<div class="col-sm-9 col-lg-6">
						<img
							class="img-fluid mb-2"
							src="@/assets/svg/illustrations/maintenance-mode.svg"
							alt="SVG Illustration"
						/>
						<h1 class="h2">We're just tuning up a few things.</h1>
						<p>
							We apologize for the inconvenience but Thriftax is currently
							undergoing maintenance. Please try again later.
						</p>
					</div>
				</div>
			</div>
		</div>

		<!-- ========== MAIN ========== -->
	</body>
</template>

<script>
export default {};
</script>
