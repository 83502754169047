<template>
    <div>
        <div class="interview-header">
            Your forms are <strong>ready to go</strong>

            <div class="interview-subheader" v-if="context.self_send">
                Be sure to follow the enclosed instructions which detail both
                where you should sign and how to mail your forms to the tax
                authorities
            </div>
        </div>

        <error-card
            :errors="[
                `An
            error occurred while printing your forms. We've been notified about
            the problem and will contact you once it's resolved.`,
            ]"
            v-if="failure"
        />

        <div
            v-if="invalidPermissions"
            class="rounded border border-danger p-3 mb-3 mx-1 h4 text-danger text-center"
        >
            <i class="fas fa-exclamation-triangle pr-2"></i> You need to pay
            before printing this form. If you think this is an error, please
            send us a support request explaining the issue. You can send a
            support request by clicking "Support" at the top right of the
            screen.
        </div>

        <div class="review-card">
            <h3 class="text-center border-bottom pb-1 mb-3">
                Download Tax Forms
            </h3>
            <btn
                v-for="ret in returns"
                class="mb-3"
                app
                color="info"
                :key="ret.name"
                @click="fetchTaxForms(ret.name)"
                :loading="ret.loading"
                block
            >
                {{ ret.name }}</btn
            >
        </div>
        <div class="d-flex justify-content-center mt-4" v-if="context.tester">
            <btn @click="getTestPack" app size="sm" color="info">
                Generate Test Pack
            </btn>
            <button class="d-none" id="downloadAnchorElem"></button>
        </div>
        <p class="text-center mt-4">
            Please note: for security reasons, your forms cannot be emailed to
            you.
        </p>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import sendConversion from '@/views/send_conversion';
import submitErrorLog from '@/services/error-logs';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'gen_pdfs',
            returns: [],
            noFormSubmit: true,

            failure: false,
            invalidPermissions: false,
        };
    },
    methods: {
        getTestPack() {
            this.$store.getters.client
                .get(`test-pack/${this.context.tr_id}/`)
                .then((resp) => {
                    var exportObj = resp.data;
                    var dataStr =
                        'data:text/json;charset=utf-8,' +
                        encodeURIComponent(JSON.stringify(exportObj));
                    var downloadAnchorNode = document.createElement('a');
                    downloadAnchorNode.setAttribute('href', dataStr);
                    downloadAnchorNode.setAttribute(
                        'download',
                        'testcase.json',
                    );
                    document.body.appendChild(downloadAnchorNode); // required for firefox
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                });
        },
        fetchTaxForms(type) {
            for (var ret of this.returns) {
                if (ret.loading) {
                    return;
                }
            }
            this.failure = false;

            var index = -1;
            for (var i = 0; i < this.returns.length; i++) {
                if (this.returns[i].name == type) {
                    index = i;
                }
            }
            this.returns[index].loading = true;
            this.$store.getters.client
                .get(`gen-pdfs/${this.context.tr_id}/`, {
                    params: {
                        return_type: type,
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    // const fileName = response.headers["Content-Disposition"].match(
                    // 	/filename=(.*)/
                    // )[1];
                    var fileName;
                    if (type == 'Family 8843 Forms') {
                        fileName = `Thriftax Family 8843 Forms ${this.context.year}.pdf`;
                    } else {
                        fileName = `Thriftax ${type} Tax Forms ${this.context.year}.pdf`;
                    }
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.returns[index].loading = false;
                })
                .catch((err) => {
                    this.returns[index].loading = false;
                    if (err.response && err.response.status == 402) {
                        this.invalidPermissions = true;
                    } else {
                        submitErrorLog(err);
                        this.failure = true;
                    }
                });
        },
    },
    created() {
        sendConversion({
            address: 'AW-417237183/aOl3CPCKiPYBEL-R-sYB',
            id: this.$store.getters.getTrId,
        });

        for (var i = 0; i < this.context.returns.length; i++) {
            this.returns.push({
                name: this.context.returns[i],
                loading: false,
            });
        }
    },
};
</script>
<!-- Event snippet for Website sale conversion page -->
