<template>
    <div>
        <div class="interview-header">
            Were you
            <strong>at least a half-time student or scholar </strong>at a
            <strong>U.S. educational institution</strong>
            in {{ context.year }}?
            <header-tooltip size="lg">
                What's a half-time student/scholar?
                <template v-slot:content>
                    Studying 50% or more of a full-time academic workload as
                    defined by your academic institution. Answer yes if you are
                    a full-time student/scholar or at least a half-time
                    student/scholar. If you are not sure, please contact your
                    school’s office of international students.
                    <br />
                    <br />
                    If you were a half-time student or scholar and graduated in
                    {{ context.year }}, select "Yes".
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'is_US_scholar',
        };
    },
};
</script>
