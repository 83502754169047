<template>
  <div class="no-interview-window">
    <div v-if="isEditing">
      <div class="interview-window">
        <div class="row income-token-row mb-2 d-md-flex d-none">
          <div
            class="income-token-col col mb-2"
            v-for="(doc, index) in docs"
            :key="doc.id"
          >
            <div
              class="income-doc-token w-100 h-100"
              :class="editDocId == doc.id ? 'edit' : ''"
            >
              <div class="token-header-text">
                {{ displayDocType(doc.type) }}
              </div>
              <div class="token-sub-text">
                <div v-if="doc.payer_name" class="p-0 m-0">
                  {{ doc.payer_name }}
                </div>

                <div v-else class="min-height-text"></div>
              </div>
            </div>
          </div>
        </div>
        <component
          v-show="!editDocLoading"
          v-if="editDocDataFetched"
          v-on:close="closeEditor"
          :is="editDocType"
          :formData="editDoc"
          :id="editDocId"
          ref="editDoc"
          @init="editDocLoading = false"
          @reload="updateEditDoc($store.getters.editDocId)"
          :submitFunc="submitEditDoc"
          :showBackBtn.sync="showBackBtn"
          :showIncomeCtrlBtns.sync="showIncomeCtrlBtns"
        >
          <template v-slot:back-btn>
            <btn
              v-if="showBackBtn"
              @click="submitEditDoc(false)"
              color=""
              app
              size="sm"
              class="btn-soft-danger"
            >
              <i class="fas fa-caret-left mr-1"></i>
              Back to Income
            </btn>
          </template>
        </component>
        <interview-page-loading v-if="editDocLoading" />
      </div>
      <div v-if="showIncomeCtrlBtns" class="ctrl-btn-wrapper">
        <btn
          @click="submitEditDoc(false)"
          disable-on-forms-purchased
          app
          color="info"
          class="transition-3d-hover"
        >
          <i class="fas fa-hourglass-half"></i>
          Save for Later
        </btn>
        <btn
          @click="submitEditDoc(true)"
          disable-on-forms-purchased
          app
          color="rally"
          class="transition-3d-hover"
        >
          Done
        </btn>
      </div>
    </div>
    <div class="interview-window" v-else>
      <div class="interview-header">
        Your U.S.
        <strong>sources of income</strong>

        <div class="interview-subheader">
          <span class="link" @click="helpModal = true">
            Help me figure out which types of income I should add
            <i class="fas fa-info-circle"> </i>
          </span>
        </div>
      </div>

      <h3
        class="text-danger text-center mb-5 w-lg-60 mx-auto"
        v-if="errors.length"
      >
        <template v-if="!errors.includes('no_docs')">
          <error-card :errors="errors"
        /></template>
        <template v-else
          >Please add one or more income sources before moving on. If you didn't
          have income, you can
          <span class="link" @click="goBackToHadIncome">
            go back and answer that you didn't have income </span
          >.
        </template>
      </h3>

      <div>
        <div
          class="row m-0 d-flex justify-content-center"
          v-for="i in Math.ceil(docs.length / 2)"
          :key="i"
        >
          <div
            v-for="(doc, index) in docs.slice((i - 1) * 2, i * 2)"
            :key="doc.id"
            class="p-2 income-source-card rounded-lg border mb-4"
            :class="getIncomeSourceCardClass(i, index)"
            @click="updateEditDoc(doc.id)"
          >
            <div class="position-relative h-100">
              <div class="row m-0 p-0 px-1">
                <span class="no-wrap-ell">
                  <span class="h4 p-0 m-0">
                    {{ displayDocType(doc.type) }}
                  </span>
                  <span v-if="doc.payer_name">
                    <span class="">—</span>
                    {{ doc.payer_name }}
                  </span>
                </span>
              </div>
              <div
                class="row m-0 p-0 position-absolute bottom-0 d-flex justify-content-between w-100 h4"
              >
                <btn
                  @click.stop="deleteDoc(doc.id)"
                  class="badge btn-danger"
                  color=""
                  disable-on-forms-purchased
                >
                  <i class="fas fa-trash-alt mr-1"></i>
                  Delete
                </btn>
                <div
                  class="small m-0 p-0 d-flex align-items-center px-1"
                  :class="doc.is_completed ? 'text-success' : 'text-danger'"
                >
                  <template v-if="doc.is_completed">
                    <i class="fas fa-check mr-1"></i>
                    Completed
                  </template>

                  <template v-else> Not Completed </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <modal v-model="showModal" :footer=false>
          <template v-slot:body>
            <div
              class="row m-0"
              v-for="i in Math.ceil(addIncomeChoices.length / 2)"
              :key="i"
            >
              <div
                class="p-0 mb-2"
                v-for="(item, index) in addIncomeChoices.slice(
                  (i - 1) * 2,
                  i * 2
                )"
                :class="getAddIncomeColClasses(index)"
                :key="item.targetName"
              >
                <btn
                  app
                  block
                  size="sm"
                  class="btn-outline-secondary py-1"
                  color=""
                  @click="
                    item.unsupported
                      ? promptUnsupportedIncome(item.targetName)
                      : createDoc(item.targetName)
                  "
                >
                  <div>{{ item.name }}</div>
                  <div class="font-weight-normal small mt-0 pt-0">
                    {{ item.description }}
                  </div>
                </btn>
              </div>
            </div>

            <p class="mt-2 text-center mb-1 h5">
              Other income not reported elsewhere:
            </p>

            <btn
              v-for="item in addIncomeChoicesOther"
              :key="item.targetName"
              app
              block
              size="sm"
              class="btn-outline-secondary py-1"
              color=""
              @click="
                item.unsupported
                  ? promptUnsupportedIncome(item.targetName)
                  : createDoc(item.targetName)
              "
            >
              {{ item.name }}
            </btn>
          </template>
          <template v-slot:footer>
            <div class="d-none"></div>
          </template>
        </modal>
      </div>

      <modal v-model="unsupportedIncome">
        <template v-slot:title> We're sorry </template>
        <template v-slot:body>
          {{ unsupportedIncomeType }} income is not currently supported by
          Thriftax. Please use an alternative tax-filing service that supports
          this type of income.
        </template>
      </modal>

      <modal size="xl" v-model="helpModal">
        <template v-slot:body>
          <div>
            <table class="table font-size-base-sm">
              <thead class="thead-light">
                <tr>
                  <th>Income Source</th>
                  <th>
                    Income Form (you should have received)
                  </th>
                </tr>
              </thead>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I was employed by a
                  <strong>U.S. employer </strong>
                </td>
                <td>
                  You should have received either a
                  <strong> W-2 or 1042-S</strong> form from your employer
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I received income
                  <strong>from my host family as an au pair</strong>.
                </td>
                <td>
                  You may or may not have received a W-2 from your host family.
                  If you received a W-2, add it on the income menu. If you did
                  not receive a W-2, add "Au Pair Income".
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I had
                  <strong>interest income </strong>from deposits, bonds, the
                  IRS, or other governmental authorities
                </td>
                <td>
                  You should have received form
                  <strong>1099-INT or 1042-S</strong>
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I had
                  <strong>capital gain(s) </strong>from investment(s) with a
                  broker (e.g. stocks, options, ETFs etc.)
                </td>
                <td>
                  You should have received form(s)<strong>
                    1099-B
                  </strong>
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I received a
                  <strong> dividend </strong> or
                  <strong>capital distributions </strong>
                  paid to me by a U.S. company
                </td>
                <td>
                  You should have received form
                  <strong>1099-DIV or form 1042-S</strong>
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I received a<strong> tax refund</strong>
                  last year or received other
                  <strong>payments from governmental institutions</strong>
                </td>
                <td>
                  You should have received form
                  <strong>1099-G</strong>
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  <div class="highlight-icon">
                    I received a
                    <strong>scholarship/fellowship or academic grant </strong>

                    <tooltip>
                      <i class="fas fa-info-circle" />

                      <template v-slot:content>
                        Note:
                        <ol class="mb-0">
                          <li>
                            If the scholarship/grant was paid directly to your
                            school or was given by your school to offset tuition
                            expenses, you will be able to deduct the amount paid
                            for school expenses in the deductions stage.
                          </li>
                          <li>
                            If you have not received any form regarding your
                            scholarship you may still report the scholarship
                            income.
                          </li>
                        </ol>
                      </template>
                    </tooltip>
                  </div>
                </td>
                <td>
                  You should have received
                  <strong
                    >form 1042-S, a letter from the payer (university or other
                    entity), or form 1099-MISC</strong
                  >
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I won a
                  <strong>prize or an award</strong>
                </td>
                <td>
                  <div class="highlight-icon">
                    You should have received form
                    <strong
                      >1099-MISC, 1042-S, or a letter from the payer (university
                      or other entity)</strong
                    >.
                    <tooltip>
                      <i class="fas fa-info-circle" />

                      <template v-slot:content>
                        If you have not received any form for your prize/award
                        you can still report it as income.
                      </template>
                    </tooltip>
                  </div>
                </td>
              </tr>
              <tr class="stronger-border-bottom">
                <td class="border-right">
                  I had a
                  <strong>pension or annuity payment</strong>, including early
                  withdrawals from pension plans such as a 401K or 401K-ruth
                </td>
                <td>
                  You should have received form
                  <strong>1099-R</strong>
                </td>
              </tr>
            </table>

            <p>
              Other types of income are usually reported with form 1099-MISC or
              1042-S.
            </p>
          </div>
        </template>
      </modal>
      <div class="add-income-btn">
        <btn
          @click="showModal = true"
          disable-on-forms-purchased
          app
          block
          color="info"
          ><i class="fas fa-plus mr-2"></i> Add a Source of Income</btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import W2 from "./W2";
import S1042 from "./1042-S";
import B1099 from "./1099-B";
import DIV1099 from "./1099-DIV";
import G1099 from "./1099-G";
import INT1099 from "./1099-INT";
import MISC1099 from "./1099-MISC";
import NEC1099 from "./1099-NEC";
import Academic from "./academic";
import Award from "./award";
import AuPairIncome from "./AuPairIncome";
import Tips from "./Tips";
import MultiB1099 from "./MultiB1099";
import Commissions from "./commissions";
import submitErrorLog from "@/services/error-logs";
import { displayDocType } from "@/views/utilities";
import interviewPageLoading from "@/components/interview/page-loading";

export default {
  data() {
    return {
      context: {},
      showModal: false,
      isEditing: false,
      dirty: false,
      errors: [],
      unsupportedIncome: false,
      unsupportedIncomeType: "",
      helpModal: false,
      editDocLoading: true,
      editDocDataFetched: false,
      isAuPair: false,
      addIncomeColBreak: "md",
      addIncomeChoices: [
        {
          name: "W2",
          description: "Employment Income"
        },
        {
          name: "1042-S",
          description: "Various Income Types"
        },
        {
          name: "1099-B",
          description: "Multiple Investments",
          targetName: "MultiB1099"
        },
        {
          name: "1099-B",
          description: "Single Investment"
        },
        {
          name: "1099-DIV",
          description: "Dividends and Distributions"
        },
        {
          name: "1099-G",
          description: "Government Payments"
        },
        {
          name: "1099-INT",
          description: "Interest Income"
        },
        {
          name: "1099-MISC",
          description: "Miscellaneous Income"
        },
        {
          name: "1099-NEC",
          description: "Nonemployment Compensation"
        },
        {
          name: "1099-R",
          description: "Pensions and Annuities",
          unsupported: true
        }
      ],
      addIncomeChoicesOther: [
        {
          name: "Tips",
          targetName: "Tips",
        },
        {
          name: "Academic Grant / Scholarship",
          targetName: "Academic"
        },
        {
          name: "Prize / Award",
          targetName: "Award"
        },
        {
          name: "Fees / Commissions",
          targetName: "Fees/Commissions",
          unsupported: true
        },
        {
          name: "Au Pair Income",
          description: "Income From Host Family",
          targetName: "AuPairIncome"
        },
      ],
      showIncomeCtrlBtns: true,

      showBackBtn: true
    };
  },
  computed: {
    isPurchased() {
      return this.$store.getters.formsPurchased;
    },
    editDocType() {
      let doc = this.docs.filter(doc => {
        return doc.id == this.editDocId;
      });
      if (!doc.length) {
        return null;
      }
      return this.cleanDocTypeName(doc[0].type);
    },
    editDocId() {
      return this.$store.getters.editDocId;
    },
    editDoc() {
      return this.$store.getters.editDoc;
    },
    sections() {
      return this.$store.getters.editDoc;
    },
    docs() {
      return this.$store.getters.incomeDocs;
    }
  },
  methods: {
    switchPages(direction) {
      this.$store.commit("changeDirection", direction);

      let shouldMove = true;
      if (direction == "next") {
        if (!this.docs.length) {
          this.errors = ["no_docs"];
          shouldMove = false;
        }
        this.docs.forEach(doc => {
          if (!doc.is_completed) {
            this.errors = [
              "Please finish filling out all income sources before moving on."
            ];
            shouldMove = false;
          }
        });
      }
      if (shouldMove) {
        this.$store.commit("changeLoading", true);
        var dirty = this.dirty;
        this.dirty = false;
        this.$store.dispatch("moveWithoutSubmitting", {
          shouldSubmit: dirty,
          direction: direction
        });
      }
    },

    getIncomeSourceCardClass(index2, index) {
      var classes = [];

      if (index2 * 2 <= this.docs.length) {
        classes.push(index ? "ml-md-3" : "mr-md-3");
      }
      return classes;
    },
    async submitEditDoc(shouldValidate) {
      if (this.$store.getters.formsPurchased) {
        this.closeEditor();
      }

      var valid = await this.$refs.editDoc.submit(shouldValidate);
      if (valid) {
        this.closeEditor();
      }
      this.dirty = true;
    },
    getAddIncomeColClasses(index) {
      var classes = [`col-${this.addIncomeColBreak}`];

      classes.push(
        index
          ? `ml-${this.addIncomeColBreak}-1`
          : `mr-${this.addIncomeColBreak}-1`
      );
      return classes;
    },
    promptUnsupportedIncome(type) {
      this.showModal = false;
      this.unsupportedIncome = true;
      this.unsupportedIncomeType = type;
    },
    isDirty() {
      return false;
    },
    goBackToHadIncome() {
      this.$store.commit("changeDirection", "jump");
      this.$store.commit("changeLoading", true);
      this.$store.dispatch("jumpTo", "had_US_income");
    },
    closeEditor() {
      this.showBackBtn = true;
      this.isEditing = false;
    },
    cleanDocTypeName(type) {
      switch (type) {
        case "1042-S":
          return "S1042";
        case "1099-B":
          return "B1099";
        case "1099-DIV":
          return "DIV1099";
        case "1099-G":
          return "G1099";
        case "1099-INT":
          return "INT1099";
        case "1099-MISC":
          return "MISC1099";
        case "1099-NEC":
          return "NEC1099";
        case "1099-R":
          return "R1099";
      }
      return type;
    },
    displayDocType(type) {
      return displayDocType(type);
    },
    createDoc(type) {
      try {
        this.showModal = false;
        return this.$store.dispatch("createDoc", type).then(() => {
          this.dirty = true;
          this.updateEditDoc(this.$store.getters.editDocId);
        });
      } catch (error) {
        this.$store.commit("maintenanceMode", true);
        submitErrorLog(error);
      }
    },
    deleteDoc(id) {
      this.$store.dispatch("deleteDoc", id);
      this.dirty = true;
    },
    openEditor() {
      this.isEditing = true;
    },
    updateEditDoc(id) {
      console.log("update edit doc");
      this.editDocDataFetched = false;
      this.editDocLoading = true;
      this.openEditor();
      this.$store.dispatch("updateEditDoc", id).then(value => {
        this.editDocDataFetched = true;
        this.$nextTick(() => {
          this.showIncomeCtrlBtns =
            this.$refs.editDoc.showCtrlBtns === undefined
              ? true
              : this.$refs.editDoc.showCtrlBtns;
        });
      });
    }
  },
  created() {
    for (var item of this.addIncomeChoices) {
      if (!("targetName" in item)) {
        item.targetName = item.name;
      }
    }
    this.context = this.$store.getters.incomeDocsContext;
  },
  watch: {
    isEditing(to) {
      this.$emit("update:showCtrlButtons", !to);
    }
  },

  components: {
    W2,
    S1042,
    B1099,
    DIV1099,
    G1099,
    INT1099,
    MISC1099,
    NEC1099,
    Academic,
    Award,
    Commissions,
    MultiB1099,
    AuPairIncome,
    Tips,
    interviewPageLoading
  }
};
</script>
