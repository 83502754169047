var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav pb-0 progress-menu"},[_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.page_name))]),_c('div',{staticClass:"section-bar w-100 d-flex justify-content-center p-0"},[_c('div',{staticClass:"container p-0"},[_c('ul',{staticClass:"section"},[_c('li',{class:{
                        selected: _vm.sectionClass == 'federal',
                        available: _vm.availableSections.includes('federal'),
                    },on:{"click":function($event){return _vm.redirectSection('federal')}}},[_vm._v(" Federal ")]),_c('li',{class:{
                        selected: _vm.sectionClass == 'state',
                        available: _vm.availableSections.includes('state'),
                    },on:{"click":function($event){return _vm.redirectSection('state')}}},[_c('div',[_vm._v("State")]),(_vm.unrequiredSections.includes('state'))?_c('div',{staticClass:"text-clarify"},[_vm._v(" (Not Required) ")]):_vm._e()]),_c('li',{class:{
                        selected: _vm.sectionClass == 'review',
                        available: _vm.availableSections.includes('review'),
                    },on:{"click":function($event){return _vm.redirectSection('review')}}},[_vm._v(" Review ")])])])]),(_vm.sectionClass == 'federal')?_c('div',{staticClass:"w-100 d-flex justify-content-center p-0 container"},[_c('ul',{staticClass:"sub-section"},[_c('li',{class:{
                    selected: _vm.subSectionClass == 'introduction',
                    available: _vm.availableSubSections.includes(
                        'introduction'
                    ),
                },on:{"click":function($event){return _vm.redirectSubsection('introduction')}}},[_vm._m(0),_c('p',{staticClass:"sub-section-title"},[_vm._v("Introduction")])]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'residency',
                    available: _vm.availableSubSections.includes('residency'),
                },on:{"click":function($event){return _vm.redirectSubsection('residency')}}},[_vm._m(1),_c('p',{staticClass:"sub-section-title"},[_vm._v("Residency")])]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'aboutyou',
                    available: _vm.availableSubSections.includes('aboutyou'),
                },on:{"click":function($event){return _vm.redirectSubsection('aboutyou')}}},[_vm._m(2),_c('p',{staticClass:"sub-section-title"},[_vm._v("About You")])]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'income',
                    available: _vm.availableSubSections.includes('income'),
                },on:{"click":function($event){return _vm.redirectSubsection('income')}}},[_vm._m(3),_c('p',{staticClass:"sub-section-title"},[_vm._v("Income")]),(_vm.unrequiredSubs.includes('income'))?_c('span',{staticClass:"text-clarify"},[_vm._v(" (Not Required) ")]):_vm._e()]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'deductions',
                    available: _vm.availableSubSections.includes('deductions'),
                },on:{"click":function($event){return _vm.redirectSubsection('deductions')}}},[_vm._m(4),_c('p',{staticClass:"sub-section-title"},[_vm._v("Deductions")]),(_vm.unrequiredSubs.includes('deductions'))?_c('span',{staticClass:"text-clarify"},[_vm._v(" (Not Required) ")]):_vm._e()])])]):(_vm.sectionClass == 'state')?_c('div',{staticClass:"w-100 d-flex justify-content-center p-0 container"},[_c('ul',{staticClass:"sub-section"},[_c('li',{class:{
                    selected: _vm.subSectionClass == 'state_visits',
                    available: _vm.availableSubSections.includes(
                        'state_visits'
                    ),
                },on:{"click":function($event){return _vm.redirectSubsection('state_visits')}}},[_vm._m(5),_c('p',{staticClass:"sub-section-title"},[_vm._v("Presence")])]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'state_residency',
                    available: _vm.availableSubSections.includes(
                        'state_residency'
                    ),
                },on:{"click":function($event){return _vm.redirectSubsection('state_residency')}}},[_vm._m(6),_c('p',{staticClass:"sub-section-title"},[_vm._v("Residency")])]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'state_return',
                    available: _vm.availableSubSections.includes(
                        'state_return'
                    ),
                },on:{"click":function($event){return _vm.redirectSubsection('state_return')}}},[_vm._m(7),_c('p',{staticClass:"sub-section-title"},[_vm._v("State Return")])])])]):(_vm.sectionClass == 'review')?_c('div',{staticClass:"w-100 d-flex justify-content-center p-0 container"},[_c('ul',{staticClass:"sub-section"},[_c('li',{class:{
                    selected: _vm.subSectionClass == 'finalize',
                    available: _vm.availableSubSections.includes('finalize'),
                },on:{"click":function($event){return _vm.redirectSubsection('finalize')}}},[_vm._m(8),_c('p',{staticClass:"sub-section-title"},[_vm._v("Finalize")])]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'checkout',
                    available: _vm.availableSubSections.includes('checkout'),
                },on:{"click":function($event){return _vm.redirectSubsection('checkout')}}},[_vm._m(9),_c('p',{staticClass:"sub-section-title"},[_vm._v("Checkout")])]),_c('li',{class:{
                    selected: _vm.subSectionClass == 'send',
                    available: _vm.availableSubSections.includes('send'),
                },on:{"click":function($event){return _vm.redirectSubsection('send')}}},[_vm._m(10),_c('p',{staticClass:"sub-section-title"},[_vm._v("Send")])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-handshake"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-passport"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-address-card"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-money-check-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-money-bill-wave"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-flag-usa"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-home"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-invoice"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-tasks"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-shopping-cart"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-paper-plane"})])}]

export { render, staticRenderFns }