<template>
    <div>
        <error-banner v-model="invalid" />
        <h3 class="mb-1 mt-4">Payer Information</h3>
        <p class="input-label mb-1">
            PAYER's name, street address, city or town, state or province,
            country, ZIP or foreign postal code, and telephone no.
        </p>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <fly-row
                :key="false"
                :fillTop="false"
                :schema="sections[1].schema"
                :data="sections[1].data"
            />
            <fly-col
                class="w-md-50"
                :schema="sections[2].schema"
                :data="sections[2].data"
            />
            <h3 class="mb-1 mt-2">Boxes 1a, 2a, 4</h3>
            <fly-row :schema="sections[3].schema" :data="sections[3].data" />
            <h3 class="mb-1 mt-4">Boxes 13-15</h3>
            <fly-row :schema="sections[4].schema" :data="sections[4].data" />
        </validation-observer>
    </div>
</template>

<script>
import incomeMixin from './mixins';

export default {
    mixins: [incomeMixin],
};
</script>
