<template>
    <div>
        <div class="interview-header">
            Did you <strong>file taxes </strong> in the U.S.
            <strong> in any year prior to {{ context.year - 1 }}</strong
            >?
            <header-tooltip>
                I'm not sure
                <template v-slot:content>
                    If you have filed a full tax return (1040-NR / 1040-NR-EZ)
                    in
                    {{ context.year - 2 }} or any prior year, select "Yes".
                    <br />
                    <br />
                    If you have not filed a full tax return in
                    {{ context.year - 2 }} or any prior year, or have only filed
                    form 8843, select "No".
                </template>
            </header-tooltip>
        </div>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'filed_taxes_any_prev_year',
        };
    },
};
</script>
