<template>
    <div>
        <div class="interview-header">
            Your <strong>donations</strong>

            <div class="interview-subheader">
                Deducting eligible charitable contributions can result in a
                higher tax refund.
            </div>
            <header-tooltip>
                What types of donations qualify?
                <template v-slot:content>
                    Consider donations you've made to U.S.-based non-profit
                    organizations, for which you have adequate records or
                    receipts.
                </template>
            </header-tooltip>
        </div>

        <error-card :errors="errors" class="mb-3" />

        <validation-observer ref="validator">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Organization Type</th>
                        <th class="pr-0" scope="col">
                            Amount Donated by Cash or Check
                        </th>
                        <th scope="col">Amount Donated by Other Means</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="donation in donationsFiltered"
                        :key="donation.displayId"
                    >
                        <td class="align-top">
                            <fly-row
                                no-margin-bottom
                                :data="donation.sections[0].data"
                                :schema="donation.sections[0].schema"
                            />
                        </td>
                        <td class="align-top">
                            <fly-row
                                no-margin-bottom
                                :data="donation.sections[1].data"
                                :schema="donation.sections[1].schema"
                            />
                        </td>
                        <td class="align-top">
                            <fly-row
                                no-margin-bottom
                                :data="donation.sections[2].data"
                                :schema="donation.sections[2].schema"
                            />
                        </td>
                        <td>
                            <fs-delete
                                @click="dirty = true"
                                v-model="donation.delete"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-center">
                <btn
                    disable-on-forms-purchased
                    app
                    size="sm"
                    color="info"
                    @click="addForm"
                    ><i class="fas fa-plus mr-2"></i> Add Donation</btn
                >
            </div>
            <div class="mx-auto" v-if="showOver250">
                <div class="p-3 custom-field-label">
                    <span class="font-weight-bold">Note: </span> <br />
                    For any single charitable contribution you made that
                    exceeded $250, you must keep a written acknowledgment from
                    the charitable organization which shows the following
                    information:
                    <ul>
                        <li>The date of the contribution</li>
                        <li>The amount of money donated</li>
                        <li>
                            If you made a non-cash contribution –⁠ a description
                            of any property donated
                        </li>
                        <li>
                            If you received something from the charitable
                            organization in return for your donation – a
                            description of what was received
                        </li>
                    </ul>
                </div>
            </div>
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title> We're sorry </template>

            <template v-slot:body>
                If you want to claim a tax credit for $500 of charitable
                contributions that were not made by cash, check or credit card,
                you are required to file form 8283, which Thriftax does not
                currently support.
                <br />
                <br />
                If you made a mistake, click "Close" and update your
                information.
            </template>
        </modal>
    </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from '@/views/interview/formset';
import baseInterviewMixin from '@/views/interview/mixins';
import { strToNum } from '@/views/utilities';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'charity_expenses',

            errors: [],
            unsupported: false,
        };
    },
    computed: {
        donationsFiltered() {
            return filterDeletedForms({ formset: this.formsets.donations });
        },
        showOver250() {
            for (var don of this.donationsFiltered) {
                var tot = 0;
                if (!isNaN(strToNum(don.sections[1].data.amount_cash))) {
                    tot += strToNum(don.sections[1].data.amount_cash);
                }
                if (!isNaN(strToNum(don.sections[2].data.amount))) {
                    tot += strToNum(don.sections[2].data.amount);
                }
                if (tot > 250) {
                    return true;
                }
            }
            return false;
        },
    },
    methods: {
        addForm() {
            addFormsetItem(this.formsets.donations);
        },
        validateDonations() {
            this.errors = [];
            let tot = 0;
            this.donationsFiltered.forEach((don) => {
                if (don.sections[1].data.amount_cash) {
                    if (!don.sections[2].data.amount) {
                        don.sections[2].data.amount = 0;
                    }
                } else if (don.sections[2].data.amount) {
                    if (!don.sections[1].data.amount_cash) {
                        don.sections[1].data.amount_cash = 0;
                    }
                } else if (
                    !this.errors.includes(
                        'For each donation, please add at least either an amount donated by cash or an amount donated by other means',
                    )
                ) {
                    this.errors.push(
                        'For each donation, please add at least either an amount donated by cash or an amount donated by other means',
                    );
                }
                if (!isNaN(strToNum(don.sections[2].data.amount))) {
                    tot += strToNum(don.sections[2].data.amount);
                }
            });
            return tot;
        },
        continueSubmit() {
            let tot = this.validateDonations();
            if (tot > 500) {
                this.unsupported = true;
                return false;
            }
            return !this.errors.length;
        },
    },
};
</script>

<style scoped>
.delete-icon input[type='checkbox'] {
    z-index: 1;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.fancy-checkbox input[type='checkbox'] {
    display: none;
}

.fancy-checkbox {
    cursor: pointer;
    font-size: 19px;
}
.visit_row {
    margin-bottom: 2px !important;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    /* STYLES GO HERE */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tbody tr {
        border: 1px solid #eee;
        border-radius: 6px;
        margin-bottom: 20px;
    }

    td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-top: 10%;
    }
    td:nth-of-type(4) {
        padding: 0 0 0 5%;
    }
    td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-left: 10px;
        white-space: nowrap;
    }

    td:nth-of-type(1):before {
        content: 'Organization Type';
    }
    td:nth-of-type(2):before {
        content: 'Amount Donated by Cash or Check';
    }
    td:nth-of-type(3):before {
        content: 'Amount Donated by Other Means';
    }
}
</style>
