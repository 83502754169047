<template>
    <div>
        <error-banner v-model="invalid" />
        <h3 class="mb-3 mt-3">Payer Information</h3>
        <p class="input-label mb-1">
            PAYER's name, street address, city or town, state or province,
            country, ZIP or foreign postal code, and telephone no.
        </p>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <fly-row
                :fillTop="false"
                :schema="sections[1].schema"
                :data="sections[1].data"
            />
            <fly-col :schema="sections[2].schema" :data="sections[2].data" />

            <div class="my-3">
                <h3 class="mb-1 mt-2">Boxes 1, 3, 4</h3>
                <p>Boxes 1 and 3 should not be filled at the same time</p>
            </div>
            <fly-col :schema="sections[3].schema" :data="sections[3].data" />
            <h3 class="mb-3 mt-6">Boxes 15-17</h3>
            <fly-row :schema="sections[4].schema" :data="sections[4].data" />
        </validation-observer>
        <modal v-model="unsupported">
            <template v-slot:title> We're sorry </template>
            <template v-slot:body>
                Thriftax currently doesn't support 1099-INT income from any
                other source. You may file offline or check again at a later
                time.
            </template>
        </modal>
    </div>
</template>

<script>
import incomeMixin from './mixins';

export default {
    mixins: [incomeMixin],
    data: function () {
        return {
            unsupported: false,
        };
    },
    mounted() {
        this.$watch('sections.3.data.INT1099_interest_income', () => {
            this.checkField1And3();
        });
        this.$watch('sections.3.data.INT1099_interest_on_bonds', () => {
            this.checkField1And3();
        });
        this.$watch('sections.3.data.INT1099_income_connection', () => {
            this.incomeConnectionIsValid();
        });
        this.checkField1And3();
    },
    methods: {
        incomeConnectionIsValid() {
            this.unsupported = false;
            let val = this.sections[3].data.INT1099_income_connection;
            if (
                this.sections[3].data.INT1099_interest_income ||
                this.sections[3].data.INT1099_interest_on_bonds
            ) {
                if (val == 'no_connection') {
                    this.unsupported = true;
                }
            }
            return !this.unsupported;
        },
        checkField1And3() {
            let field1 = this.sections[3].data.INT1099_interest_income;
            let field3 = this.sections[3].data.INT1099_interest_on_bonds;

            if (field1) {
                this.sections[3].schema[1].disabled = true;
            } else {
                this.sections[3].schema[1].disabled = false;
            }
            if (field3) {
                this.sections[3].schema[0].disabled = true;
            } else {
                this.sections[3].schema[0].disabled = false;
            }
        },
        runCustomValidation(payload, shouldValidate) {
            if (shouldValidate) {
                return this.incomeConnectionIsValid();
            } else {
                payload.is_completed =
                    payload.is_completed && this.incomeConnectionIsValid();
            }
            return true;
        },
    },
};
</script>
