<template>
    <div>
        <div class="interview-header">
            Your <strong>foreign income</strong>

            <div class="interview-subheader">
                <span v-if="context.is_resident">
                    The state of California requires all California residents to
                    report foreign income they made during {{ context.year }}.
                </span>

                <span v-else class="font-size-base">
                    The state of California requires you to distinguish between
                    the foreign income you made before and after you became a
                    California resident. Please note that the foreign income
                    generated in the period you were a California resident will
                    be taxed. <br />
                    For income types that are hard to relate to a specific
                    period, you may divide the income proportionately to the
                    period that you were a California resident in
                    {{ context.year }}.

                    <tooltip>
                        Show me an example

                        <template v-slot:content>
                            Based on your information, you were a California
                            non-resident for
                            {{ Math.round(fracNR * 100) }}% of
                            {{ context.year }} and a California resident for
                            {{ 100 - Math.round(fracNR * 100) }}%. If, for
                            example, you had $100 of foreign interest income
                            during {{ context.year }} that is hard to relate to
                            a specific period, you would enter $100 x
                            {{ fracNR.toFixed(2) }} = ${{
                                Math.round(100 * fracNR)
                            }}
                            into "Amount Earned Before
                            {{ context.intention_date }}" and enter $100 x
                            {{ 1 - fracNR.toFixed(2) }} = ${{
                                Math.round(100 * (1 - fracNR))
                            }}
                            into "Amount Earned After
                            {{ context.intention_date }}"
                        </template>
                    </tooltip>
                </span>
            </div>
        </div>
        <validation-observer ref="validator">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="w-30">Type of income</th>
                        <th v-if="context.is_resident">Amount</th>
                        <th class="pr-0" v-if="!context.is_resident">
                            Amount Earned Before {{ context.intention_date }}
                        </th>
                        <th v-if="!context.is_resident">
                            Amount Earned After {{ context.intention_date }}
                        </th>

                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(fincome, i) in fincomesFiltered"
                        :key="fincome.displayId"
                    >
                        <td class="align-top">
                            <fly-row
                                @change="updateFields($event, i)"
                                :data="fincome.sections[0].data"
                                :schema="[fincome.sections[0].schema[0]]"
                                no-margin-bottom
                            />
                        </td>

                        <td class="align-top">
                            <fly-row
                                :data="fincome.sections[0].data"
                                :schema="[fincome.sections[0].schema[1]]"
                                no-margin-bottom
                            />
                        </td>
                        <td v-if="!context.is_resident" class="align-top">
                            <fly-row
                                :data="fincome.sections[0].data"
                                :schema="[fincome.sections[0].schema[2]]"
                                no-margin-bottom
                            />
                        </td>
                        <td>
                            <fs-delete
                                @click="dirty = true"
                                v-model="fincome.delete"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center">
                <btn
                    @click="addForm"
                    disable-on-forms-purchased
                    app
                    size="sm"
                    color="info"
                    ><i class="fas fa-plus mr-2"></i> Add Income Source</btn
                >
            </div>
        </validation-observer>
    </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from '@/views/interview/formset';
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'california_fincome',
        };
    },
    computed: {
        fincomesFiltered() {
            return filterDeletedForms({
                formset: this.formsets.fincome_sources,
            });
        },
        fracNR() {
            var residencyDate = new Date(this.context.intention_date);
            var start = new Date(this.context.year, 0, 0);
            var diff = residencyDate - start;
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);
            return day / 365;
        },
    },
    methods: {
        updateFields(eventD, index) {
            if (eventD.field != 'income_type') {
                return;
            }
            var incomeType = eventD.val;
            var allowLosses = ['business', 'other_gains', 'farm_income'];
            var input_type = this.fincomesFiltered[index];
            if (allowLosses.includes(incomeType)) {
                input_type = 'float-input';
            } else input_type = 'positive-float';
            this.fincomesFiltered[
                index
            ].sections[0].schema[1].type = input_type;
            if (this.fincomesFiltered[index].sections[0].schema.length == 3) {
                this.fincomesFiltered[
                    index
                ].sections[0].schema[2].type = input_type;
            }
        },
        addForm() {
            addFormsetItem(this.formsets.fincome_sources);
        },
    },
};
</script>
