<template>
    <div>
        <validation-observer ref="validator">
            <div class="interview-header">
                Great news! You may qualify for a
                <strong>FICA tax return</strong> in the amount of
                <strong>{{ formatDollarAmount(context.return_amt) }}</strong>
                <div class="interview-subheader">
                    This return is separate from your income tax returns. You
                    may be eligible for a refund because your employer(s)
                    erroneously withheld social security / Medicare taxes.
                </div>
            </div>
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <div v-if="shouldShowW2Ficas">
                <div
                    v-for="fica in formsets.w2_ficas"
                    :key="fica.id"
                >
                    <div class="formset-card">
                        <h3>
                            For your W-2 income from
                            {{ fica.context.payer_name }}
                        </h3>
                        <p>
                            {{ formatDollarAmount(fica.context.amt) }} possible
                            refund
                        </p>
                        <fly-col
                            :schema="fica.sections[0].schema"
                            :data="fica.sections[0].data"
                        />

                        <template v-if="noRefund(fica)">
                            <p>
                                <strong> Note: </strong>
                                <br />
                                Based on the information provided, the FICA tax
                                refund for this W-2 is/was handled directly by
                                your employer. You will not need to submit a
                                FICA tax return for this W-2.
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { strToNum } from '@/views/utilities';

export default {
    data() {
        return {
            pageName: 'fica',
        };
    },
    mixins: [baseInterviewMixin],
    computed: {
        shouldShowW2Ficas() {
            return this.boolEq(true, this.sections[0].data.wants_fica);
        },
    },
    methods: {
        noRefund(fica) {
            var offset = 0;
            if (this.boolEq(true, fica.sections[0].data.refund)) {
                if (!isNaN(strToNum(fica.sections[0].data.refund_amt))) {
                    offset += strToNum(fica.sections[0].data.refund_amt);
                }
            }
            if (this.boolEq(true, fica.sections[0].data.employer_claim)) {
                if (!isNaN(strToNum(fica.sections[0].data.employer_claim_amt))) {
                    offset += strToNum(fica.sections[0].data.employer_claim_amt);
                }
            }
            if (this.boolEq(true, fica.sections[0].data.claimed)) {
                if (!isNaN(strToNum(fica.sections[0].data.claimed_amt))) {
                    offset += strToNum(fica.sections[0].data.claimed_amt);
                }
            }
            if (fica.sections[0].data.employer_request == 'Yes') {
                if (!isNaN(strToNum(fica.sections[0].data.employer_request_amt))) {
                    offset += strToNum(
                        fica.sections[0].data.employer_request_amt,
                    );
                }
            }

            return offset >= fica.context.amt;
        },
    },
};
</script>
