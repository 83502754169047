<template>
    <div>
        <div class="interview-header">
            About your
            <strong>most recent tax return</strong>
        </div>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'non_recent_return',
        };
    },
};
</script>
