<template>
    <div>
        <validation-observer ref="validator">
            <div class="interview-header">
                About your <strong>student loan</strong>

                <div class="interview-subheader">
                    Deducting your student loan interest may increase your tax
                    refund.
                </div>
            </div>
            <fly-col :data="sections[0].data" :schema="sections[0].schema" />
        </validation-observer>

        <div v-if="showAmount">
            <h3 class="mb-1">Please Note:</h3>
            <ul>
                <li class="mb-3">
                    Include only interest payments of your student loan. Do not
                    include principal payments or deferred interest payments
                    that were added to your loan balance but were not paid by
                    you in {{ context.year }}.
                </li>
                <li class="mb-3">
                    If you took your loan from a U.S. lender, you should expect
                    to receive form 1098-E from each lender. The total interest
                    paid on your loan is provided in box 1 (Student loan
                    interest received by lender) of this form.
                </li>
                <li class="mb-3">
                    If you took your loan from a non-U.S. lender, review your
                    loan annual statement and be sure to only include interest.
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'loan_expenses',
        };
    },
    computed: {
        showAmount() {
            return this.boolEq(
                true,
                this.sections[0].data.loan_for_eligible_student,
            );
        },
    },
};
</script>
