<template>
    <div>
        <error-banner v-model="invalid" />
        <h3 class="mb-3 mt-3">
            How much did you receive from this grant/scholarship?
        </h3>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <h3 class="mb-3 mt-5">About the payer</h3>
            <fly-row :schema="sections[1].schema" :data="sections[1].data" />
            <fly-row :schema="sections[2].schema" :data="sections[2].data" />

            <div
                class="border-bottom w-100 mb-4 mt-2"
                v-if="formsetsPresent(formsets)"
            ></div>

            <div
                v-if="
                    formsets.visa_clarifiers && formsets.visa_clarifiers.length
                "
            >
                <fly-col
                    v-for="vc in formsets.visa_clarifiers"
                    :key="`visa-clarifier-${vc.context.visa_type}`"
                    :data="vc.sections[0].data"
                    :schema="vc.sections[0].schema"
                >
                </fly-col>
            </div>
        </validation-observer>

        <template v-if="showVisaQualifiersIncomeDifferenceError">
            <div
                class="text-danger lead"
                v-if="visaQualifiersIncomeDifference != 0"
            >
                <i class="fas fa-warning bs-mr-2"> </i> Please ensure that the
                income made on all visas adds up to ${{ fedIncome }}
            </div>
        </template>
    </div>
</template>

<script>
import incomeMixin from './mixins';
import { strToNum } from '@/views/utilities';

export default {
    mixins: [incomeMixin],
    data() {
        return {
            showVisaQualifiersIncomeDifferenceError: false,
        };
    },
    computed: {
        fedIncome() {
            return this.sections[0].data.academic_amount;
        },
        visaQualifiersIncomeDifference() {
            if (
                !(
                    this.formsets.visa_clarifiers &&
                    this.formsets.visa_clarifiers.length
                )
            ) {
                return 0;
            }
            var income = this.fedIncome;
            var vc_amt = 0;
            for (var vc of this.formsets.visa_clarifiers) {
                vc_amt += strToNum(vc.sections[0].data.amt);
            }

            var diff = income - vc_amt;

            if (vc_amt < income) {
                if (Math.abs(diff) < 3) {
                    diff = 0;
                }
            }
            return diff;
        },
    },
    methods: {
        runCustomValidation(payload, shouldValidate) {
            if (shouldValidate) {
                if (this.visaQualifiersIncomeDifference != 0) {
                    this.showVisaQualifiersIncomeDifferenceError = true;
                    this.invalid = true;
                    return false;
                }
            } else {
                payload.is_completed =
                    payload.is_completed &&
                    this.visaQualifiersIncomeDifference == 0;
            }

            return true;
        },
    },
};
</script>
