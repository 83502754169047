<template>
    <div class="bg-secondary">
        <div class="d-flex justify-content-between p-0 py-1" :class="{container, 'px-2': !container}">
            <slot> </slot>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        container: {
            type: Boolean,
            default: true,
        },
    },


};
</script>
