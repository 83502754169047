<template>
    <div>
        <div class="interview-header">
            Tell us more
            <strong>about your children</strong>

            <div class="interview-subheader">
                We will check if you are eligible for dependent tax credits and
                if you need to file form 8843 for them (included at no
                additional cost)
            </div>
        </div>
        <validation-observer ref="validator">
            <div
                class="formset-card"
                v-for="(dependent, i) in dependentsFiltered"
                :key="dependent.displayId"
            >
                <h3>Child {{ i + 1 }} {{ childName(dependent) }}</h3>
                <fly-col
                    @change="handleSection0Change(dependent, $event)"
                    :schema="dependent.sections[0].schema"
                    :data="dependent.sections[0].data"
                />

                <div v-if="shouldShowDependent(dependent.sections[0].data)">
                    <fly-row
                        :schema="dependent.sections[1].schema.slice(0, 3)"
                        :data="dependent.sections[1].data"
                        :cols="[5, 2, 5]"
                    />

                    <fly-col
                        :schema="dependent.sections[2].schema"
                        :data="dependent.sections[2].data"
                    />
                    <fly-col
                        @change="handleSection3($event)"
                        :schema="dependent.sections[3].schema"
                        :data="dependent.sections[3].data"
                    />
                    <fly-row
                        :schema="dependent.sections[4].schema.slice(0, 2)"
                        :data="dependent.sections[4].data"
                    />
                    <fly-col
                        :schema="dependent.sections[4].schema.slice(2, 3)"
                        :data="dependent.sections[4].data"
                    />
                    <p class="text-dark pb-0 mb-0">
                        Please enter the number of days they were in the U.S. in
                        each of the following years:
                    </p>
                    <fly-row
                        class="w-md-50"
                        :schema="dependent.sections[5].schema"
                        :data="dependent.sections[5].data"
                    />
                    <p class="text-dark mb-0">
                        Please enter their visa type (if any) in each of the
                        following years:
                    </p>
                    <fly-row
                        :schema="dependent.sections[6].schema"
                        :data="dependent.sections[6].data"
                    />
                    <fly-col
                        :schema="dependent.sections[7].schema.slice(0, 1)"
                        :data="dependent.sections[7].data"
                    />
                    <fly-row
                        :schema="dependent.sections[7].schema.slice(1, 3)"
                        :data="dependent.sections[7].data"
                        :cols="[8, 4]"
                    />

                    <fly-row
                        :schema="dependent.sections[7].schema.slice(3, 6)"
                        :data="dependent.sections[7].data"
                    />
                    <fly-col
                        :schema="dependent.sections[7].schema.slice(6, 7)"
                        :data="dependent.sections[7].data"
                    />
                    <fly-row
                        :schema="dependent.sections[7].schema.slice(7, 9)"
                        :data="dependent.sections[7].data"
                        :cols="[8, 4]"
                    />
                    <fly-row
                        :schema="dependent.sections[7].schema.slice(9, 12)"
                        :data="dependent.sections[7].data"
                    />
                </div>
                <fly-col
                    :schema="dependent.sections[8].schema"
                    :data="dependent.sections[8].data"
                />
                <div
                    v-if="
                        shouldShowExtraTaxID(
                            dependent.sections[8].data,
                            dependent.sections[0].data,
                        )
                    "
                >
                    <fly-col
                        :schema="dependent.sections[9].schema"
                        :data="dependent.sections[9].data"
                    />
                    <fly-col
                        :schema="dependent.sections[10].schema"
                        :data="dependent.sections[10].data"
                    />
                </div>

                <fs-delete @click="dirty = true" v-model="dependent.delete">
                    Remove this child
                </fs-delete>
            </div>

            <div class="d-flex justify-content-center mt-3">
                <btn
                    class="mx-auto"
                    @click="addForm"
                    color="info"
                    app
                    size="sm"
                    disable-on-forms-purchased
                    ><i class="fas fa-plus mr-2"></i> Add Child</btn
                >
            </div>
        </validation-observer>
        <modal v-model="showFileSeparatelyNotice">
            <template v-slot:title>File Separately With Thriftax</template>

            <template v-slot:body>
                Since {{ depsConcat }} changed their visa after January 30,
                {{ context.year }}, we'll need to evaluate their residency more
                thoroughly before filling their 8843. Please have them open
                another account with us and file as you are doing now.
            </template>
            <template v-slot:footer>
                <btn @click="acknowledge()" app color="secondary" small>
                    Ok
                </btn>
            </template>
        </modal>

        <modal v-model="unsupported">
            <template v-slot:title>We're sorry</template>

            <template v-slot:body>
                Because your child's green card/residency application was
                approved, they can file their taxes as a U.S. resident. They
                should use a tax-filing software that supports U.S. residents.
            </template>
        </modal>
    </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from '@/views/interview/formset';
import baseInterviewMixin from '@/views/interview/mixins';
import { prepareInterviewLabel } from '@/views/utilities';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'dependents',

            unsupported: false,
            toFileSeparately: [],
            showFileSeparatelyNotice: false,
        };
    },
    computed: {
        dependentsFiltered() {
            return filterDeletedForms({ formset: this.formsets.Dependents });
        },
        depsConcat() {
            if (this.toFileSeparately.length > 1) {
                var lastWord = ' and ' + this.toFileSeparately.pop();
                if (this.toFileSeparately.length > 1) {
                    lastWord = ',' + lastWord;
                }
            } else {
                var lastWord = '';
            }
            return this.toFileSeparately.join(', ') + lastWord;
        },
    },
    mounted() {
        for (var dependent of this.dependentsFiltered) {
            this.handleSection0Change(dependent, {
                field: 'visa_type',
                val: dependent.sections[0].data.visa_type,
            });
        }
    },

    methods: {
        continueSubmit() {
            this.toFileSeparately = [];

            /* if any dependents changed visas after Jan 30 of the
             * current tax year, show a message indicating that those
             * dependents need to open a separate account */
            for (var dep of this.dependentsFiltered) {
                if (this.changedVisasAfterCutoff(dep)) {
                    this.toFileSeparately.push(dep.sections[1].data.first_name);
                }
            }

            var continueSubmit = !(
                this.toFileSeparately.length && !this.acknowledged
            );

            if (!continueSubmit) {
                this.showFileSeparatelyNotice = true;
            }

            return continueSubmit;
        },
        acknowledge() {
            this.acknowledged = true;
            this.$store.commit('changeDirection', 'next');
            this.$store.dispatch('submitAndMoveOn', {
                shouldSubmit: true,
                form: this,
                direction: 'next',
            });
        },
        changedVisasAfterCutoff(dep) {
            if (
                this.shouldShowDependent(dep.sections[0].data) &&
                this.boolEq(true, dep.sections[3].data.changed_visas)
            ) {
                var val = dep.sections[3].data.previous_visa_change_date;
                var visaChange = new Date(val);
                var cutoff = new Date(this.context.year, 0, 31);
                console.log(visaChange);
                console.log(cutoff);
                return +visaChange >= +cutoff;
            }
            return false;
        },
        handleSection0Change(form, event) {
            if (event.field == 'visa_type') {
                form.sections[2].schema[0].label = prepareInterviewLabel(`Date of first entry to the U.S. on their current
                ${event.val}
                visa`);
            }
        },
        handleSection3(event) {
            if (
                event.field == 'green_card_application_status' &&
                event.val == 'Approved'
            ) {
                this.unsupported = true;
            }
        },
        childName(dependent) {
            if (dependent.sections[1].data.first_name) {
                return ` – ${dependent.sections[1].data.first_name}`;
            }
            return null;
        },
        shouldShowDependent(data) {
            let visas = ['F2', 'J2', 'M2', 'Q3'];
            return visas.includes(data.visa_type);
        },
        shouldShowExtraTaxID(data, data_section_1) {
            // if (!(data_section_1.visa_type == "Other visa" || data_section_1.visa_type == "US citizen")) {
            // 	return false;
            // }
            /* we removed this because we need these questions for the
             * other dependent tax credit possibility
             * which does not require that the dependent is a US
             * citizen / US resident */

            if (!data.tax_ID_type) {
                return false;
            }

            if (
                !(
                    data.already_claimed_as_dependent == 'false' ||
                    data.already_claimed_as_dependent == false
                )
            ) {
                return false;
            }

            let options = ['SSN', 'ITIN'];
            return options.includes(data.tax_ID_type);
        },
        addForm() {
            addFormsetItem(this.formsets.Dependents);
        },
    },
};
</script>
