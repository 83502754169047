import california_r from './california_r';
import california_nr from './california_nr';
import california_residency from './california_residency';
import california_fincome from './california_fincome';
import california_had_out_of_state_purchases from './california_had_out_of_state_purchases';
import california_has_fincome from './california_has_fincome';
import california_nr_spouse from './california_nr_spouse';
import california_out_of_state_purchases from './california_out_of_state_purchases';
import cali_time_of_income from './cali_time_of_income';
import review_cali from './review_cali';

export default {
    california_nr: california_nr,
    california_r: california_r,
    california_residency: california_residency,
    california_fincome: california_fincome,
    california_had_out_of_state_purchases: california_had_out_of_state_purchases,
    california_has_fincome: california_has_fincome,
    california_nr_spouse: california_nr_spouse,
    review_cali: review_cali,
    cali_time_of_income: cali_time_of_income,
    california_out_of_state_purchases: california_out_of_state_purchases,
};
