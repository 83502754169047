<template>
    <div>
        <div class="interview-header">
            Tell us more about your
            <strong>current/most recent visa</strong>
        </div>
        <div>
            <error-card :errors="errors" />
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            />
            <fly-row :schema="sections[1].schema" :data="sections[1].data" />
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title> We're Sorry </template>
            <template v-slot:body>
                We currently don't support visa types other than the ones listed
                as choices in "Visa type/visitor status". If you made a mistake,
                please click “close” and update your visa type to match your
                records.
            </template>
        </modal>
        <modal v-model="noFile">
            <template v-slot:title>No Tax Forms Needed</template>
            <template v-slot:body>
                You do not need to submit any tax forms to the IRS because you
                did not have income in
                {{ context.year }} and your visa type doesn't require you to
                submit form 8843. If you made a mistake, click "close" and
                update your visa type to match your records. If not, you're free
                to stop filing your taxes.
            </template>
        </modal>
    </div>
</template>

<script>
import { isOrdered } from '@/views/interview/date_utilities';
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            errors: [],
            unsupported: false,
            noFile: false,
            pageName: 'current_visa',
        };
    },
    methods: {
        validateDates() {
            this.errors = [];
            let valid = isOrdered(
                this.sections[1].data.visa_issue_date,
                this.sections[1].data.visa_expiry_date,
            );
            if (!valid) {
                this.errors.push(
                    'Visa issue date should be before visa expiry date.',
                );
                this.sections[1].schema[0].invalid = true;
                this.sections[1].schema[1].invalid = true;
            } else {
                this.sections[1].schema[0].invalid = false;
                this.sections[1].schema[1].invalid = false;
            }
        },
        continueSubmit() {
            var visaType = this.sections[0].data.visa_type;

            if (visaType === 'other') {
                this.unsupported = true;
                return false;
            }
            if (
                this.context.non_exempt_visa_types.includes(visaType) &&
                !this.context.had_US_income
            ) {
                this.noFile = true;
                return false;
            }
            this.validateDates();
            return !this.errors.length;
        },
    },
    created() {
        this.$watch('sections.0.data.visa_type', (newVal) => {
            if (newVal === 'other') {
                this.unsupported = true;
                return;
            }
        });
    },
};
</script>
