<template>
  <div>
    <div class="interview-header">
      Okay,<strong> {{ context.first_name }}</strong
      >, tell us more
      <strong> about yourself</strong>
    </div>

    <validation-observer ref="validator">
      <fly-col
        :schema="sections[0].schema.slice(0, 1)"
        :data="sections[0].data"
      />
      <p v-if="crypto">
        If you used a US exchange for your cryptocurrency, you should have
        received a 1099-B or 1099-MISC from your exchange, which you can enter
        in the income section. <strong>If you didn't receive a 1099-B or 1099-MISC, your
        cryptocurrency is not taxable in the US</strong>.
      </p>
      <fly-col :schema="sections[0].schema.slice(1)" :data="sections[0].data" />
    </validation-observer>

    <modal v-model="unsupported">
      <template v-slot:title> We're Sorry </template>
      <template v-slot:body>
        Due to your household employee, you are required to file extra forms
        that Thriftax does not support. Please file your taxes offline. If you
        made a mistake, correct your answers and continue filing with Thriftax.
      </template>
    </modal>

    <modal v-model="applyTaxId">
      <template v-slot:title>Tax ID Needed</template>
      <template v-slot:body>
        Since you had a US income in {{ context.year }}, you are required to
        file taxes and will need an ITIN to avoid issues with your tax refund.
      </template>
    </modal>
  </div>
</template>

<script>
import baseInterviewMixin from "@/views/interview/mixins";

export default {
  mixins: [baseInterviewMixin],
  data() {
    return {
      hello: '',
      pageName: "personal_info",
      unsupported: false,
      applyTaxId: false
    };
  },
  computed: {
    crypto() {
      return this.boolEq(true, this.getFormVal(0, "exchanged_virtual_currency"));
    }
  },
  methods: {
    continueSubmit() {
      if (
        (this.sections[0].data.had_household_employee == "true" ||
          this.sections[0].data.had_household_employee == true) &&
        (this.sections[0].data.household_employee_paid_more_than_2100 ==
          "true" ||
          this.sections[0].data.household_employee_paid_more_than_2100 ==
            true ||
          this.sections[0].data.withheld_taxes_from_household_employee ==
            "true" ||
          this.sections[0].data.withheld_taxes_from_household_employee ==
            true ||
          this.sections[0].data
            .household_employee_paid_more_than_1000_in_quarter == "true" ||
          this.sections[0].data
            .household_employee_paid_more_than_1000_in_quarter == true)
      ) {
        this.unsupported = true;
        return false;
      }
      return true;
    }
  },
  created() {
    this.$watch("sections.0.data.has_US_tax_ID", newVal => {
      if (this.context.had_US_income) {
        if (newVal == false || newVal == "false") {
          this.applyTaxId = true;
        }
      }
    });
  }
};
</script>
