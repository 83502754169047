<template>
    <div>
        <div class="interview-header">
            Were you<strong> married</strong> in {{ context.year }}?
            <header-tooltip>
                I was married for only part of the year in
                {{ context.year }}
                <template v-slot:content>
                    If you were married as of Dec 31st
                    {{ context.year }}, select "Yes". Otherwise, select "No".
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'is_married',
        };
    },
};
</script>
