<template>
  <div>
    <div class="interview-header">
      Your
      <strong>tax payments this year</strong>
    </div>
    <validation-observer ref="validator">
      <fly-col :schema="sections[0].schema" :data="sections[0].data" />

      <div class="mb-4" v-if="shouldShowEstimatedStatePayments">
        <p class="interview-header-2">
          Please list
          <strong> all the states</strong> to which you made
          <strong>estimated tax payments</strong>
        </p>
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="w-60">State</th>
              <th scope="col" class="w-40">Amount</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="payment in estimatedStatePaymentsFiltered"
              :key="payment.sections[0].schema[0].id"
            >
              <td class="align-top">
                <fly-row
                  :data="payment.sections[0].data"
                  no-margin-bottom
                  :schema="[payment.sections[0].schema[0]]"
                />
              </td>
              <td class="align-top">
                <fly-row
                  no-margin-bottom
                  :data="payment.sections[0].data"
                  :schema="[payment.sections[0].schema[1]]"
                />
              </td>
              <td>
                <fs-delete @click="dirty = true" v-model="payment.delete" />
              </td>
            </tr>
          </tbody>
        </table>
        <btn
          disable-on-forms-purchased
          app
          size="sm"
          color="info"
          @click="addEstimatedPayment"
          ><i class="fas fa-plus mr-2"></i> Add State</btn
        >
      </div>
      <fly-col :schema="sections[1].schema" :data="sections[1].data" />

      <div class="mb-4" v-if="shouldShowExtensionStatePayments">
        <p class="interview-header-2">
          Please list
          <strong> all the states</strong> to which you made
          <strong>extension payments</strong>
        </p>

        <error-card :errors="errors" class="mb-4" />
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="w-40">State</th>
              <th scope="col" class="w-30">Amount</th>
              <th scope="col" class="w-30">
                Payment Date
                <tooltip
                  ><i class="fas fa-info-circle"></i
                  ><template v-slot:content
                    >Enter the exact date on which you made this
                    payment.</template
                  ></tooltip
                >
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="payment in extensionStatePaymentsFiltered"
              :key="payment.displayId"
            >
              <td class="align-top">
                <fly-row
                  no-margin-bottom
                  :data="payment.sections[0].data"
                  :schema="[payment.sections[0].schema[0]]"
                />
              </td>
              <td class="align-top">
                <fly-row
                  no-margin-bottom
                  :data="payment.sections[0].data"
                  :schema="[payment.sections[0].schema[1]]"
                />
              </td>
              <td class="align-top">
                <fly-row
                  :data="payment.sections[0].data"
                  no-margin-bottom
                  :schema="[payment.sections[0].schema[2]]"
                />
              </td>
              <td>
                <fs-delete @click="dirty = true" v-model="payment.delete" />
              </td>
            </tr>
          </tbody>
        </table>
        <btn
          disable-on-forms-purchased
          @click="addExtensionPayment"
          size="sm"
          app
          color="info"
          ><i class="fas fa-plus mr-2"></i> Add State</btn
        >
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from "@/views/interview/formset";
import baseInterviewMixin from "@/views/interview/mixins";
import { strToNum } from '@/views/utilities';

export default {
  mixins: [baseInterviewMixin],
  data() {
    return {
      pageName: "add_info",

      errors: []
    };
  },
  computed: {
    estimatedStatePaymentsFiltered() {
      return filterDeletedForms({
        formset: this.formsets.estimated_state_payments
      });
    },
    extensionStatePaymentsFiltered() {
      return filterDeletedForms({
        formset: this.formsets.extension_state_payments
      });
    },

    shouldShowEstimatedStatePayments() {
      return this.boolEq(
        true,
        this.sections[0].data.made_estimated_state_payments
      );
    },
    shouldShowExtensionStatePayments() {
      return this.boolEq(
        true,
        this.sections[1].data.had_extension_state_payments
      );
    }
  },

  methods: {
    validateExtensionPayments: function() {
      this.errors = [];
      if (
        this.boolEq(false, this.sections[1].data.had_extension_state_payments)
      ) {
        return;
      }
      for (var payment of this.extensionStatePaymentsFiltered) {
        var date = payment.sections[0].data.date;
        if (!date) continue;
        var year;
        if (date.includes("/")) {
          year = date.slice(6, 10);
        } else {
          year = date.slice(0, 4);
        }
        year = strToNum(year);
        if (year != this.context.year && year != this.context.year + 1) {
          this.errors.push(
            `Please include below only payments you made in ${
              this.context.year
            } or ${this.context.year +
              1}. If you only made payments in others years please change your answer regarding payments you made with an extension request to “No”.`
          );
        }
      }
    },
    addEstimatedPayment() {
      addFormsetItem(this.formsets.estimated_state_payments);
    },
    addExtensionPayment() {
      addFormsetItem(this.formsets.extension_state_payments);
    },
    continueSubmit() {
      this.validateExtensionPayments();
      return !this.errors.length;
    }
  }
};
</script>

<style scoped>
.slightly_larger_text {
  font-size: 20px;
}
</style>
