<template>
    <div>
        <div class="interview-header-3">
            Did you purchase any products<strong>
                from outside of California</strong
            >
            for which you<strong> did not pay California sales tax</strong>?
        </div>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            fieldName: 'had_out_of_state_purchases',
            pageName: 'california_had_out_of_state_purchases',
        };
    },
};
</script>

<style>
.smallish_text {
    font-size: 27px !important;
}
</style>
