<template>
    <div>
        <div class="interview-header">
            You
            <strong>owe taxes</strong> for the following tax returns
            <div class="interview-subheader">
                In addition to sending your tax forms to the authorities, you
                will also need to send a payment, which we'll show you how to do
            </div>
        </div>
        <div class="d-flex justify-content-center mb-3">
            <table class="table w-lg-50">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Tax Return</th>
                        <th scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="ret in context.returns" :key="ret.title">
                        <th>{{ ret.title }}</th>
                        <td class="text-danger font-weight-bold">
                            {{ formatDollarAmount(ret.amt) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-center">
            <div class="col-lg-10">
                <div class="p-3 custom-field-label">
                    <strong>Note: </strong>You are required
                    by law to file and mail your forms along with a payment or
                    proof of payment. Failure to file and mail your taxes and/or
                    paying the amount you owe may result in penalties and fines.
                    Don’t worry, we will provide you with detailed instructions
                    for how to pay the taxes you owe and mail your forms.
                </div>
            </div>
        </div>
        <validation-observer ref="validator"> </validation-observer>
    </div>
</template>
<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'due_info',
            noFormSubmit: true,
        };
    },
};
</script>
