<template>
    <div>
        <div class="interview-header">
            We need to know a little more
            <strong> about your spouse</strong> for your California return
        </div>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'california_nr_spouse',
        };
    },
};
</script>
