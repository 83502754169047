<template>
  <div>
    <h3 class="mt-3 mb-1">Your Au Pair Income</h3>
    <tooltip class="mb-2">
      What if I earned au pair income from multiple families in
      {{ context.year }}?
      <template v-slot:content>
        If you earned income from multiple families in {{ context.year }}, add
        an au pair income source for each family on the income sources menu.
        The income sources menu can be accessed by clicking "Done" or "Save for
        Later".
      </template>
    </tooltip>
    <validation-observer ref="validator">
      <fly-col :schema="sections[0].schema" :data="sections[0].data" />
      <fly-col :schema="sections[1].schema" :data="sections[1].data" />
    </validation-observer>
  </div>
</template>

<script>
import headerTooltip from "../../../components/ui/header-tooltip.vue";
import incomeMixin from "./mixins";

export default {
  components: { headerTooltip },
  mixins: [incomeMixin]
};
</script>
