<template>
    <div>
        <div class="interview-header">
            <strong>Thank you</strong> for using
            <strong>Thriftax</strong>
            <div class="interview-subheader">
                You're all done! <br />
                We'll now ship your forms to the IRS. If you wish to view your
                tax forms, you can click the button below.
            </div>
        </div>
        <div class="col-md-5 mx-auto">
            <h5 class="text-center mb-1">Review Tax Forms</h5>
            <btn
                v-for="ret in returns"
                app
                :key="ret.name"
                @click="fetchTaxForms(ret.name)"
                :loading="ret.loading"
                block
                color="info"
            >
                {{ ret.name }}</btn
            >
        </div>
        <div class="row d-flex justify-content-center mt-5">
            <div class="col-md-7">
                <img
                    class="img-fluid"
                    src="../../../assets/svg/illustrations/relaxing-man.svg"
                    alt="SVG Illustration"
                />
            </div>
        </div>
        <validation-observer ref="validator"> </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'shipping_info',
            noFormSubmit: true,
            returns: [],
        };
    },
    methods: {
        fetchTaxForms(type) {
            var index = -1;
            for (var i = 0; i < this.returns.length; i++) {
                if (this.returns[i].name == type) {
                    index = i;
                }
            }
            this.returns[index].loading = true;
            return this.$store.getters.client
                .get(`gen-pdfs/${this.context.tr_id}/`, {
                    params: {
                        return_type: type,
                        get_instructions: false,
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    // const fileName = response.headers["Content-Disposition"].match(
                    // 	/filename=(.*)/
                    // )[1];
                    var fileName;
                    if (type == 'Family 8843 Forms') {
                        fileName = `Thriftax Family 8843 Forms ${this.context.year}.pdf`;
                    } else {
                        fileName = `Thriftax ${type} Tax Forms ${this.context.year}.pdf`;
                    }
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.returns[index].loading = false;
                })
                .catch((err) => {
                    this.returns[index].loading = false;
                });
                    
        },
    },
    created() {
        for (var i = 0; i < this.context.returns.length; i++) {
            this.returns.push({
                name: this.context.returns[i],
                loading: false,
            });
        }
    },
};
</script>
