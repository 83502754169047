<template>
    <div>
        <div class="interview-header">
            Do you have
            <strong>children</strong>?

            <p class="interview-subheader">
                When answering this question, please consider only children born
                on or before 12/31/{{ context.year }}.
            </p>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'has_dependents',
        };
    },
};
</script>
