<template>
	<validation-observer ref="validator">
		<v-banner
			v-if="invalid"
			single-line
			sticky
			rounded
			color="#bf1b1b"
			class="mb-4 error_alert"
		>
			<div>
				Please correct the errors on this page before submitting
			</div>
			<template v-slot:actions>
				<v-btn @click="closeBanner" text color="white accent-4">Dismiss</v-btn>
			</template>
		</v-banner>
		<h3 class="bs-mb-2 bs-mt-3">
			How much did you receive from these fees/commissions?
		</h3>
		<fly-col :schema="sections[0].schema" :data="sections[0].data"></fly-col>
		<h3 class="bs-mb-2 bs-mt-3">
			What was the type of service that you offered?
		</h3>
		<fly-row
			fillTop="false"
			:schema="sections[1].schema"
			:data="sections[1].data"
		></fly-row>
		<h3 class="bs-mb-2 bs-mt-3">About the employer</h3>
		<fly-row :schema="sections[2].schema" :data="sections[2].data"></fly-row>
		<h3 class="bs-mb-2 bs-mt-3">From where did you receive this income?</h3>
		<fly-row :schema="sections[3].schema" :data="sections[3].data"></fly-row>

		<v-row justify="space-between" class="px-2 mt-5">
			<db-button
				@click="submit(false)"
				color="#7a7a7a"
				large
				text
				class=" custom_btn pl-4"
			>
				<span class="large_text">
					<i class="fas fa-hourglass-half"></i>
					Save for Later
				</span>
			</db-button>
			<db-button @click="submit(true)" large class="custom_btn next_button">
				<span class="large_text">
					Done
				</span>
			</db-button>
		</v-row>
	</validation-observer>
</template>

<script>
import { cleanSubmissionDataWithFormsets } from "@/api/clean-submission-data";
import submitErrorLog from "@/services/error-logs";

export default {
	data: function() {
		return {
			invalid: false,
			errorOnPreviousSubmit: false,
			sections: [],
			formsets: {},
		};
	},
	props: {
		formData: {},
		id: null,
	},
	created() {
		this.sections = this.formData.sections;
		this.formsets = this.formData.formsets;
	},
	methods: {
		closeBanner: function() {
			this.invalid = false;
		},
		submit: function(shouldValidate) {
			try {
				if (
					!this.errorOnPreviousSubmit &&
					!this.$refs.validator.flags.dirty &&
					(!shouldValidate || this.$store.getters.currDocCompleted)
				) {
					this.$emit("close");
					return;
				}

				let payload = cleanSubmissionDataWithFormsets({
					sections: this.sections,
					formsets: this.formsets,
				});
				if (shouldValidate == false) {
					payload.should_validate = false;
				} else {
					this.$refs.validator.validate();
					if (!this.$refs.validator.flags.valid) {
						this.invalid = true;
						this.errorOnPreviousSubmit = true;
						return;
					}
				}
				if (shouldValidate == false)
					payload.is_completed = this.$refs.validator.flags.valid;

				this.errorOnPreviousSubmit = false;
				this.$emit("submit");
				this.$store
					.dispatch("submitIncomeDoc", {
						payload: payload,
						id: this.id,
					})
					.then(() => {
						this.$emit("close");
					});
			} catch (error) {
				this.$store.commit("maintenanceMode", true);
				submitErrorLog(error);
			}
		},
	},
	mounted() {
		try {
			this.$refs.validator.reset();
		} catch (error) {
			this.$store.commit("maintenanceMode", true);
			submitErrorLog(error);
		}
	},
};
</script>

<style>
.error_alert {
	color: white !important;
}
</style>
