<template>
    <div>
        <div class="interview-header">California Return Summary</div>
        <div class="review-card">
            <div v-for="item in amts" :key="item.name" class="d-flex mb-1">
                <div class="review-card-col-left">
                    {{ item.name }}
                </div>

                <div class="review-card-col-right">
                    {{ formatDollarAmount(item.amt) }}
                </div>
            </div>

            <div class="d-flex lead h3 font-weight-bold mt-2 pt-2 border-top">
                <div class="review-card-col-left">
                    <span v-if="context.amount_due <= 0" class="text-success">
                        Your California Tax Refund:
                    </span>

                    <span v-else class="text-danger">
                        Your California Tax Liability (you need to pay)
                    </span>
                </div>

                <div class="review-card-col-right">
                    {{ formatDollarAmount(Math.abs(context.amount_due)) }}
                </div>
            </div>
        </div>
        <validation-observer ref="validator"> </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'review_cali',
            noFormSubmit: true,

            amts: [],
        };
    },
    created() {
        var context = this.context;
        this.amts.push({
            name: 'Federal taxable income',
            amt: context.fed_taxable_income,
        });
        this.amts.push({
            name: 'California adjustments',
            amt: context.state_adjustments,
        });
        this.amts.push({
            name: 'California taxable income',
            amt: context.cali_taxable_income,
        });
        this.amts.push({
            name: 'Taxes you paid to the state of California',
            amt: context.payments,
        });
        this.amts.push({
            name: `Your total California tax due for ${context.year}`,
            amt: context.tax,
        });
    },
};
</script>
