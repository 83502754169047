import treaties from './treaties';
import clarify_treaties from './clarify_treaties';
import university_expenses from './university_expenses.vue';
import independent_expenses from './independent_expenses.vue';
import charity_expenses from './charity_expenses.vue';
import loan_expenses from './loan_expenses.vue';
import add_info from './add_info.vue';
import received_1095A from './received_1095A.vue';
import previous_return_info from './previous_return_info.vue';
import fica from './fica.vue';

export default {
    treaties: treaties,
    clarify_treaties: clarify_treaties,
    university_expenses: university_expenses,
    independent_expenses: independent_expenses,
    charity_expenses: charity_expenses,
    loan_expenses: loan_expenses,
    received_1095A: received_1095A,
    add_info: add_info,
    fica: fica,
    previous_return_info: previous_return_info,
};
