<template>
    <div>
        <div class="interview-header">
            Nice to meet you,
            <strong>{{ context.first_name }}</strong
            >! Were you
            <strong>a U.S. citizen</strong>
            in
            {{ context.year }}?
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title> We're Sorry </template>
            <template v-slot:body>
                Thriftax does not support tax filing for US citizens.
                <br />
                <br />
                Answered yes by mistake? Click "Close" and change your answer.
            </template>
        </modal>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'is_US_citizen',
            unsupported: false,
        };
    },
    methods: {
        continueSubmit() {
            var is_US_citizen = this.sections[0].data.is_US_citizen;
            if (is_US_citizen === 'true' || is_US_citizen === true) {
                this.unsupported = true;
                return false;
            }
            return true;
        },
    },
};
</script>
