import { cleanSubmissionDataWithFormsets } from '@/api/clean-submission-data';
import { cleanFormsetOnSubmit } from '@/views/interview/formset';

var baseInterviewMixin = {
    /* This mixin is used in every interview component. It handles
     * common functionality for all of the form pages: retrieving data
     * from the vuex store that corresponds to the current form page
     * component, determining if a form is valid or dirty using the
     * vee-validate component, and preparing / submitting form data on
     * submit. It also has several slots for functionality that is
     * specific to the current form component. */
    data() {
        return {
            /* sections is form data that is not present in
             * formsets, including the form schema, and the form data.
             * */
            sections: [],

            /* context holds extra backend information that is not
             * typically altered by the user (with one exception being
             * the signature page for the 8843). */
            context: {},

            /* formsets are structured using the related name used in
             * the backend as the key, with the value being a list of
             * forms */
            formsets: {},

            /* dirty is an extra piece of information that is used on
             * top of the use of the vee validate component's
             * isDirty() method (which scans for form fields in the
             * DOM that have been changed). Typically, the dirty
             * member variable is used in formsets, where it's set to
             * true every time a form is deleted (which wouldn't have
             * otherwise been caught by the vee validate component). */
            dirty: false,

            /* noFormSubmit designates whether or not the page
             * component has form data which should be POSTed when the
             * user submits. */
            noFormSubmit: false,
        };
    },
    methods: {
        getFormVal(section, field_name) {
            return this.sections[section].data[field_name];
        },
        isDirty() {
            if (this.runCustomIsDirty != undefined) {
                if (this.runCustomIsDirty()) return true;
            }
            if (this.dirty) return true;
            if (this.noFormSubmit) return false;
            return this.$refs.validator.flags.dirty;
        },
        isValid() {
            if (this.runCustomValidation != undefined) {
                if (!this.runCustomValidation()) {
                    return false;
                }
            }
            if (this.noFormSubmit) return true;
            return this.$refs.validator.flags.valid;
        },
        handlePostSubmit(response, direction) {
            this.$store.commit('updateLastCompletedPage', this.pageName);
            if (this.postSubmit !== undefined) {
                return this.postSubmit({ response, direction });
            }
        },
        async submit(direction) {
            if (this.onSubmit) {
                /* The onSubmit method is async because occasionally
                 * it submits other data to the backend (as is the
                 * case with signature_8843). */
                await this.onSubmit(direction);
            }
            if (this.noFormSubmit) {
                return this.handlePostSubmit(null, direction);
            }
            let requestData = {
                current_page: this.pageName,
                ...cleanSubmissionDataWithFormsets({
                    sections: this.sections,
                    formsets: this.formsets,
                }),
                should_validate: direction === 'next',
            };
            return this.$store.getters.client
                .put(`tax-return/${this.$store.getters.getTrId}/`, requestData)
                .then((response) => {
                    for (var formsetName in this.formsets) {
                        var params = {
                            formset: this.formsets[formsetName],
                            response: response,
                            modelRelatedName: formsetName,
                        };
                        /* Make sure to clean formsets after we submit
                         * them.  One thing that needs to be done is
                         * remove forms that have been submitted with
                         * delete = true, so that we don't resubmit a
                         * delete request if we visit this page
                         * later. */
                        this.formsets[formsetName] = cleanFormsetOnSubmit(
                            params,
                        );
                    }
                    this.$store.commit(
                        `updateFormDataFromVue_${this.pageName}`,
                        {
                            sections: this.sections,
                            formsets: this.formsets,
                        },
                    );
                    return this.handlePostSubmit(response, direction);
                });
        },
    },
    created() {
        let pageData = this.$store.getters[`${this.pageName}Data`];
        this.context = pageData.context;
        this.sections = pageData.sections;
        if (pageData.formsets) {
            for (var formsetName in pageData.formsets) {
                this.$set(
                    this.formsets,
                    formsetName,
                    pageData.formsets[formsetName],
                );
            }
        }
    },
};

var chunkyRadioPageMixin = {
    /* This mixin can be used alongside the baseInterviewMixin for
     * pages that have one Yes/No question with the large (chunky)
     * radio buttons.  The main feature of this mixin is the fact that
     * it watches the one variable in this.sections and, under certain
     * conditions, may automatically submit and move on to the next
     * page when that variable is changed.  */
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        isLoading() {
            return this.loading;
        },
        getFieldName() {
            /* most chunky radio pages have the same field name and
             * page name */
            return this.fieldName ? this.fieldName : this.pageName;
        },
    },
    created() {
        this.$watch(
            `sections.0.data.${this.getFieldName()}`,
            (newVal, oldVal) => {
                if (oldVal == null) {
                    if (this.continueSubmit != undefined) {
                        if (!this.continueSubmit()) {
                            return;
                        }
                    }
                    this.loading = true;
                    setTimeout(() => {
                        this.$store.commit('changeLoading', true);
                        this.$store
                            .dispatch('submitAndMoveOn', {
                                shouldSubmit: true,
                                form: this,
                                direction: 'next',
                            })
                            .then(() => {
                                this.loading = false;
                                this.$store.commit('changeLoading', false);
                            });
                    }, 90);
                }
            },
        );
    },
};

var docParserMixin = {
    methods: {
        async submitFileForParsing(file, id, docType) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('file_type', file.type);
            formData.append('id', id);
            formData.append('tr_id', this.$store.getters.getTrId);
            formData.append('doc_type', docType);
            console.log(await this.$store.getters.client
                .post('/doc-parse/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }));
        },
        async getQRCodeLinkBase(params) {
            params['tr_id'] = this.$store.getters.getTrId;
            var { link, key } = (await this.$store.getters.client
                .get('/doc-parse-mobile-link/', { params })).data;
            this.key = key;
            return link;
        },
        async getMobileTaskStatusBase(params) {
            params['tr_id'] = this.$store.getters.getTrId;
            params['key'] = this.key;
            return (await this.$store.getters.client
                .get('/doc-parse-mobile-task-status/', { params })).data;
        },
    },
    created() {
        this.key = null;
    }
};

export default baseInterviewMixin;

export { chunkyRadioPageMixin, docParserMixin };
