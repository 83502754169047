<template>
    <div>
        <div class="interview-header">
            Did you have any
            <strong>income from non-U.S. sources</strong>
            in {{ context.year }}?

            <div class="interview-subheader">
                California has a tax on foreign (non-U.S.-sourced) income.
            </div>
        </div>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            fieldName: 'has_foreign_income',
            pageName: 'california_has_fincome',
        };
    },
};
</script>
