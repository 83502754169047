<template>
    <div>
        <div class="interview-header">
            <span v-html="context.header"></span>

            <div
                class="interview-subheader"
                v-html="context.subheader"
                v-if="context.subheader"
            ></div>
        </div>

        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <fly-row :schema="sections[1].schema" :data="sections[1].data" />
            <fly-row :schema="sections[2].schema" :data="sections[2].data" />
            <fly-row :schema="sections[3].schema" :data="sections[3].data" />
            <fly-col :schema="sections[4].schema" :data="sections[4].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'school',
        };
    },
};
</script>
