import personal_info from './personal_info';
import home_US from './home_US';
import home_abroad from './home_abroad';
import marital_status from './marital_status';
import marital_status_2 from './marital_status_2';
import dependents from './dependents';
import filed_taxes_any_prev_year from './filed_taxes_any_prev_year';
import non_recent_return from './non_recent_return';
import school from './school';
import letter_6419 from './letter_6419';

export default {
    personal_info,
    home_US,
    home_abroad,
    marital_status_2,
    marital_status,
    non_recent_return,
    filed_taxes_any_prev_year,
    dependents,
    school,
    letter_6419,
};
