<template>
    <div>
        <div class="interview-header">
            Did you
            <strong>file U.S. taxes last year ({{ context.year - 1 }})</strong>?
            <header-tooltip>
                I only filed form 8843 last year
                <template v-slot:content>
                    If you only filed form 8843, select "No".
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'filed_taxes_prev_year',
        };
    },
};
</script>
