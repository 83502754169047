<template>
    <div>
        <div class="interview-header">
            Did you receive a
            <strong>1095-A form</strong> in {{ context.year }}?
            <header-tooltip>
                What is a 1095-A form?
                <template v-slot:content>
                    If you purchased health insurance from the health insurance
                    Marketplace (e.g. Medi-Cal, Covered California, etc.) you
                    should expect to receive a 1095-A form from your insurer.
                    <br /><br />
                    If you were only enrolled in a private or a school-related
                    health insurance plan, you can answer "No".
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title> We're sorry </template>

            <template v-slot:body>
                If part III, column C of your form 1095-A has a number other
                other than 0, or if you want to claim a Premium Tax Credit, you
                must complete and attach form 8962 as part of your tax return.
                Thriftax does not currently support the filling of this form.
            </template>
        </modal>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'received_1095A',
            unsupported: false,
        };
    },
    methods: {
        continueSubmit() {
            if (this.boolEq(true, this.sections[0].data.received_1095A)) {
                this.unsupported = true;
                return false;
            }
            return true;
        },
    },
};
</script>
