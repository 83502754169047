<template>
    <div>
        <template v-if="context.only_filing_8843">
            <div class="review-card">
                <h1>Good news, {{ context.first_name }}</h1>
                <p class="lead">
                    You are not required to file a complete tax return for
                    {{ context.year }}, and are only required to file form 8843.
                    The law requires all F, J, Q, or M visas to file and ship
                    this form to the IRS. The form will be available for you at
                    a discounted price upon checkout.
                </p>
                <p class="text-small text-muted">
                    Please click next to continue
                </p>
            </div>
        </template>

        <template v-else>
            <div class="interview-header">Federal Return Summary</div>

            <div class="review-card">
                <div class="mb-4">
                    <h3 class="border-bottom text-center">Income</h3>
                    <div
                        class="d-flex"
                        v-for="income in context.incomes"
                        :key="income.id"
                    >
                        <div class="review-card-col-left">
                            {{ displayDocType(income.type) }} –
                            {{ income.payer_name }}
                        </div>

                        <div class="review-card-col-right">
                            {{ formatDollarAmount(income.amount) }}
                        </div>
                    </div>
                </div>
                <div v-if="deductions.length" class="mb-4">
                    <h3 class="border-bottom text-center">
                        Your Expenses and Deductions
                    </h3>

                    <div
                        class="d-flex"
                        v-for="deduction in deductions"
                        :key="deduction.name"
                    >
                        <div class="review-card-col-left">
                            {{ deduction.name }}
                        </div>

                        <div class="review-card-col-right">
                            {{ formatDollarAmount(deduction.amt) }}
                        </div>
                    </div>
                </div>

                <div v-if="otherAmts.length" class="mb-4">
                    <div
                        class="d-flex"
                        v-for="otherAmt in otherAmts"
                        :key="otherAmt.name"
                    >
                        <div class="review-card-col-left">
                            {{ otherAmt.name }}
                        </div>

                        <div class="review-card-col-right">
                            {{ formatDollarAmount(otherAmt.amt) }}
                        </div>
                    </div>
                </div>

                <div class="mb-2">
                    <div
                        class="d-flex"
                        v-for="taxAmt in taxAmts"
                        :key="taxAmt.name"
                    >
                        <div class="review-card-col-left">
                            {{ taxAmt.name }}
                        </div>

                        <div class="review-card-col-right">
                            {{ formatDollarAmount(taxAmt.amt) }}
                        </div>
                    </div>
                </div>

                <div class="d-flex h3 font-weight-bold border-top pt-2 p-0 m-0">
                    <div class="pr-3">
                        <span
                            v-if="context.amount_due <= 0"
                            class="text-success"
                        >
                            Your Federal Tax Refund:
                        </span>

                        <span v-else class="text-danger">
                            Your Federal Tax Liability (you need to pay)
                        </span>
                    </div>

                    <div class="review-card-col-right">
                        {{ formatDollarAmount(Math.abs(context.amount_due)) }}
                    </div>
                </div>
            </div>
        </template>

        <validation-observer ref="validator"> </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'review_federal',
            noFormSubmit: true,

            deductions: [],
            otherAmts: [],

            taxAmts: [],
        };
    },

    created() {
        var context = this.context;
        if (context.only_filing_8843) return;

        var deductions = context.deductions;

        var displayDeductions = [];

        if (deductions.charity) {
            displayDeductions.push({
                name: 'Charity and donations',
                amt: deductions.charity,
            });
        }

        if (deductions.loan_interest) {
            displayDeductions.push({
                name: 'Student loan interest',
                amt: deductions.loan_interest,
            });
        }

        if (deductions.standard_deductions) {
            displayDeductions.push({
                name: 'Standard deduction',
                amt: deductions.standard_deductions,
            });
        }

        if (deductions.treaty_exemption) {
            displayDeductions.push({
                name: 'Treaty exemptions',
                amt: deductions.treaty_exemption,
            });
        }

        if (deductions.tax_credits) {
            displayDeductions.push({
                name: 'Tax credits',
                amt: deductions.tax_credits,
            });
        }

        this.deductions = displayDeductions;

        var otherAmts = [];

        if (deductions.grants_excluded) {
            otherAmts.push({
                name: 'Other amounts excluded',
                amt: deductions.grants_excluded,
            });
        }

        if (deductions.other_non_taxable_amounts) {
            otherAmts.push({
                name: 'Other deductions and non-taxable amounts',
                amt: deductions.other_non_taxable_amounts,
            });
        }

        this.otherAmts = otherAmts;

        this.taxAmts.push({
            name: 'Taxable income',
            amt: context.taxable_income,
        });
        this.taxAmts.push({
            name: 'Taxes you paid (or were withheld)',
            amt: context.total_payments,
        });
        this.taxAmts.push({
            name: `Your total tax due for ${context.year}`,
            amt: context.total_tax,
        });
    },

    methods: {
        displayDocType: function (type) {
            switch (type) {
                case 'ACADEMIC':
                    return 'Scholarship/Grant';
                case 'COMMISSIONS':
                    return 'Fees/Commissions';
                case 'AWARD':
                    return 'Prize/Award';
            }
            return type;
        },
        hasItems(obj) {
            return (
                obj &&
                !(Object.keys(obj).length === 0 && obj.constructor === Object)
            );
        },
    },
};
</script>
