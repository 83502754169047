import { numberInputs } from '@/components/fly-form/inputs';

function cleanSubmissionData(sections) {
    let data = {};

    sections.forEach((section) => {
        section.schema.forEach((field) => {
            if (
                numberInputs.includes(field.type) &&
                section.data[field.name] !== null
            ) {
                var value = section.data[field.name];
                value = value.toString().replace(/,/g, '');
                section.data[field.name] = value;
            }
        });
        Object.assign(data, section.data);
    });

    return data;
}

export function cleanSubmissionDataWithFormsets(payload) {
    let data = {};
    if ('sections' in payload) {
        data = cleanSubmissionData(payload.sections);
    }
    for (let key in payload.formsets) {
        if (!payload.formsets[key]) {
            data[key] = [];
        } else {
            let formset = [];

            payload.formsets[key].forEach((form) => {
                let formData = {
                    id: form.id,
                    delete: form.delete,
                    ...cleanSubmissionData(form.sections),
                };
                formset.push(formData);
            });
            data[key] = formset;
        }
    }

    if ('nonFormFields' in payload) {
        data = {
            ...data,
            ...payload.nonFormFields,
        };
    }


    console.log(data);
    return data;
}

export default cleanSubmissionData;
