<template>
    <div>
        <div class="interview-header">
            Can you be claimed as a
            <strong>dependent</strong> on<strong>
                someone else's tax return?</strong
            >

            <header-tooltip size="lg">
                Who can be claimed as a dependent?
                <template v-slot:content>
                    You might be able to be claimed as a dependent only if
                    you're a resident of Canada or Mexico. <br /><br /><span
                        class="font-weight-normal"
                        >If your parents are U.S. citizens or U.S. residents,
                        they may be able to claim you as a dependent. If you've
                        been living with a relative that is a U.S. citizen or
                        U.S. resident for all of {{ context.year }}, they may be
                        able to claim you as a dependent. In the cases listed
                        above, verify with your relatives. For other cases,
                        select "No".</span
                    >
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'is_dependent',
        };
    },
};
</script>
