<template>
    <div>
        <div class="interview-header">Payment</div>
        <p class="lead" v-if="paymentRequestData.can_test">
            <span class="font-weight-bold">Note to user: </span>Since you have
            permissions to test the product, you do not need to pay. Please
            click next to continue.
        </p>
        <validation-observer ref="validator"></validation-observer>
        <checkout-base
            ref="checkoutBase"
            :payment-request-data="paymentRequestData"
            promo-codes-allowed
            :payment-request-context="paymentRequestContext()"
            :payment-complete="$store.getters.formsPurchased"
            @payment="postPayment"
            @promo-applied="applyPromo"
        >
        </checkout-base>
    </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import submitErrorLog from '@/services/error-logs';
import loading from '@/components/loading';
import checkoutBase from '@/components/utilities/checkout_base';
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    components: {
        CollapseTransition,
        checkoutBase,
    },
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'checkout',
            paymentRequestData: {},
        };
    },
    methods: {
        paymentRequestContext() {
            return {
                payment_context: 'std_tax_return',
                tr_id: this.$store.getters.getTrId,
            };
        },
        isDirty() {
            return true;
        },
        postPayment() {
            this.$store.commit('changeLoading', true);
            this.$store.commit('updateFormsPurchased', true);
            this.$store.dispatch('moveWithoutSubmitting', {
                shouldSubmit: false,
                direction: 'next',
            });
        },
        switchPages(direction) {
            if (this.paymentRequestData.can_test) {
                this.$store.dispatch('moveWithoutSubmitting', {
                    shouldSubmit: false,
                    direction: direction,
                });
                return;
            }
            if (direction == 'back') {
                this.$store.dispatch('moveWithoutSubmitting', {
                    shouldSubmit: false,
                    direction: direction,
                });
                return;
            }
            if (this.$store.getters.formsPurchased) {
                this.$store.dispatch('moveWithoutSubmitting', {
                    shouldSubmit: false,
                    direction: direction,
                });
            } else {
                if (!this.$refs.checkoutBase.isValid()) {
                    return;
                }
                this.$refs.checkoutBase.makePayment().then((paymentSuccess) => {
                    if (paymentSuccess) {
                        this.postPayment();
                    }
                });
            }
        },
        submit(direction) {
            // add data to $store for this page
            // submit to appropriate endpoint and return promise
            return;
        },
        applyPromo(promoCode) {
            if (!promoCode) return;
            var id = this.$store.getters.getTrId;
            this.$store.getters.client
                .post('/apply-promo/', {
                    promo_code: promoCode,
                    id: id,
                })
                .then((resp) => {
                    this.paymentRequestData = resp.data;
                    this.$store.commit('updateContext_checkout', resp.data);
                    this.$refs.checkoutBase.activatePromoSuccess();
                })
                .catch((err) => {
                    this.$refs.checkoutBase.activatePromoFailure(
                        err.response.data,
                    );
                });
        },
    },
    created() {
        this.paymentRequestData = this.context;
    },
    mounted() {
        if (!this.$store.getters.formsPurchased) {
            this.$ga.event('Filing', 'Page Reached', 'Checkout');
        }
    },
};
</script>
