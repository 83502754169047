<template>
    <div>
        <div class="interview-header">
            More about your <strong>spouse</strong>
        </div>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <fly-col :schema="sections[1].schema" :data="sections[1].data" />
            <fly-row
                :schema="sections[2].schema.slice(0, 2)"
                :data="sections[2].data"
            />
            <fly-col
                :schema="sections[2].schema.slice(2, 3)"
                :data="sections[2].data"
            />
            <p class="text-dark pb-0 mb-0">
                Please enter the number of days your spouse was in the U.S. in
                each of the following years:
            </p>
            <fly-row
                class="w-md-50"
                :schema="sections[3].schema"
                :data="sections[3].data"
            />
            <p class="text-dark pb-0 mb-0">
                Please enter your spouse's visa type (if any) in each of the
                following years:
            </p>
            <fly-row :schema="sections[4].schema" :data="sections[4].data" />
            <fly-col
                :schema="sections[5].schema.slice(0, 1)"
                :data="sections[5].data"
            >
            </fly-col>
            <fly-row
                :schema="sections[5].schema.slice(1, 3)"
                :data="sections[5].data"
                :cols="[8, 4]"
            />
            <fly-row
                :schema="sections[5].schema.slice(3, 6)"
                :data="sections[5].data"
            />

            <fly-col
                :schema="sections[6].schema.slice(0, 1)"
                :data="sections[6].data"
            >
            </fly-col>
            <fly-row
                :schema="sections[6].schema.slice(1, 3)"
                :data="sections[6].data"
                :cols="[8, 4]"
            />

            <fly-row
                :schema="sections[6].schema.slice(3, 6)"
                :data="sections[6].data"
            />
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title>We're sorry</template>

            <template v-slot:body>
                Since your spouse's green card/residency application was
                approved, they can file their taxes as a U.S. resident. If you
                made a mistake, click "Close" and change your answer. Otherwise,
                they should use a tax-filing software that supports U.S.
                residents.
            </template>
        </modal>

        <modal v-model="showFileSeparateFilingNotice">
            <template v-slot:title>File separately with Thriftax</template>

            <template v-slot:body>
                Since your spouse changed their visa after January 30,
                {{ context.year }}, we'll need to evaluate their residency more
                thoroughly before filling their 8843. Please have them open
                another account with us and file as you are doing now.
            </template>
            <template v-slot:footer>
                <btn @click="acknowledge()" app color="secondary" small>
                    Ok
                </btn>
            </template>
        </modal>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    data() {
        return {
            pageName: 'marital_status_2',

            unsupported: false,
            fileSeparately: false,
            isSubmitting: false,
            acknowledged: false,
            showFileSeparateFilingNotice: false,
        };
    },
    mixins: [baseInterviewMixin],

    methods: {
        continueSubmit() {
            if (this.isDirty()) this.isSubmitting = true;
            else this.isSubmitting = false;

            /* if the spouse changed their visa after january 30 of
             * the tax year, more residency logic needs to be done to
             * determine residency status -- show notice that spouse
             * needs to file separately */

            var fileSeparately =
                this.isAfterCutoff(
                    this.sections[1].data.previous_visa_change_date,
                ) && this.boolEq(true, this.sections[1].data.changed_visas);

            var continueSubmit = !(
                fileSeparately &&
                !this.acknowledged &&
                this.isSubmitting
            );

            if (!continueSubmit) {
                this.showFileSeparateFilingNotice = true;
            }

            return continueSubmit;
        },
        acknowledge() {
            this.acknowledged = true;
            this.$store.commit('changeDirection', 'next');
            this.$store.dispatch('submitAndMoveOn', {
                shouldSubmit: true,
                form: this,
                direction: 'next',
            });
        },
        isAfterCutoff(val) {
            var visaChange = new Date(val);
            var cutoff = new Date(this.context.year, 0, 31);
            return visaChange >= cutoff;
        },
    },
    created() {
        this.$watch(
            'sections.1.data.green_card_application_status',
            (newVal) => {
                if (newVal === 'Approved') {
                    this.unsupported = true;
                }
            },
        );
    },
};
</script>
