<template>
    <div class="nav pb-0 progress-menu">
        <div class="d-none">{{ page_name }}</div>
        <div class="section-bar w-100 d-flex justify-content-center p-0">
            <div class="container p-0">
                <ul class="section">
                    <li
                        :class="{
                            selected: sectionClass == 'federal',
                            available: availableSections.includes('federal'),
                        }"
                        @click="redirectSection('federal')"
                    >
                        Federal
                    </li>
                    <li
                        :class="{
                            selected: sectionClass == 'state',
                            available: availableSections.includes('state'),
                        }"
                        @click="redirectSection('state')"
                    >
                        <div>State</div>
                        <div
                            v-if="unrequiredSections.includes('state')"
                            class="text-clarify"
                        >
                            (Not Required)
                        </div>
                    </li>
                    <li
                        :class="{
                            selected: sectionClass == 'review',
                            available: availableSections.includes('review'),
                        }"
                        @click="redirectSection('review')"
                    >
                        Review
                    </li>
                </ul>
            </div>
        </div>
        <div
            v-if="sectionClass == 'federal'"
            class="w-100 d-flex justify-content-center p-0 container"
        >
            <ul class="sub-section">
                <li
                    :class="{
                        selected: subSectionClass == 'introduction',
                        available: availableSubSections.includes(
                            'introduction',
                        ),
                    }"
                    @click="redirectSubsection('introduction')"
                >
                    <div class="icon">
                        <i class="fas fa-handshake"></i>
                    </div>
                    <p class="sub-section-title">Introduction</p>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'residency',
                        available: availableSubSections.includes('residency'),
                    }"
                    @click="redirectSubsection('residency')"
                >
                    <div class="icon">
                        <i class="fas fa-passport"></i>
                    </div>
                    <p class="sub-section-title">Residency</p>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'aboutyou',
                        available: availableSubSections.includes('aboutyou'),
                    }"
                    @click="redirectSubsection('aboutyou')"
                >
                    <div class="icon">
                        <i class="fas fa-address-card"></i>
                    </div>
                    <p class="sub-section-title">About You</p>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'income',
                        available: availableSubSections.includes('income'),
                    }"
                    @click="redirectSubsection('income')"
                >
                    <div class="icon">
                        <i class="fas fa-money-check-alt"></i>
                    </div>
                    <p class="sub-section-title">Income</p>
                    <span
                        v-if="unrequiredSubs.includes('income')"
                        class="text-clarify"
                    >
                        (Not Required)
                    </span>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'deductions',
                        available: availableSubSections.includes('deductions'),
                    }"
                    @click="redirectSubsection('deductions')"
                >
                    <div class="icon">
                        <i class="fas fa-money-bill-wave"></i>
                    </div>
                    <p class="sub-section-title">Deductions</p>
                    <span
                        v-if="unrequiredSubs.includes('deductions')"
                        class="text-clarify"
                    >
                        (Not Required)
                    </span>
                </li>
            </ul>
        </div>
        <div
            v-else-if="sectionClass == 'state'"
            class="w-100 d-flex justify-content-center p-0 container"
        >
            <ul class="sub-section">
                <li
                    :class="{
                        selected: subSectionClass == 'state_visits',
                        available: availableSubSections.includes(
                            'state_visits',
                        ),
                    }"
                    @click="redirectSubsection('state_visits')"
                >
                    <div class="icon">
                        <i class="fas fa-flag-usa"></i>
                    </div>
                    <p class="sub-section-title">Presence</p>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'state_residency',
                        available: availableSubSections.includes(
                            'state_residency',
                        ),
                    }"
                    @click="redirectSubsection('state_residency')"
                >
                    <div class="icon">
                        <i class="fas fa-home"></i>
                    </div>
                    <p class="sub-section-title">Residency</p>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'state_return',
                        available: availableSubSections.includes(
                            'state_return',
                        ),
                    }"
                    @click="redirectSubsection('state_return')"
                >
                    <div class="icon">
                        <i class="fas fa-file-invoice"></i>
                    </div>
                    <p class="sub-section-title">State Return</p>
                </li>
            </ul>
        </div>
        <div
            v-else-if="sectionClass == 'review'"
            class="w-100 d-flex justify-content-center p-0 container"
        >
            <ul class="sub-section">
                <li
                    :class="{
                        selected: subSectionClass == 'finalize',
                        available: availableSubSections.includes('finalize'),
                    }"
                    @click="redirectSubsection('finalize')"
                >
                    <div class="icon">
                        <i class="fas fa-tasks"></i>
                    </div>
                    <p class="sub-section-title">Finalize</p>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'checkout',
                        available: availableSubSections.includes('checkout'),
                    }"
                    @click="redirectSubsection('checkout')"
                >
                    <div class="icon">
                        <i class="fas fa-shopping-cart"></i>
                    </div>
                    <p class="sub-section-title">Checkout</p>
                </li>
                <li
                    :class="{
                        selected: subSectionClass == 'send',
                        available: availableSubSections.includes('send'),
                    }"
                    @click="redirectSubsection('send')"
                >
                    <div class="icon">
                        <i class="fas fa-paper-plane"></i>
                    </div>
                    <p class="sub-section-title">Send</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: ['page_name'],
    data: function () {
        return {
            pageName: this.page_name,
            sections: {
                federal: {
                    subSection: {
                        introduction: [
                            'intro',
                            'is_US_citizen',
                            'has_green_card',
                            'applied_for_green_card',
                            'green_card_application_status',
                            'had_US_income',
                            'is_married',
                            'has_dependents',
                            'is_dependent',
                            'is_US_scholar',
                            'has_US_address',
                            'paid_interest_on_student_loan',
                            'gave_charity',
                            'filed_taxes_prev_year',
                            'filed_state_taxes_prev_year',
                        ],
                        residency: [
                            'current_visa',
                            'previous_visas',
                            'previous_country',
                        ],
                        aboutyou: [
                            'personal_info',
                            'filed_taxes_any_prev_year',
                            'non_recent_return',
                            'home_US',
                            'home_abroad',
                            'marital_status',
                            'marital_status_2',
                            'dependents',
                            'letter_6419',
                            'school',
                        ],
                        income: ['income'],
                        deductions: [
                            'fica',
                            'university_expenses',
                            'clarify_treaties',
                            'treaties',
                            'received_1095A',
                            'independent_expenses',
                            'charity_expenses',
                            'loan_expenses',
                            'add_info',
                            'previous_return_info',
                        ],
                    },
                },
                state: {
                    subSection: {
                        state_visits: ['state_visits'],
                        state_residency: [
                            'california_residency',
                            'state_return_unneeded',
                        ],
                        state_return: [
                            'california_has_fincome',
                            'california_fincome',
                            'california_nr',
                            'california_nr_spouse',
                            'california_had_out_of_state_purchases',
                            'california_out_of_state_purchases',
                            'california_r',
                            'cali_time_of_income',
                        ],
                    },
                },
                review: {
                    subSection: {
                        finalize: [
                            'is_being_emailed',
                            'due_info',
                            'refund_info',
                            'no_diff_return',
                            'review_federal',
                            'review_fica',
                            'review_cali',
                            'corresp_location',
                            'is_shipping_8843',
                        ],
                        checkout: ['checkout_options', 'checkout'],
                        send: ['signature_8843', 'gen_pdfs', 'shipping_info'],
                    },
                },
            },
            availableSections: [],
            availableSubSections: [],
            unrequiredSections: [],
            unrequiredSubs: [],
            sectionClass: null,
            subSectionClass: null,
        };
    },
    updated() {
        this.pageName = this.page_name;
        this.paint(this.pageName);
    },
    mounted() {
        this.enable(this.lastCompletedPage);
    },
    watch: {
        lastCompletedPage: function (lastCompletedPage) {
            this.enable(lastCompletedPage);
        },
    },
    methods: {
        enable: function (lastAvailablePage) {
            let lastCompletedPage = lastAvailablePage;
            this.availableSubSections = [];
            this.availableSections = [];
            this.unrequiredSections = [];
            this.unrequiredSubs = [];
            let validPages = this.$store.getters.validPages;

            let lastCompletedPageFound = false;

            for (let section in this.sections) {
                var subSecCaught = false;
                for (let sub in this.sections[section].subSection) {
                    var subPages = this.sections[section].subSection[sub];
                    var individualSubFound = false;
                    for (var i = 0; i < subPages.length; i++) {
                        if (validPages.includes(subPages[i])) {
                            if (!lastCompletedPageFound) {
                                this.availableSubSections.push(sub);
                                subSecCaught = true;
                            }
                            individualSubFound = true;
                        }
                    }
                    if (!individualSubFound) {
                        this.unrequiredSubs.push(sub);
                    }
                    if (subPages.includes(lastCompletedPage)) {
                        lastCompletedPageFound = true;
                    }
                }
                if (subSecCaught) {
                    this.availableSections.push(section);
                } else if (!lastCompletedPageFound) {
                    this.unrequiredSections.push(section);
                }
            }
        },
        paint: function (pageName) {
            for (let key in this.sections) {
                for (let sub in this.sections[key].subSection) {
                    if (this.sections[key].subSection[sub].includes(pageName)) {
                        this.sectionClass = key;
                        this.subSectionClass = sub;
                    }
                }
            }
        },
        redirectSubsection: function (subsection) {
            if (!this.availableSubSections.includes(subsection)) {
                return;
            }
            this.$store.commit('changeDirection', 'jump');
            let target = null;
            switch (subsection) {
                case 'introduction':
                    target = 'intro';
                    break;
                case 'residency':
                    target = 'current_visa';
                    break;
                case 'aboutyou':
                    target = 'personal_info';
                    break;
                case 'income':
                    target = 'income';
                    break;
                case 'deductions':
                    target = 'fica';
                    break;
                case 'state_visits':
                    target = 'state_visits';
                    break;
                case 'state_residency':
                    target = 'california_residency';
                    break;
                case 'state_return':
                    target = 'california_has_fincome';
                    break;
                case 'finalize':
                    target = 'due_info';
                    break;
                case 'checkout':
                    target = 'checkout_options';
                    break;
                case 'send':
                    target = 'gen_pdfs';
                    break;
            }
            this.$emit('redirect', target);
        },
        redirectSection: function (section) {
            if (!this.availableSections.includes(section)) {
                return;
            }
            this.$store.commit('changeDirection', 'jump');
            let target = null;
            switch (section) {
                case 'federal':
                    target = 'intro';
                    break;
                case 'state':
                    target = 'state_visits';
                    break;
                case 'review':
                    target = 'due_info';
                    break;
            }
            this.$emit('redirect', target);
        },
    },
    computed: {
        lastCompletedPage: function () {
            return this.$store.getters.lastCompletedPage;
        },
    },
};
</script>
