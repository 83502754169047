<template>
    <div>
        <error-banner v-model="invalid" />

        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <h3 class="my-3">Payer Information</h3>
            <p class="input-label mb-1">
                PAYER's name, street address, city or town, state or province,
                country, ZIP or foreign postal code, and telephone no.
            </p>
            <fly-col :schema="sections[1].schema" :data="sections[1].data" />
            <fly-row
                :fillTop="false"
                :schema="sections[2].schema"
                :data="sections[2].data"
            />
            <div class="w-lg-50 w-100">
                <fly-col
                    :schema="sections[3].schema"
                    :data="sections[3].data"
                />
            </div>

            <h3 class="mb-3 mt-2">Boxes 3, 4</h3>
            <fly-col
                @change="handleSec4($event)"
                :schema="sections[4].schema"
                :data="sections[4].data"
            />
            <h3 class="mb-3 mt-6">Boxes 16-18</h3>
            <fly-col
                class="mb-3"
                :schema="sections[5].schema"
                :data="sections[5].data"
            />
            <label class="input-label p-0 m-0"
                ><span class="h5">17</span> State/Payer's state no.</label
            >
            <fly-row
                :fillTop="false"
                :schema="sections[6].schema"
                :data="sections[6].data"
            />

            <fly-col :schema="sections[7].schema" :data="sections[7].data" />

            <modal v-model="unsupportedIncome">
                <template v-slot:title> We're sorry </template>

                <template v-slot:body>
                    Thriftax does not support other types of income reported on
                    the 1099-MISC income form.
                    <br />
                    <br />
                    If you made a mistake, click "Close" and change your answer.
                </template>
            </modal>
        </validation-observer>
    </div>
</template>

<script>
import incomeMixin from './mixins';

export default {
    mixins: [incomeMixin],
    data() {
        return {
            unsupportedIncome: false,
        };
    },
    methods: {
        handleSec4(event) {
            if (
                event.field === 'MISC1099_income_source_type' &&
                event.val === 'other'
            ) {
                this.unsupportedIncome = true;
            }
        },
        incomeSourceIsValid() {
            if (this.sections[4].data.MISC1099_income_source_type === 'other') {
                this.unsupportedIncome = true;
                return false;
            }
            return true;
        },
        runCustomValidation(payload, shouldValidate) {
            if (shouldValidate) {
                return this.incomeSourceIsValid();
            } else {
                payload.is_completed =
                    payload.is_completed && this.incomeSourceIsValid();
            }
            return true;
        },
    },
};
</script>
