<template>
    <div>
        <div class="interview-header">
            Good news! You qualify for a
            <strong> tax refund</strong> for the following returns
            <div class="interview-subheader">
                We need some information about how you want to receive your tax
                refund.
            </div>
        </div>

        <div class="col-lg-7 mx-auto">
            <validation-observer ref="validator">
                <div
                    v-for="(refund, i) in allRefunds"
                    class="formset-card"
                    :key="refund.title"
                >
                    <h3>
                        {{ refund.title }} –
                        {{ formatDollarAmount(refund.amt) }} Refund
                    </h3>
                    <fly-col
                        :schema="refund.sections[0].schema.slice(0, 4)"
                        :data="refund.sections[0].data"
                    />
                    <div v-if="refund.sections[0].schema.length == 11">
                        <fly-col
                            :schema="refund.sections[0].schema.slice(4, 5)"
                            :data="refund.sections[0].data"
                        ></fly-col>
                        <fly-col
                            :schema="refund.sections[0].schema.slice(5, 6)"
                            :data="refund.sections[0].data"
                        ></fly-col>
                        <fly-row
                            :schema="refund.sections[0].schema.slice(6, 8)"
                            :cols="[8, 4]"
                            :data="refund.sections[0].data"
                        ></fly-row>
                        <fly-row
                            :schema="refund.sections[0].schema.slice(8, 11)"
                            :cols="[4, 4, 4]"
                            :data="refund.sections[0].data"
                        ></fly-row>
                    </div>
                    <div v-if="i == 0 && allRefunds.length > 1" class="mt-2">
                        <span class="font-italic text-small mr-2"
                            >Want this payment information applied to all
                            returns?</span
                        >
                        <btn
                            @click="applyToAll(i)"
                            size="sm"
                            app
                            color="info"
                            disable-on-forms-purchased
                            >Apply</btn
                        >
                    </div>
                </div>
            </validation-observer>
        </div>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'refund_info',

            allRefunds: [],
        };
    },
    methods: {
        applyToAll(i) {
            this.dirty = true;
            var data = this.allRefunds[i].sections[0].data;
            for (var j = 1; j < this.allRefunds.length; j++) {
                for (var key in this.allRefunds[j].sections[0].data) {
                    if (key in data) {
                        this.allRefunds[j].sections[0].data[key] = data[key];
                    }
                }
            }
        },
        onSubmit(direction) {
            var sections = [];
            var stateRefunds = [];
            this.allRefunds.forEach((refund) => {
                if (refund.title == 'Federal') {
                    sections = refund.sections;
                } else {
                    stateRefunds.push(refund);
                }
            });
            this.sections = sections;
            this.formsets.state_returns = stateRefunds;
        },
    },
    created() {
        if (this.sections.length) {
            this.allRefunds.push({
                sections: this.sections,
                amt: this.context.fed_amt,
                title: 'Federal',
            });
        }
        this.formsets.state_returns.forEach((refund) => {
            this.allRefunds.push(refund);
        });
    },
};
</script>

<style scoped>
.box {
    display: flex;
    justify-content: center;
}
</style>
