<template>
    <div>
        <div class="interview-header">
            Good news,
            <strong>{{ context.first_name }}</strong
            >! You <strong> qualify </strong>for the following
            <strong>tax treaties</strong>
        </div>
        <validation-observer ref="validator">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Country</th>
                        <th scope="col">Income Type</th>
                        <th scope="col">Article</th>
                        <th scope="col months_claimed_col">
                            Months Claimed Already
                            <tooltip>
                                <i class="fas fa-info-circle" />

                                <template v-slot:content
                                    >You are eligible to benefit from a tax
                                    treaty between
                                    {{ formsets.treaty_links[0].country }} and
                                    the U.S. The IRS requires a disclosure of the
                                    number of months that you already used this
                                    tax treaty in previous U.S. tax returns.
                                    <br />
                                    <br />
                                    If this is your first year filing a U.S. tax
                                    return, enter 0.
                                    <br /><br />
                                    If you've already filed U.S. tax return(s) in
                                    the past, sum the number of months that you
                                    were present in the U.S. during each tax year
                                    and enter the total months below.
                                </template>
                            </tooltip>
                        </th>
                        <th scope="col" class="pr-1 usage_col">
                            Use This Treaty
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="treaty in formsets.treaty_links"
                        :key="treaty.id"
                    >
                        <td scope="row" class="align-middle">
                            {{ treaty.country }}
                        </td>
                        <td class="align-middle">
                            {{ getPurposeDisplayName(treaty.purpose) }}
                        </td>
                        <td class="align-middle">{{ treaty.article }}</td>
                        <td class="align-middle months_claimed_col pr-lg-10">
                            <fly-row
                                :fillTop="fillTop"
                                :data="treaty.sections[0].data"
                                :schema="treaty.sections[0].schema"
                            />
                        </td>
                        <td class="custom-align usage_col pl-6">
                            <fly-col
                                class="justifycontentcenter"
                                :data="treaty.sections[1].data"
                                :schema="treaty.sections[1].schema"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <fly-col :data="sections[0].data" :schema="sections[0].schema" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'treaties',
            t: false,

            fillTop: false,
        };
    },
    methods: {
        getPurposeDisplayName: function (purpose) {
            switch (purpose) {
                case 'dependent':
                    return 'Employee Income';
                case 'grant':
                    return 'Scholarship/Grant';
                case 'teaching':
                    return 'Teaching/Research';
                case 'student':
                    return 'Student/Trainee';
                case 'independent':
                    return 'Self-Employment Income';
            }
        },
    },
};
</script>

<style>
thead > tr th {
    text-align: start;
}
tbody > tr td {
    text-align: start;
}
/* .justifycontentcenter {
	display: flex;
	justify-content: center;
}
.custom-align {
	margin: auto;
	padding-top: 19px !important;
	padding-bottom: 0px !important;
}
.months_claimed_col {
	width: 250px;
}
.usage_col {
	width: 150px;
} */
</style>

<style scoped>
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    /* STYLES GO HERE */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tbody tr {
        border: 1px solid #eee;
        border-radius: 6px;
        padding: 0.5rem 0rem 0rem 0rem;
        margin-bottom: 24px;
    }

    td {
        border: none;
        position: relative;
        padding: 0.5rem 0.75rem 0.1rem;
        height: 60px;
    }
    td:nth-of-type(4) {
        height: 100%;
    }
    /* td:nth-of-type(4) {
		padding: 0 0 0 5%;
	} */
    td:before {
        position: absolute;
        color: black;
        top: 0px;
        left: 6px;
        width: 45%;
        padding-left: 10px;
        white-space: nowrap;
    }

    td:nth-of-type(1):before {
        content: 'Country';
    }
    td:nth-of-type(2):before {
        content: 'Income Type';
    }
    td:nth-of-type(3):before {
        content: 'Article';
    }
    td:nth-of-type(4):before {
        content: 'Months Claimed Already';
    }
    td:nth-of-type(5):before {
        content: 'Use This Treaty';
    }

    thead > tr th {
        text-align: start;
    }
    tbody > tr td {
        text-align: start;
        padding: 20px 17px 10px 17px;
    }
    /* 
	.justifycontentcenter {
		display: flex;
		justify-content: flex-start;
	}
	.custom-align {
		margin: auto;
		padding-top: 25px !important;
		padding-bottom: 0px !important;
	}
	.months_claimed_col {
		width: 100% !important;
	}
	.usage_col {
		width: 100% !important;
	} */
}

/* iphone 5 & 5s portrait */
@media only screen and (max-device-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
    /* STYLES GO HERE */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tbody tr {
        border: 1px solid #eee;
        border-radius: 6px;
        margin-bottom: 24px;

        padding: 0.5rem 0rem 0rem 0rem;
    }

    td {
        border: none;
        position: relative;
        padding: 0.5rem 0.75rem 0.1rem;
    }
    /* td:nth-of-type(4) {
		padding: 0 0 0 5%;
	} */
    td:before {
        position: absolute;
        color: black;
        top: 0px;
        left: 6px;
        width: 45%;
        padding-left: 10px;
        white-space: nowrap;
    }

    td:nth-of-type(1):before {
        content: 'Country';
    }
    td:nth-of-type(2):before {
        content: 'Income Type';
    }
    td:nth-of-type(3):before {
        content: 'Article';
    }
    td:nth-of-type(4):before {
        content: 'Months Claimed Already';
    }
    td:nth-of-type(5):before {
        content: 'Use This Treaty';
    }

    thead > tr th {
        text-align: start;
    }
    tbody > tr td {
        text-align: start;
        padding: 20px 17px 10px 17px;
    }
    /* .justifycontentcenter {
		display: flex;
		justify-content: flex-start;
	}
	.custom-align {
		margin: auto;
		padding-top: 25px !important;
		padding-bottom: 0px !important;
	}
	.months_claimed_col {
		width: 100% !important;
		padding-top: 27px !important;
	}
	.usage_col {
		width: 100% !important;
	} */
}
</style>
