<template>
    <div>
        <div class="interview-header">
            Your <strong>California return</strong>
            <div class="interview-subheader">
                Based on the information you've given us, you qualify as a
                California resident for tax purposes. Most of your state return
                information has been collected during the federal section, but
                we have a few more questions.
            </div>
        </div>
        <validation-observer ref="validator">
            <fly-col
                :data="sections[0].data"
                :schema="sections[0].schema.slice(0, 1)"
            />
            <fly-row
                align-vertical="end"
                :data="sections[0].data"
                :schema="sections[0].schema.slice(1, 3)"
            />
            <fly-col
                :data="sections[0].data"
                :schema="sections[0].schema.slice(3)"
            />
            <fly-col
                class="mb-2"
                v-if="shouldShowMovingExpenses"
                :data="sections[1].data"
                :schema="sections[1].schema"
            />
            <fly-col :data="sections[2].data" :schema="sections[2].schema" />
            <div v-if="warnInsurance">
                Are you sure you didn't have health insurance? 
                <br />
                <br />
                Please note that if you were not insured for
                the entire time you resided in California, you may be required to pay a
                penalty. If you are required to pay a penalty, you will be receive a
                letter from the California tax authorities with further
                instructions after you submit your tax forms.
            </div>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { strToNum } from '@/views/utilities';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'california_r',
        };
    },
    computed: {
        warnInsurance() {
            return this.boolEq(false, this.sections[2].data.health_insured_in_CA);
        },
        shouldShowMovingExpenses() {
            if (
                this.sections[0].data.moving_miles_old_to_old &&
                this.sections[0].data.moving_miles_old_to_new &&
                this.boolEq(true, this.sections[0].data.had_moving_expenses)
            ) {
                let movingMilesOldToOld = strToNum(
                    this.sections[0].data.moving_miles_old_to_old,
                );
                let movingMilesOldToNew = strToNum(
                    this.sections[0].data.moving_miles_old_to_new,
                );
                return movingMilesOldToNew - movingMilesOldToOld > 50;
            }
            return false;
        },
    },
};
</script>
