<template>
    <v-col class="mb-2 p-0" cols="12">
        <validation-observer ref="validator">
            <h1 class="display-1 font-weight text-center">
                You're eligible to<span class="font-weight-bold">
                    offset your self-employment expenses</span
                >.
            </h1>
            <p class="lead helper_text text-center mb-7">
                Please fill in all applicable expenses below.
            </p>
            <v-row>
                <v-col md="4" cols="12"
                    ><fly-col
                        :schema="sections[0].schema"
                        :data="sections[0].data"
                    ></fly-col>
                </v-col>
                <v-col md="4" cols="12"
                    ><fly-col
                        :schema="sections[1].schema"
                        :data="sections[1].data"
                    ></fly-col>
                </v-col>
                <v-col md="4" cols="12"
                    ><fly-col
                        :schema="sections[2].schema"
                        :data="sections[2].data"
                    ></fly-col>
                </v-col>
            </v-row>
        </validation-observer>
    </v-col>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'independent_expenses',
        };
    },
};
</script>
