<template>
    <div
        class="w-100 d-flex justify-content-center align-items-center"
        :class="{ 'interview-window': interviewHeight }"
    >
        <loading color="primary" :size="65"></loading>
    </div>
</template>

<script>
export default {
    props: { interviewHeight: { type: Boolean, default: true } },
};
</script>
