<template>
    <div>
        <error-banner v-model="invalid" />
        <h3 class="mb-1 mt-3">Payer Information</h3>
        <p class="input-label mb-1">
            PAYER's name, street address, city or town, state or province,
            country, ZIP or foreign postal code, and telephone no.
        </p>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <fly-row
                :fillTop="false"
                :schema="sections[1].schema"
                :data="sections[1].data"
            />
            <fly-col :schema="sections[2].schema" :data="sections[2].data" />
            <h3 class="mb-1 mt-4 pl-1">Boxes 1a-1g</h3>
            <fly-row :schema="sections[3].schema" :data="sections[3].data" />
            <fly-col :schema="sections[4].schema" :data="sections[4].data" />
            <fly-row :schema="sections[5].schema" :data="sections[5].data" />
            <fly-col :schema="sections[6].schema" :data="sections[6].data" />
            <h3 class="mb-1 mt-4 pl-1">Box 4</h3>
            <fly-row :schema="sections[7].schema" :data="sections[7].data" />
            <h3 class="mb-1 mt-4 pl-1">Boxes 14-16</h3>
            <fly-row :schema="sections[8].schema" :data="sections[8].data" />
            <fly-col :schema="sections[9].schema" :data="sections[9].data" />
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title> We're sorry </template>

            <template v-slot:body>
                Thriftax does not support income that is effectively connected
                with U.S. trade or business or a result of the sale or exchange
                of U.S. real property. If you made a mistake, you can change
                your answer and continue.
            </template>
        </modal>
    </div>
</template>

<script>
import incomeMixin from './mixins';

export default {
    mixins: [incomeMixin],
    data: function () {
        return {
            unsupported: false,
        };
    },
    mounted() {
        this.$watch('sections.8.data.is_effectively_connected', () => {
            this.incomeConnectionIsValid();
        });
    },
    methods: {
        incomeConnectionIsValid() {
            this.unsupported = false;
            let val = this.sections[8].data.is_effectively_connected;
            if (this.boolEq(true, val)) {
                this.unsupported = true;
            }
            return !this.unsupported;
        },
        runCustomValidation(payload, shouldValidate) {
            if (shouldValidate) {
                return this.incomeConnectionIsValid();
            } else {
                payload.is_completed =
                    payload.is_completed && 
                    this.incomeConnectionIsValid();
            }
            return true;
        },
    },
};
</script>
