<template>
  <div>
    <div class="interview-header">
      Did you
      <strong>have U.S. related income</strong>
      in
      {{ context.year }}?
      <header-tooltip>
        What's considered to be income?
        <template v-slot:content>
          Wages, scholarships, au pair income, awards, capital gains, taxable
          interest, stocks or other assets traded through a U.S. broker, or any
          other income originating from a U.S.-related source.
        </template>
      </header-tooltip>
    </div>

    <validation-observer ref="validator">
      <fly-col :schema="sections[0].schema" :data="sections[0].data"></fly-col>
    </validation-observer>
  </div>
</template>

<script>
import sendConversion from "@/views/send_conversion";
import baseInterviewMixin from "@/views/interview/mixins";
import { chunkyRadioPageMixin } from "@/views/interview/mixins";

export default {
  mixins: [baseInterviewMixin, chunkyRadioPageMixin],
  data() {
    return {
      pageName: "had_US_income"
    };
  },
  methods: {
    onSubmit(direction) {
      if (direction === "next") {
        this.$ga.event("Filing", "Page Submit", "Had US income Page");
        if (this.sections[0].data.had_US_income) {
          sendConversion({
            address: "AW-417237183/v9diCMap-vcBEL-R-sYB"
          });
        } else
          sendConversion({
            address: "AW-417237183/8cIECLSv-vcBEL-R-sYB"
          });
      }
    }
  }
};
</script>
