<template>
  <div class="text-center">
    <figure
      class="max-w-11rem mb-3 mx-auto bg-primary p-3 rounded-circle shadow-sm"
    >
      <img
        class="img-fluid"
        src="@/assets/svg/icons/icon-white-18.svg"
        alt="SVG"
      />
    </figure>
    <h2 class="mb-3">How can we help?</h2>
    <p class="mb-1">What is the nature of your concern?</p>
    <validation-observer ref="validator">
      <select-input
        v-model="category"
        :choices="categoryChoices"
        validation-rules="required"
      />
      <div v-if="category === 'Other - Type your own'">
        <p class="lead-2 mb-1 mt-3">
          Enter a category that fits your concern
        </p>
        <text-input validation-rules="required" v-model="customCategory" />
      </div>
      <p class="mb-1 mt-3">Describe your concern in detail:</p>
      <text-area :rows="3" v-model="message" validation-rules="required" />
    </validation-observer>
    <div class="mt-2 px-3">
      <p class="mb-1">
        You'll receive a reply to
        <span class="font-weight-bold">{{ $store.getters.userEmail }}</span>
      </p>
      <p class="mb-0">
        We are doing our best to reply within 48 hours, excluding weekends and
        holidays.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      category: ``,
      categoryChoices: [],
      customCategory: ``
    };
  },
  props: {
    context: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {
    var choices = [
      "Report a technical Issue - Something isn't working right",
      "Filing - Not sure about a question",
      "Filing - General",
      "Post-filing - Handling my tax forms after printing",
      "Payments and Checkout",
      "Other - Type your own"
    ];
    for (var i = 0; i < choices.length; i++) {
      this.categoryChoices.push([choices[i], choices[i]]);
    }
  },
  methods: {
    async sendRequest() {
      if (!(await this.$refs.validator.validate())) return false;
      var category;
      if (this.category === "Other - Type your own") {
        category = "OTHER - " + this.customCategory;
      } else {
        category = this.category;
      }
      var payload = {
        tr_id: this.context.trId,
        current_page: this.context.currentPage,
        message: this.message,
        category: category
      };
      var response = await this.$store.getters.client.post(
        "/support/",
        payload
      );
      let toast = this.$toasted.show("Your support request has been sent", {
        iconPack: "fontawesome",
        icon: "fa-check",
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 5000,
        className: "t-toast"
      });

      this.message = "";
      this.category = "";
      this.$refs.validator.reset();
      return true;
    }
  }
};
</script>
