<template>
    <div>
        <div class="interview-header">Citizenship and Residency</div>

        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
            <fly-row :schema="sections[1].schema" :data="sections[1].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'previous_country',
        };
    },
    created() {
        this.$watch('sections.0.data.country_of_citizenship', (val) => {
            this.sections[0].data.country_of_previous_residence = val;
        });
    },
};
</script>
