<template>
    <div>
        <error-banner v-model="invalid" />

        <validation-observer ref="validator">
            <fly-row
                :schema="sections[0].schema"
                :data="sections[0].data"
            />

            <div class="w-lg-50 w-100">
                <fly-col
                    :schema="sections[1].schema"
                    :data="sections[1].data"
                    @blur="handleFederalStateBlur()"
                />
                <fly-row
                    :schema="sections[2].schema"
                    :data="sections[2].data"
                />
            </div>
            <h3 class="mb-2 mt-4 d-lg-none">Boxes 5-7 — First Row</h3>
            <fly-row
                row-breakpoint="lg"
                :schema="sections[3].schema"
                :data="sections[3].data"
                @change="event => updateStateRowRequirements(0, event)"
                @blur="handleFederalStateBlur()"
            />
            <h3 class="mb-2 mt-4 d-lg-none">Boxes 5-7 — Second Row</h3>
            <fly-row
                row-breakpoint="lg"
                :schema="sections[4].schema"
                :data="sections[4].data"
                @blur="handleFederalStateBlur()"
            />

            <error-card :errors="addErrors"></error-card>

            <modal v-model="unsupportedIncome">
                <template v-slot:title> We're sorry </template>
                <template v-slot:body>
                    We do not support nonresident tax filing with business
                    income. Please file your taxes offline.
                    <br />
                    <br />
                    If you made a mistake, click "Close" and change your answer.
                </template>
            </modal>
        </validation-observer>
    </div>
</template>

<script>
import incomeMixin from './mixins';
import { strToNum } from '@/views/utilities';

export default {
    mixins: [incomeMixin],
    data() {
        return {
            unsupportedIncome: false,
            addErrors: [],
        };
    },
    methods: {
        runCustomValidation(payload, shouldValidate) {
            if (shouldValidate) {
                if (!this.onceValid()) {
                    this.unsupportedIncome = true;
                }
                if (this.federalStateInconsistent()) {
                    this.handleFederalStateBlur();
                }
                return this.onceValid() && !this.federalStateInconsistent();
            } else {
                payload.is_completed =
                    payload.is_completed && this.onceValid() && !this.federalStateInconsistent();
            }
            return true;
        },
        handleFederalStateBlur() {
            if (this.federalStateInconsistent()) {
                this.addErrors = ["The amounts in box 7 rows 1 and 2 should sum to the amount in field 1"];
            } else {
                this.addErrors = [];
            }
        },
        updateStateRowRequirements(stateRowIdx, event) {
            var data = event.data;
            const section = {
                0: 3,
                1: 4,
            }[stateRowIdx]
            var required = false;
            for (const field in data) {
                if (data[field]) {
                    console.log(data[field])
                    required = true;
                    break;
                }
            }
            for (const field of this.sections[section].schema) { 
                var rules = field.validation_rules.split('|');
                if (required) {
                    if (!rules.includes('required')) {
                        rules.push('required')
                    }
                } else {
                    rules = rules.filter(r => r !== 'required')
                }
                field.validation_rules = rules.join('|')
            }
        },
        federalStateInconsistent() {
            if (
                (!this.sections[3].data.state_0 &&
                    !this.sections[4].data.state_1) ||
                ((this.sections[3].data.state_income_0 ==
                    null ||
                    this.sections[3].data.state_income_0 ==
                        '') &&
                    (this.sections[4].data.state_income_1 ==
                        null ||
                        this.sections[4].data.state_income_1 ==
                            '')) || this.sections[1].data.nec == '' || this.sections[1].data.nec == null
            ) {
                return false;
            }

            let stateIncome = 0;
            if (this.sections[3].data.state_0) {
                var stateWages = this.sections[3].data
                    .state_income_0;

                if (!isNaN(strToNum(stateWages))) {
                    stateIncome += strToNum(stateWages);
                }
            }
            if (this.sections[4].data.state_1) {
                var stateWages = this.sections[4].data
                    .state_income_1;

                if (!isNaN(strToNum(stateWages))) {
                    stateIncome += strToNum(stateWages);
                }
            }

            return stateIncome != strToNum(this.sections[1].data.nec)
        },
        onceValid() {
            let val = this.sections[0].data.once
            return val == true || val == 'true'
        },
    },
    mounted() {
        this.$watch("sections.0.data.once", _ => {
            this.unsupportedIncome = !this.onceValid()
        });
    },
};
</script>
