<template>
    <div>
        <div class="interview-header">
            Your
            <strong>out-of-state purchases</strong>
            <div class="interview-subheader">
                Please list all of the purchases you made in
                {{ context.year }} on which you did not pay California use tax
                or sales tax
            </div>

            <header-tooltip>
                How do I know if I paid use / sales tax on an item?
                <template v-slot:content>
                    You should include any items that you may have purchased in
                    person in another state or country, then brought back to
                    California for use, storage, consumption, or to be given
                    away, and therefore did not pay any Californian tax on.
                    <br />
                    <br />
                    Ordering online for delivery into California often takes
                    state tax into account, so you probably won't need to
                    include those purchases. If you're not sure, you can check
                    the receipts.
                </template>
            </header-tooltip>
        </div>
        <validation-observer ref="validator">
            <div
                v-for="p in formsFiltered"
                class="formset-card"
                :key="p.displayId"
            >
                <fly-col
                    :schema="p.sections[0].schema"
                    :data="p.sections[0].data"
                />
                <fs-delete @click="dirty = true" v-model="p.delete">
                    Remove Purchase
                </fs-delete>
            </div>

            <div class="d-flex justify-content-center mb-4">
                <btn
                    @click="addForm"
                    app
                    size="sm"
                    color="info"
                    disable-on-forms-purchased
                    ><i class="fas fa-plus mr-2"></i> Add Item</btn
                >
            </div>
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>
    </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from '@/views/interview/formset';
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'california_out_of_state_purchases',
        };
    },
    computed: {
        formsFiltered() {
            return filterDeletedForms({
                formset: this.formsets.out_of_state_purchases,
            });
        },
    },
    methods: {
        addForm() {
            addFormsetItem(this.formsets.out_of_state_purchases);
        },
    },
};
</script>
