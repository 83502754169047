<template>
    <validation-observer ref="validator">
        <fly-col
            :schema="sections[0].schema"
            :data="sections[0].data"
        />
        <h3 class="mb-2 mt-3">About the payer</h3>
        <fly-row
            :fillTop="false"
            :schema="sections[1].schema"
            :data="sections[1].data"
        />
        <h3 class="mb-2 mt-3">From where did you receive this income?</h3>
        <fly-row
            :schema="sections[2].schema"
            :data="sections[2].data"
        />

    </validation-observer>
</template>

<script>
import incomeMixin from './mixins';

export default {
    mixins: [incomeMixin],

};
</script>
