var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('validation-observer',{ref:"validator"},[_c('fly-col',{attrs:{"schema":_vm.sections[0].schema,"data":_vm.sections[0].data}}),_c('fly-col',{attrs:{"schema":_vm.sections[1].schema,"data":_vm.sections[1].data}}),_c('fly-col',{attrs:{"schema":_vm.sections[2].schema.slice(0, 1),"data":_vm.sections[2].data}}),_c('fly-row',{attrs:{"schema":_vm.sections[2].schema.slice(1),"data":_vm.sections[2].data}}),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"interview-header-2"},[_vm._v(" List all your "),_c('strong',[_vm._v(" visits to the U.S. between "+_vm._s(_vm.context.year - 5)+" and "+_vm._s(_vm.context.year))])]),_c('div',{staticClass:"mb-2"},[_c('header-tooltip',{attrs:{"tooltip-font-size-inherit":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" This information can be found on your passport within your ink entry stamps. Alternatively, you may rely on form I-94 to view your visits to the U.S. over the last 5 years. You can get this form on the "),_c('a',{attrs:{"target":"_blank","href":"https://i94.cbp.dhs.gov/I94/#/history-search"}},[_vm._v("Department of Homeland Security Website "),_c('i',{staticClass:"fas fa-external-link-alt"}),_vm._v(".")])]},proxy:true}])},[_vm._v(" Where can I find this information? ")])],1)]),_c('error-card',{staticClass:"mb-4",attrs:{"errors":_vm.errors}}),_c('table',{staticClass:"table"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"w-30",attrs:{"scope":"col"}},[_vm._v(" Visa Type/Visitor Status ")]),_c('th',{staticClass:"w-30",attrs:{"scope":"col"}},[_vm._v("Arrived On")]),_c('th',{staticClass:"w-30",attrs:{"scope":"col"}},[_vm._v(" Left/Intend to Leave On ")]),_c('th',{attrs:{"scope":"col"}})])]),_c('tbody',[_vm._l((_vm.USVisitsFiltered),function(visit){return [_c('tr',{key:("sections-" + (visit.displayId))},[_c('td',{staticClass:"align-top",class:{
                'pb-1': visit.errors.length
              }},[_c('fly-row',{attrs:{"data":visit.sections[0].data,"schema":[visit.sections[0].schema[0]],"no-margin-bottom":""}})],1),_c('td',{staticClass:"align-top",class:{
                'pb-1': visit.errors.length
              }},[_c('fly-row',{attrs:{"data":visit.sections[0].data,"schema":[visit.sections[0].schema[1]],"no-margin-bottom":""}})],1),_c('td',{staticClass:"align-top",class:{
                'pb-1': visit.errors.length
              }},[_c('fly-row',{attrs:{"data":visit.sections[0].data,"schema":[visit.sections[0].schema[2]],"no-margin-bottom":""}})],1),_c('td',{class:{
                'pb-1': visit.errors.length
              }},[_c('fs-delete',{on:{"click":function($event){_vm.dirty = true}},model:{value:(visit.delete),callback:function ($$v) {_vm.$set(visit, "delete", $$v)},expression:"visit.delete"}})],1)]),_c('tr',{key:("error-" + (visit.displayId))},[(visit.errors.length)?_c('td',{staticClass:"no-border-top pt-0 text-danger",attrs:{"colspan":"3"}},[_c('i',{staticClass:"fas fa-warning mr-1"}),_vm._v(" "+_vm._s(visit.errors[0])+" ")]):_vm._e()])]})],2)]),_c('div',[_c('btn',{attrs:{"disable-on-forms-purchased":"","size":"sm","color":"info","app":""},on:{"click":_vm.addForm}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" Add Visit")])],1)],1),_c('modal',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" No Tax Return Required ")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" Based on the data you entered, you were not present in the U.S. in "+_vm._s(_vm.context.year)+", meaning you are not required to file U.S. tax returns for "+_vm._s(_vm.context.year)+". If this is a mistake, please click \"close\" and update your visits to the U.S. to match your records. ")]},proxy:true}]),model:{value:(_vm.showNoTaxesModal),callback:function ($$v) {_vm.showNoTaxesModal=$$v},expression:"showNoTaxesModal"}}),_c('modal',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" We're sorry ")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" Based on the information you entered, you are deemed a U.S. resident for tax purposes, which is a case that Thriftax does not support. Please use an alternative service that supports tax filing for U.S. residents. ")]},proxy:true}]),model:{value:(_vm.showSubstantialPresenceModal),callback:function ($$v) {_vm.showSubstantialPresenceModal=$$v},expression:"showSubstantialPresenceModal"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interview-header"},[_vm._v(" Your "),_c('strong',[_vm._v("time in the U.S.")])])}]

export { render, staticRenderFns }