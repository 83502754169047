function formsetsPresent(obj) {
    if (!obj) {
        return false;
    }
	for (var key of Object.keys(obj)) {
		if (obj[key] && obj[key].length) {
			return true;
		}
	}

	return false;
}

export default formsetsPresent;
