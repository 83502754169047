<template>
  <div>
    <div class="interview-header">
      Your
      <strong>time in the U.S.</strong>
    </div>

    <validation-observer ref="validator">
      <fly-col :schema="sections[0].schema" :data="sections[0].data"></fly-col>
      <fly-col :schema="sections[1].schema" :data="sections[1].data"></fly-col>
      <fly-col
        :schema="sections[2].schema.slice(0, 1)"
        :data="sections[2].data"
      ></fly-col>
      <fly-row
        :schema="sections[2].schema.slice(1)"
        :data="sections[2].data"
      ></fly-row>

      <div class="my-3">
        <div class="interview-header-2">
          List all your
          <strong>
            visits to the U.S. between
            {{ context.year - 5 }} and {{ context.year }}</strong
          >
        </div>
        <div class="mb-2">
          <header-tooltip tooltip-font-size-inherit>
            Where can I find this information?

            <template v-slot:content>
              This information can be found on your passport within your ink
              entry stamps. Alternatively, you may rely on form I-94 to view
              your visits to the U.S. over the last 5 years. You can get this
              form on the
              <a
                target="_blank"
                href="https://i94.cbp.dhs.gov/I94/#/history-search"
                >Department of Homeland Security Website
                <i class="fas fa-external-link-alt"></i>.</a
              >
            </template>
          </header-tooltip>
        </div>
      </div>

      <error-card :errors="errors" class="mb-4" />

      <table class="table">
        <thead class="thead-light">
          <tr>
            <th scope="col" class="w-30">
              Visa Type/Visitor Status
            </th>
            <th scope="col" class="w-30">Arrived On</th>
            <th scope="col" class="w-30">
              Left/Intend to Leave On
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="visit in USVisitsFiltered">
            <tr :key="`sections-${visit.displayId}`">
              <td
                class="align-top"
                :class="{
                  'pb-1': visit.errors.length
                }"
              >
                <fly-row
                  :data="visit.sections[0].data"
                  :schema="[visit.sections[0].schema[0]]"
                  no-margin-bottom
                />
              </td>
              <td
                class="align-top"
                :class="{
                  'pb-1': visit.errors.length
                }"
              >
                <fly-row
                  :data="visit.sections[0].data"
                  :schema="[visit.sections[0].schema[1]]"
                  no-margin-bottom
                />
              </td>
              <td
                class="align-top"
                :class="{
                  'pb-1': visit.errors.length
                }"
              >
                <fly-row
                  :data="visit.sections[0].data"
                  :schema="[visit.sections[0].schema[2]]"
                  no-margin-bottom
                />
              </td>
              <td
                :class="{
                  'pb-1': visit.errors.length
                }"
              >
                <fs-delete @click="dirty = true" v-model="visit.delete" />
              </td>
            </tr>
            <tr :key="`error-${visit.displayId}`">
              <td
                v-if="visit.errors.length"
                colspan="3"
                class="no-border-top pt-0 text-danger"
              >
                <i class="fas fa-warning mr-1"></i>
                {{ visit.errors[0] }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div>
        <btn
          disable-on-forms-purchased
          size="sm"
          color="info"
          app
          @click="addForm"
          ><i class="fas fa-plus mr-2"></i> Add Visit</btn
        >
      </div>
    </validation-observer>

    <modal v-model="showNoTaxesModal">
      <template v-slot:title> No Tax Return Required </template>
      <template v-slot:body>
        Based on the data you entered, you were not present in the U.S. in
        {{ context.year }}, meaning you are not required to file U.S. tax
        returns for {{ context.year }}. If this is a mistake, please click
        "close" and update your visits to the U.S. to match your records.
      </template>
    </modal>

    <modal v-model="showSubstantialPresenceModal">
      <template v-slot:title> We're sorry </template>
      <template v-slot:body>
        Based on the information you entered, you are deemed a U.S. resident for
        tax purposes, which is a case that Thriftax does not support. Please use
        an alternative service that supports tax filing for U.S. residents.
      </template>
    </modal>
  </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from "@/views/interview/formset";
import baseInterviewMixin from "@/views/interview/mixins";
import { INVALID_NO_MESSAGE, VALID } from "@/api/clean-form-data";

export default {
  mixins: [baseInterviewMixin],
  data() {
    return {
      pageName: "previous_visas",

      errors: [],
      showSubstantialPresenceModal: false,
      showNoTaxesModal: false,
      watchers: [],
      arriveBeforeDepartErr:
        "This visit's arrival date should be before its departure date",
      overlapErr: "This visit overlaps with another visit"
    };
  },
  computed: {
    USVisitsFiltered() {
      return filterDeletedForms({ formset: this.formsets.USVisits });
    },
    backendErrors() {
      return this.$store.getters.backendErrors_previous_visas;
    }
  },
  methods: {
    continueSubmit() {
      let currYearEnd = new Date(this.context.year + 1, 0, 1);
      let currYearStart = new Date(this.context.year - 1, 11, 31);
      var visitedThisYear = false;
      for (var visit of this.USVisitsFiltered) {
        let arrive = this.parseDate(visit.sections[0].data.arrive);
        let depart = this.parseDate(visit.sections[0].data.depart);
        if (!(depart <= currYearStart || arrive >= currYearEnd)) {
          visitedThisYear = true;
        }
      }
      this.showNoTaxesModal = ! this.visitedThisYear;
      return visitedThisYear;
    },
    validateVisits: function() {
      this.errors = [];
      for (var watcher of this.watchers) {
        /* stop the watchers from watching! */
        watcher();
      }
      this.watchers = [];
      var errorAdded = false;

      if (!this.USVisitsFiltered.length) {
        this.errors.push("Please add at least one visit before moving on");
        return;
      }
      let visitedThisYear = false;
      for (var i = 0; i < this.USVisitsFiltered.length; i++) {
        this.USVisitsFiltered[i].sections[0].schema[0].addErrors = VALID;
        this.USVisitsFiltered[i].sections[0].schema[1].addErrors = VALID;
        this.USVisitsFiltered[i].sections[0].schema[2].addErrors = VALID;
        this.USVisitsFiltered[i].errors = [];
      }
      for (var section of this.sections) {
        for (var field of section.schema) {
          field.addErrors = VALID;
        }
      }

      var foundValidEarliestVisit = false;
      var earliestVisitCurrVisa = this.convertToComparableDate(
        this.sections[1].data.first_visit_current_visa
      );

      for (let i = 0; i < this.USVisitsFiltered.length; i++) {
        let visit = this.USVisitsFiltered[i];
        var currArrive = visit.sections[0].data.arrive;

        currArrive = this.convertToComparableDate(currArrive);

        if (
          currArrive == earliestVisitCurrVisa &&
          visit.sections[0].data.visa_type == this.context.visa_type
        )
          foundValidEarliestVisit = true;

        if (
          visit.sections[0].data.arrive != null &&
          visit.sections[0].data.depart != null &&
          !visit.delete
        ) {
          let arrive = this.parseDate(visit.sections[0].data.arrive);
          let depart = this.parseDate(visit.sections[0].data.depart);
          if (arrive > depart) {
            visit.errors.push(this.arriveBeforeDepartErr);
            errorAdded = true;
            this.USVisitsFiltered[i].sections[0].schema[1].addErrors = VALID;
            this.USVisitsFiltered[i].sections[0].schema[2].addErrors = VALID;
            var watchVisit = this.USVisitsFiltered[i];
            this.watchers.push(
              this.$watch(
                () => {
                  return watchVisit.sections;
                },
                () => {
                  var visit = watchVisit;
                  if (visit.delete) {
                    return;
                  }
                  let arrive = this.parseDate(visit.sections[0].data.arrive);
                  let depart = this.parseDate(visit.sections[0].data.depart);
                  var invalid;
                  if (arrive <= depart) {
                    invalid = false;
                    if (visit.errors.length) {
                      visit.errors = visit.errors.filter(
                        e => e !== this.arriveBeforeDepartErr
                      );
                    }
                  } else {
                    invalid = true;
                    if (!visit.errors.includes(this.arriveBeforeDepartErr)) {
                      visit.errors.push(this.arriveBeforeDepartErr);
                    }
                  }
                  if (invalid) {
                    visit.sections[0].schema[1].addErrors = INVALID_NO_MESSAGE;
                    visit.sections[0].schema[2].addErrors = INVALID_NO_MESSAGE;
                  } else {
                    visit.sections[0].schema[1].addErrors = VALID;
                    visit.sections[0].schema[2].addErrors = VALID;
                  }
                },
                { deep: true }
              )
            );
          }
          if (!errorAdded) {
            for (let j = 0; j < this.USVisitsFiltered.length; j++) {
              if (j != i) {
                if (
                  this.USVisitsFiltered[j].sections[0].data.arrive != null &&
                  this.USVisitsFiltered[j].sections[0].data.depart != null &&
                  !this.USVisitsFiltered[j].delete
                ) {
                  let otherArrive = this.parseDate(
                    this.USVisitsFiltered[j].sections[0].data.arrive
                  );
                  let otherDepart = this.parseDate(
                    this.USVisitsFiltered[j].sections[0].data.depart
                  );
                  if (
                    !(depart < otherArrive || arrive > otherDepart) &&
                    !(
                      this.USVisitsFiltered[j].errors.includes(
                        this.overlapErr
                      ) &&
                      this.USVisitsFiltered[i].errors.includes(this.overlapErr)
                    )
                  ) {
                    var visit1 = this.USVisitsFiltered[i];
                    var visit2 = this.USVisitsFiltered[j];

                    if (!visit1.errors.includes(this.overlapErr)) {
                      visit1.errors.push(this.overlapErr);
                    }
                    if (!visit2.errors.includes(this.overlapErr)) {
                      visit2.errors.push(this.overlapErr);
                    }

                    errorAdded = true;
                    this.watchers.push(
                      this.$watch(
                        () => {
                          return {
                            visit1Data: visit1.sections[0].data,
                            visit2Data: visit2.sections[0].data
                          };
                        },
                        visitsData => {
                          var visit1Data = visitsData.visit1Data;
                          var visit2Data = visitsData.visit2Data;

                          var arrive = new Date(visit1Data.arrive);
                          var depart = new Date(visit1Data.depart);

                          var otherArrive = new Date(visit2Data.arrive);
                          var otherDepart = new Date(visit2Data.depart);

                          if (
                            depart < otherArrive ||
                            arrive > otherDepart ||
                            visit1.delete ||
                            visit2.delete
                          ) {
                            if (visit1.errors.length) {
                              visit1.errors = visit1.errors.filter(
                                e => e !== this.overlapErr
                              );
                            }
                            if (visit2.errors.length) {
                              visit2.errors = visit2.errors.filter(
                                e => e !== this.overlapErr
                              );
                            }
                          } else {
                            if (!visit1.errors.includes(this.overlapErr)) {
                              visit1.errors.push(this.overlapErr);
                            }
                            if (!visit2.errors.includes(this.overlapErr)) {
                              visit2.errors.push(this.overlapErr);
                            }
                          }
                        },
                        {
                          deep: true
                        }
                      )
                    );
                  }
                }
              }
            }
          }
        }
      }
      const latestVisit = this.USVisitsFiltered.reduce(function(prev, current) {
        return new Date(prev.sections[0].data.depart) >
          new Date(current.sections[0].data.depart)
          ? prev
          : current;
      });

      if (
        latestVisit.sections[0].data.visa_type != this.context.visa_type &&
        latestVisit.sections[0].data.arrive
      ) {
        latestVisit.sections[0].schema[0].addErrors = INVALID_NO_MESSAGE;
        latestVisit.errors.push(this.latestVisitVisaErr());
        this.watchers.push(
          this.$watch(
            () => {
              return latestVisit.sections;
            },
            () => {
              var visit = latestVisit;
              if (visit.delete) {
                return;
              }
              let visa_type = visit.sections[0].data.visa_type;
              var invalid;
              if (this.context.visa_type == visa_type) {
                invalid = false;
                if (visit.errors.length) {
                  visit.errors = visit.errors.filter(
                    e => e !== this.latestVisitVisaErr()
                  );
                }
              } else {
                invalid = true;
                if (!visit.errors.includes(this.latestVisitVisaErr())) {
                  visit.errors.push(this.latestVisitVisaErr());
                }
              }

              visit.sections[0].schema[0].addErrors = invalid
                ? INVALID_NO_MESSAGE
                : VALID;
            },
            { deep: true }
          )
        );
        errorAdded = true;
      }

      if (!foundValidEarliestVisit) {
        this.errors.push(this.earliestVisitErr());
        this.watchers.push(
          this.$watch(
            () => {
              return {
                earliestVisit: this.sections[1].data.first_visit_current_visa,
                visits: this.USVisitsFiltered
              };
            },
            data => {
              if (!data.earliestVisit) {
                return;
              }
              var firstVisitCurrentVisa = this.convertToComparableDate(
                data.earliestVisit
              );

              var foundArrival = false;
              for (var visit of data.visits) {
                if (
                  !visit.sections[0].data.visa_type ||
                  !visit.sections[0].data.arrive
                ) {
                  continue;
                }
                if (
                  visit.sections[0].data.visa_type == this.context.visa_type
                ) {
                  if (
                    this.convertToComparableDate(
                      visit.sections[0].data.arrive
                    ) == firstVisitCurrentVisa
                  ) {
                    foundArrival = true;
                  }
                }
              }

              var invalid;
              if (foundArrival) {
                invalid = false;
                if (this.errors.length) {
                  this.errors = this.errors.filter(
                    e => e !== this.earliestVisitErr()
                  );
                }
              } else {
                invalid = true;
                if (!this.errors.includes(this.earliestVisitErr())) {
                  this.errors.push(this.earliestVisitErr());
                }
              }
              this.sections[1].schema[0].addErrors = invalid
                ? INVALID_NO_MESSAGE
                : VALID;
            },
            { deep: true }
          )
        );
        errorAdded = true;
        this.sections[1].schema[0].addErrors = INVALID_NO_MESSAGE;
      }

      var changedVisas = this.sections[2].data.changed_visas;
      if ((changedVisas == "true" || changedVisas == true) && !errorAdded) {
        var earliestVisitCurrVisa;
        var visitsDescending = this.USVisitsFiltered.slice();

        const self = this;
        visitsDescending.sort(function(v1, v2) {
          var v2arrive = Date.parse(self.getArrive(v2));
          var v1arrive = Date.parse(self.getArrive(v1));
          if (v2arrive > v1arrive) {
            return 1;
          } else if (v2arrive < v1arrive) {
            return -1;
          } else {
            return 0;
          }
        });

        var foundPreviousVisa = false;
        for (var visit of visitsDescending) {
          var visaType = this.getVisaType(visit);
          if (visaType != this.context.visa_type) {
            if (visaType == this.sections[2].data.previous_visa) {
              if (this.getYear(this.getDepart(visit)) == this.context.year) {
                foundPreviousVisa = true;
                break;
              }
            } else {
              break;
            }
          }
        }
        if (!foundPreviousVisa) {
          if (!this.errors.includes(this.previousVisaError())) {
            this.errors.push(this.previousVisaError());
          }
          this.sections[2].schema[1].addErrors = INVALID_NO_MESSAGE;
        }
      }

      if (errorAdded || this.errors.length) {
        return;
      }
    },
    previousVisaError() {
      return `You indicated to us in the question above (highlighted in red) that you switched from a(n) ${this.sections[2].data.previous_visa} visa. Please include a visit during ${this.context.year} that has this visa type. This visit should be before your ${this.context.visa_type} visit(s).`;
    },
    latestVisitVisaErr() {
      return `Ensure that this visit has a visa type of ${this.context.visa_type}, your current visa type`;
    },
    earliestVisitErr() {
      return `Ensure that your earliest visit on your current ${this.context.visa_type} visa is included in your visits table below. If you entered the wrong first entry date with your current visa, you may fix it in the highlighted red field above`;
    },
    convertToComparableDate(dateStr) {
      if (!dateStr) {
        return dateStr;
      }
      if (dateStr.includes("-")) {
        var yyyy = dateStr.slice(0, 4);
        var mm = dateStr.slice(5, 7);
        var dd = dateStr.slice(8);
        dateStr = `${mm}/${dd}/${yyyy}`;
        return dateStr;
      }
      return dateStr;
    },
    parseDate(dateStr) {
      if (!dateStr) {
        return dateStr;
      }
      if (dateStr.includes("-")) {
        var yyyy = dateStr.slice(0, 4);
        var mm = dateStr.slice(5, 7);
        var dd = dateStr.slice(8);
        dateStr = `${mm}/${dd}/${yyyy}`;
      }
      return Date.parse(dateStr);
    },
    addForm() {
      addFormsetItem(this.formsets.USVisits);
    },
    getArrive(visit) {
      return visit.sections[0].data.arrive;
    },
    getYear(dateStr) {
      var yyyy;
      if (dateStr.includes("-")) {
        yyyy = dateStr.slice(0, 4);
      } else {
        yyyy = dateStr.slice(-4);
      }
      return Number(yyyy);
    },
    getDepart(visit) {
      return visit.sections[0].data.depart;
    },
    getVisaType(visit) {
      return visit.sections[0].data.visa_type;
    },
    runCustomIsDirty() {
      return this.backendErrors.length;
    },
    runCustomValidation() {
      this.validateVisits();
      var visitsSpecificErr = false;
      for (var visit of this.USVisitsFiltered) {
        if (visit.errors.length) {
          visitsSpecificErr = true;
        }
      }
      return !this.errors.length && !visitsSpecificErr;
    },
    onSubmit(direction) {
      this.$store.commit("clearErrors_previous_visas");
      if (direction === "next") {
        this.$ga.event("Filing", "Page Submit", "Previous Visas Page");
      }
    },
    postSubmit(params) {
      var response = params.response;
      if (response.data.substantially_present) {
        var error = {
          code: "substantially_present"
        };
        this.$store.commit(`addError_previous_visas`, error);
        this.$store.commit("changeLoading", false);
        return { continue: false };
      }
    }
  },
  created() {
    this.backendErrors.forEach(error => {
      if (error.code == "substantially_present") {
        this.showSubstantialPresenceModal = true;
        this.dirty = true;
      }
    });
    this.$store.commit("clearErrors_previous_visas");
  }
};
</script>

<style scoped>
.no-border-top {
  border-width: 0px;
}

.visit_row {
  margin-bottom: 2px !important;
}
thead tr th {
  text-align: start;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  /* STYLES GO HERE */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tbody tr {
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 0.5rem 0rem;
    margin-bottom: 24px;
  }

  td {
    border: none;
    position: relative;
    padding: 0.5rem 0.75rem;
  }
  td:nth-of-type(4) {
    padding: 0 0 0 5%;
    text-align: start;
  }
  td:before {
    position: absolute;
    color: black;
    top: 0px;
    left: 6px;
    width: 20%;
    padding-left: 10px;
    white-space: nowrap;
  }

  td:nth-of-type(1):before {
    content: "Visa Type/Visitor Status";
  }
  td:nth-of-type(2):before {
    content: "Arrived On";
  }
  td:nth-of-type(3):before {
    content: "Left/Intend to Leave On";
  }
  .custom_btn {
    padding: 0;
  }
}

/* iphone 5 & 5s portrait */
@media only screen and (max-device-width: 568px) and (orientation: portrait) {
  /* STYLES GO HERE */
  /* STYLES GO HERE */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tbody tr {
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 0.5rem 0rem;
    margin-bottom: 24px;
  }

  td {
    border: none;
    position: relative;
    padding: 0.5rem 0.75rem 0.1rem;
  }
  td:nth-of-type(4) {
    padding: 0 0 0 5%;
    text-align: start;
  }
  td:before {
    position: absolute;
    color: black;
    top: 0px;
    left: 6px;
    width: 20%;
    padding-left: 10px;
    white-space: nowrap;
  }

  td:nth-of-type(1):before {
    content: "Visa Type/Visitor Status";
  }
  td:nth-of-type(2):before {
    content: "Arrived On";
  }
  td:nth-of-type(3):before {
    content: "Left/Intend to Leave On";
  }
  .custom_btn {
    padding: 0;
  }
}

/* @media (min-width: 992px) {
  .section {
    width: 80%;
  }
  .sub_section {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .section {
    width: 70%;
  }
  .sub_section {
    width: 70%;
  }
} */
</style>
