<template>
  <div class="view-app">
    <modal v-model="testDownloadModal">
      <template v-slot:title> Download Forms </template>
      <template v-slot:body>
        <btn
          app
          v-for="ret in availableTestReturns"
          :key="ret.name"
          @click="downloadTestForm(ret)"
          block
          :loading="ret.loading"
          color="info"
        >
          {{ ret.name }}</btn
        >
        <btn app @click="getTestPack()" block color="info"> Test Pack</btn>
        <div class="h4 text-danger mt-3" v-if="testFormDownloadMenuError">
          <i class="fas fa-warning"></i>
          {{ testFormDownloadMenuError }}
        </div>
      </template>
    </modal>
    <!-- <div v-if="nonMainWindow">
            <div class="col vertical-center-screen">
                <div class="container d-flex justify-content-center">
                    <div class="card col-md-6 col-lg-4">
                        <div class="card-body lead">
                            You are filing your taxes with Thriftax in another
                            window. Click "Use Here" to use Thriftax here.
                            <div
                                class="row d-flex justify-content-end mt-3 mb-0"
                            >
                                <v-btn
                                    elevation="0"
                                    class="mr-2"
                                    @click="
                                        initialTokenInvalid = true;
                                        $router.push({
                                            name: 'Home',
                                        });
                                    "
                                    >Close</v-btn
                                ><v-btn
                                    @click="makeMainWindow()"
                                    elevation="0"
                                    dark
                                    >Use Here</v-btn
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-else-if="$store.getters.maintenanceMode">
        </div>
        -->

    <maintenance v-if="$store.getters.maintenanceMode" />
    <div v-else>
      <app-bar class="interview-page-padding-x">
        <div>
          <btn
            @click="redirectToHome"
            app
            size="xs"
            color=""
            class="btn-outline-light"
          >
            <mdi> exit_to_app </mdi>
            Save + Quit
          </btn>
          <btn
            v-if="$store.getters.tester"
            @click="openTestingModal"
            app
            size="xs"
            color=""
            class="btn-outline-light ml-2"
          >
            Test Forms
          </btn>
        </div>

        <div>
          <btn
            @click="showSupport = !showSupport"
            app
            size="xs"
            color=""
            class="btn-outline-light"
          >
            <mdi> question_answer </mdi>

            Support
          </btn>
        </div>
      </app-bar>

      <progress-menu
        class="mt-0 mb-5 pt-0 pb-0 shadow nav"
        v-bind:page_name="currentPage"
        @redirect="redirect"
        ref="progressMenu"
      />

      <div class="w-100">
        <div class="container p-0">
          <error-banner v-model="invalid" class="mb-3" />
          <transition :name="direction" mode="out-in">
            <interview-page-loading v-if="loading" />
            <component
              v-else
              class="interview-window interview-window-sm w-100 interview-page-padding-x"
              :is="currentPage"
              ref="form"
              key="currentPage"
              :showCtrlButtons.sync="showCtrlButtons"
            ></component>
          </transition>
          <div
            v-if="showCtrlButtons"
            class="ctrl-btn-wrapper interview-page-padding-x"
          >
            <span v-if="isFirstPage"></span>
            <back-button v-else @click="switchPages('back')" />
            <next-button :end="isFinalPage" @click="handleNextButton" />
          </div>
        </div>
      </div>
    </div>

    <modal v-model="showSupport">
      <template v-slot:body>
        <help
          :context="{
            currentPage: currentPage,
            trId: $store.getters.getTrId
          }"
          ref="supportModal"
        ></help>
      </template>

      <template v-slot:footer>
        <btn @click="showSupport = false" color="secondary" app size="sm">
          Close
        </btn>
        <btn
          :loading="sendSupportRequestLoading"
          @click="sendSupportRequest()"
          color="primary"
          app
          size="sm"
        >
          Send
          <i class="fas fa-paper-plane bs-pl-1"></i>
        </btn>
      </template>
    </modal>
  </div>
</template>

<script>
//import flyCol from '@/components/fly-form/fly-column';
//import flyRow from '@/components/fly-form/fly-row';
//import flyInput from '@/components/fly-form/fly-input';
import Vue from "vue";

import progressMenu from "@/components/interview/progress-menu";
import nextButton from "@/components/interview/next-button";
import backButton from "@/components/interview/back-button";
import introductionPages from "./introduction";
import residencyPages from "./residency";
import aboutYouPages from "./about-you";
import incomePages from "./income";
import deductionsPages from "./deductions";
import statePages from "./state";
import reviewPages from "./review";
import help from "@/views/help";
import maintenance from "@/views/maintenance";
import submitErrorLog from "@/services/error-logs";
import appBar from "@/components/app-bar";
import interviewPageLoading from "@/components/interview/page-loading";

//const progressMenu () => import('@/components/interview/progress-menu');
//const flyRow = () => import('@/components/fly-form/fly-row');
//const flyCol = () => import('@/components/fly-form/fly-column');
//const flyInput = () => import('@/components/fly-form/fly-input');
//const nextButton = () => import('@/components/interview/next-button');
//const backButton = () => import('@/components/interview/back-button');
//const introductionPages = () => import('./introduction');
//const residencyPages = () => import('./residency');
//const aboutYouPages = () => import('./about-you');
//const incomePages = () => import('./income');
//const deductionsPages = () => import('./deductions');
//const statePages = () => import('./state');
//const reviewPages = () => import('./review');
//const help = () => import('@/views/help');
//const maintenance = () => import('@/views/maintenance');
//const submitErrorLog = () => import('@/services/error-logs');
//const appBar = () => import('@/components/app-bar');
//const interviewPageLoading = () => import('@/components/interview/page-loading');

export default {
  beforeRouteLeave(to, from, next) {
    if (
      this.initialTokenInvalid ||
      !this.$store.getters.isAuthenticated(0) ||
      this.$store.getters.redirectingFromInterview ||
      this.allowLeave || this.$store.getters.maintenanceMode
    ) {
      next();
    } else if (this.$refs.form && !this.$refs.form.isDirty()) {
      next();
    } else {
      var r = confirm("Changes that you made may not be saved.");
      if (r == true) {
        next();
      } else {
        next(false);
      }
    }
  },
  data() {
    return {
      initialTokenInvalid: false,
      invalid: false,
      nonMainWindow: false,
      showSupport: false,
      allowLeave: false,
      sendSupportRequestLoading: false,
      switchingPages: false, // for the use of page switching functions defined in page components

      showCtrlButtons: true,

      availableTestReturns: [],
      testDownloadModal: false,
      testFormDownloadMenuError: ""
    };
  },
  computed: {
    isFirstPage() {
      return this.currentPage === "intro";
    },
    isFinalPage() {
      return (
        this.currentPage === "gen_pdfs" || this.currentPage === "shipping_info"
      );
    },
    currentPage() {
      return this.$store.getters.currentPage;
    },
    loading: function() {
      return this.$store.getters.loading;
    },
    direction: function() {
      return this.$store.getters.direction;
    },
    navButtonsShouldShow: function() {
      if (this.currentPage == "income" || this.currentPage == "checkout") {
        return false;
      }
      return true;
    },
    containerWidth: function() {
      if (this.currentPage == "income") return 12;
      return 10;
    }
  },
  watch: {
    currentPage() {
      this.invalid = false;
      this.showCtrlButtons = true;
    }
  },
  methods: {
    async openTestingModal() {
      try {
        var response = await this.$store.getters.client.get("/return-types/", {
          params: {
            tester: true,
            tr_id: this.$store.getters.getTrId,
            ignore_purchased: true
          }
        });
      } catch (e) {
        let toast = this.$toasted.show("An error occurred", {
          iconPack: "fontawesome",
          icon: "fa-warning",
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 5000,
          className: "t-toast"
        });
      }

      this.availableTestReturns = [];

      for (var i = 0; i < response.data.context.returns.length; i++) {
        this.availableTestReturns.push({
          name: response.data.context.returns[i],
          loading: false
        });
      }
      this.testDownloadModal = true;
    },
    getTestPack() {
      this.$store.getters.client
        .get(`test-pack/${this.$store.getters.getTrId}/`)
        .then(resp => {
          var exportObj = resp.data;
          var dataStr =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(exportObj));
          var downloadAnchorNode = document.createElement("a");
          downloadAnchorNode.setAttribute("href", dataStr);
          downloadAnchorNode.setAttribute("download", "testcase.json");
          document.body.appendChild(downloadAnchorNode); // required for firefox
          downloadAnchorNode.click();
          downloadAnchorNode.remove();
        });
    },
    downloadTestForm(ret) {
      var type = ret.name;
      ret.loading = true;
      this.testFormDownloadMenuError = "";
      this.$store.getters.client
        .get(`/print-test-pdfs/`, {
          params: {
            tr_id: this.$store.getters.getTrId,
            type: type
          },
          responseType: "arraybuffer"
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          var title = "";
          link.download = `${type}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
          ret.loading = false;
        })
        .catch(err => {
          console.dir(err);
          this.testFormDownloadMenuError = `An error occurred: ${err.response.data}`;
          ret.loading = false;
        });
    },
    handleNextButton() {
      if (this.isFinalPage) {
        this.redirectToHome();
      } else {
        this.switchPages("next");
      }
    },
    getTestForms(type) {
      this.$store.getters.client
        .get(`gen-pdfs/${this.$store.getters.getTrId}/`, {
          params: {
            return_type: type
          },
          responseType: "arraybuffer"
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          // const fileName = response.headers["Content-Disposition"].match(
          // 	/filename=(.*)/
          // )[1];
          var fileName;
          if (type == "Family 8843 Forms") {
            fileName = `Thriftax Family 8843 Forms.pdf`;
          } else {
            fileName = `Thriftax ${type} Tax Forms.pdf`;
          }
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    async sendSupportRequest() {
      this.sendSupportRequestLoading = true;
      if (!(await this.$refs.supportModal.sendRequest())) {
        this.sendSupportRequestLoading = false;
        return;
      }
      this.sendSupportRequestLoading = false;
      this.showSupport = false;
    },
    redirectToHome: function() {
      this.allowLeave = true;

      if (this.$refs.form.isDirty()) {
        this.$refs.form.submit("back");
      }

      this.$router.push({ name: "Dashboard" });
      this.allowLeave = false;
    },
    formIsValid() {
      return this.$refs.form.$refs.validator.validate().then(() => {
        return this.$refs.form.isValid();
      });
    },
    completePageSwitch(direction) {
      /* if current page component has a getSwitchDirection
       * method defined, use the return value of that method to
       * set the transition animation for the current page
       * switch. transDir is the direction for the animation,
       * while direction is the page switch direction for
       * business logic */
      var transDir;
      if (this.$refs.form.getSwitchDirection != undefined) {
        transDir = this.$refs.form.getSwitchDirection();
      } else {
        transDir = direction;
      }

      this.$store.commit("changeDirection", transDir);
      this.invalid = false;
      this.$store.commit("changeLoading", true);
      let shouldSubmit = this.$refs.form.isDirty();
      this.$store
        .dispatch("submitAndMoveOn", {
          shouldSubmit: shouldSubmit,
          form: this.$refs.form,
          direction: direction
        })
        .catch(error => {
          this.$store.commit("maintenanceMode", true);
          submitErrorLog(error);
        });
    },
    switchPages(direction) {
      try {
        if (
          this.loading ||
          !this.$refs.form ||
          (this.$refs.form &&
            this.$refs.form.isLoading &&
            this.$refs.form.isLoading()) ||
          this.switchingPages
        ) {
          /* ignore request if new page is currently being
           * rendered */
          return;
        }

        if (
          this.$refs.form !== undefined &&
          this.$refs.form.switchPages !== undefined
        ) {
          this.switchingPages = true;
          this.$refs.form.switchPages(direction);
          this.switchingPages = false;
          return;
        }

        if (direction === "next") {
          return this.formIsValid().then(valid => {
            if (valid) {
              this.invalid = false;
              /* if current page vue component has a
               * continueSubmit method defined, check if
               * that continueSubmit method returns true
               * before continuing. */
              if (
                this.$store.getters.shouldSubmit({
                  shouldSubmit: this.$refs.form.isDirty(),
                  direction
                }) &&
                this.$refs.form.continueSubmit != undefined
              ) {
                if (!this.$refs.form.continueSubmit()) {
                  return;
                }
              }
              this.completePageSwitch(direction);
            } else {
              this.invalid = true;
              return;
            }
          });
        } else {
          this.completePageSwitch(direction);
        }
      } catch (error) {
        this.$store.commit("maintenanceMode", true);
        submitErrorLog(error);
      }
    },
    redirect(value) {
      this.invalid = false;
      this.$store.commit("changeLoading", true);
      this.$store.dispatch("jumpTo", value);
    },
    makeMainWindow() {
      location.reload();
    }
  },
  async created() {
    this.$store.commit("changeLoading", true);
    localStorage.openpages = Date.now();
    var self = this;
    var onLocalStorageEvent = function(e) {
      if (e.key == "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
        self.nonMainWindow = true;
      }
      if (e.key == "page_available") {
      }
    };
    window.addEventListener("storage", onLocalStorageEvent, false);
    if (this.nonMainWindow) return;
  },
  async mounted() {
    this.$store.commit("maintenanceMode", false);
    try {
      var response = await this.$store.getters.client.get('/mount-interview/');
    }
    catch (e) {
      submitErrorLog(e);
      this.$store.commit('maintenanceMode', true);
    }
    if (response.data.maintenance_mode) {
      this.$store.commit("maintenanceMode", true);
      return;
    } else {
      this.$store.commit("maintenanceMode", false);
    }
    if (response.data.tester) {
      this.$store.commit("tester", true);
    }
    this.$store.commit("changeDirection", "jump");
    this.$store.dispatch("mountInterview").then(validPages => {
      this.$nextTick(() => {
        this.$refs.progressMenu.enable(this.$store.getters.lastCompletedPage);
      });
    });
  },
  components: {
    nextButton,
    backButton,
    progressMenu,
    ...introductionPages,
    ...residencyPages,
    ...aboutYouPages,
    ...incomePages,
    ...deductionsPages,
    ...statePages,
    ...reviewPages,
    help,
    maintenance,
    appBar,
    interviewPageLoading
  }
};
</script>
