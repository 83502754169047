<template>
  <div>
    <h3 class="mt-3 mb-1">Tips</h3>
    <validation-observer ref="validator">
      <fly-col :schema="sections[0].schema" :data="sections[0].data" />
      <fly-col :schema="sections[1].schema" :data="sections[1].data" />
    </validation-observer>
  </div>
</template>

<script>
import headerTooltip from "../../../components/ui/header-tooltip.vue";
import incomeMixin from "./mixins";

export default {
  components: { headerTooltip },
  mixins: [incomeMixin]
};
</script>
