<template>
    <div>
        <div class="interview-header">
            About your
            <strong>
                home in {{ context.country_of_previous_residence }}</strong
            >
        </div>

        <validation-observer ref="validator">
            <fly-row
                :cols="[8, 4]"
                :schema="sections[0].schema"
                :data="sections[0].data"
            />
            <fly-row :schema="sections[1].schema" :data="sections[1].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'home_abroad',
        };
    },
};
</script>
