<template>
    <div>
        <div class="interview-header">
            Let's
            <strong> offset your school-related expenses</strong>

            <div class="interview-subheader font-size-base">
                You reported scholarship / fellowship grants in the total amount
                of
                {{ formatDollarAmount(context.grants) }}. Now, let's offset this
                income to reduce your tax liability and increase your tax
                refund.
                <br />
                <br />
                Below are qualified expenses you may offset your scholarship /
                fellowship grant with. Please enter amounts that you paid for
                with your scholarship / fellowship grant and amounts that were
                automatically applied for you towards these expenses in the
                fields below. For example, if your school applied an amount of
                your scholarship / fellowship grant towards your tuition cost,
                enter the corresponding amount under tuition.
                <strong>Do not</strong> include any amounts you used to cover
                non-qualified costs such as housing, transportation etc.
            </div>
        </div>
        <validation-observer ref="validator">
            <fly-row :schema="sections[1].schema" :data="sections[1].data" />
            <fly-row :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'university_expenses',
        };
    },
};
</script>
