<template>
    <div>
        <div class="interview-header">
            Did you make any
            <span class="font-weight-bold">charitable donations</span> to a
            <span class="font-weight-bold">U.S.-based organization</span> in
            {{ context.year }}?
            <header-tooltip>
                What types of donations qualify?
                <template v-slot:content>
                    Consider donations you've made to U.S.-based non-profit
                    organizations, for which you have adequate records or
                    receipts.
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'gave_charity',
        };
    },
};
</script>
