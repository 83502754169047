<template>
  <div>
    <div class="interview-header">
      Let's get your
      <strong>signature </strong>on your 8843 form
      <div class="interview-subheader">
        Please sign in the signature area below. You may review your 8843 form
        before signing by clicking the button below.
      </div>
    </div>
    <div class="row d-flex justify-content-center mb-4">
      <div class="col-md-5 mb-0 pb-0 mb-">
        <btn
          app
          block
          color="info"
          @click="fetchTaxForms('Federal')"
          :loading="loading"
          >Review my form</btn
        >
      </div>
    </div>

    <p class="mb-1 h3 text-center">Signature:</p>
    <div class="d-flex justify-content-center">
      <div class="w-100 w-lg-75">
        <error-card :errors="errors" />
        <div class="pad" @click="dirty = true">
          <VueSignaturePad
            id="signature"
            width="100%"
            height="150px"
            :options="options"
            ref="signaturePad"
          />
        </div>
        <div class="d-flex justify-content-start pt-2">
          <btn class="mr-2" app color="info" size="sm" @click="undo()"
            >Undo</btn
          >
          <btn class="mr-2" app color="info" size="sm" @click="clearSignature"
            >Clear</btn
          >
        </div>
      </div>
    </div>
    <validation-observer ref="validator"> </validation-observer>
  </div>
</template>

<script>
import baseInterviewMixin from "@/views/interview/mixins";

export default {
  mixins: [baseInterviewMixin],
  data() {
    return {
      pageName: "signature_8843",
      noFormSubmit: true,
      loading: false,
      options: {
        penColor: "blue",
        minDistance: 5,
        maxWidth: 3,
        onEnd: () => {
            this.dirty = true;
        },
      },
      errors: [],
      renderCanvas: false
    };
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
      this.dirty = true;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.dirty = true;
    },
    submitSignature(direction) {
      var { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      var payload = {};
      payload.should_validate = direction == 'next' ? true : false;
      payload["image"] = data;
      payload["id"] = this.$store.getters.getTrId;
      var curves = this.$refs.signaturePad.toData();
      payload["curves"] = curves;
      this.$store.commit("updateContext_signature_8843", {
        signature: curves
      });
      return this.$store.getters.client.put("submit-signature/", payload);
    },
    continueSubmit() {
      this.errors = [];
      var isEmpty = this.$refs.signaturePad.isEmpty();
      if (isEmpty) {
        this.errors.push(`Please enter your signature before continuing`);
      }
      return !isEmpty;
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(direction) {
      return this.submitSignature(direction);
    },
    fetchTaxForms(type) {
      this.loading = true;
      return this.$store.getters.client
        .get(`get-year/${this.$store.getters.getTrId}/`)
        .then(resp => {
          this.$store.getters.client
            .get(`gen-pdfs/${this.$store.getters.getTrId}/`, {
              params: {
                return_type: type,
                get_instructions: false
              },
              responseType: "arraybuffer"
            })
            .then(response => {
              const blob = new Blob([response.data], {
                type: "application/pdf"
              });
              const link = document.createElement("a");
              // this.$refs.iframe.contentDocument.title = "newtitle!";
              link.href = URL.createObjectURL(blob);
              this.pdfUrl = link.href + "#toolbar=0&navpanes=0&scrollbar=0";
              var fileName = `Thriftax Review ${type} Tax Forms ${resp.data}.pdf`;
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
              this.loading = false;
            });
        });
    },
    displayCanvas() {
      this.renderCanvas = true;
      this.$nextTick(function() {
        var signature = this.context.signature;
        this.$refs.signaturePad.resizeCanvas();
        if (signature.length) {
          var data = JSON.parse(JSON.stringify(this.context.signature));
          this.$refs.signaturePad.fromData(data);
        }
      });
    }
  },
  mounted() {
    this.$refs.signaturePad.resizeCanvas();
    this.displayCanvas();
  }
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #ffa51f, #adadad);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: auto;
}
</style>
