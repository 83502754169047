<template>
    <div>
        <validation-observer ref="validator">
            <div class="interview-header">
                Here are all of the
                <strong>documents we've prepared for you</strong>
                <div class="interview-subheader">Please review your order</div>
            </div>
            <div class="review-card">
                <error-card :errors="errors" class="mb-3" />
                <div
                    class="mb-3"
                    v-for="opt in formsets.pricing_options"
                    :key="opt.context.price_id"
                >
                    <div
                        v-if="!opt.context.mandatory"
                        class="d-flex justify-content-between px-2 mb-2"
                    >
                        <div>
                            <div class="row">
                                <div
                                    class="pl-2 mr-0"
                                >
                                    <fly-input
                                        :data="opt.sections[0].data"
                                        :field="opt.sections[0].schema[0]"
                                    />
                                </div>

                                <h3 class="mb-0 font-weight-bold">
                                    {{ opt.context.name }}
                                </h3>
                            </div>
                        </div>
                        <h3 class="mb-0 p-0">
                            <template v-if="opt.context.amount">
                                ${{ opt.context.amount.toFixed(2) }}
                            </template>
                            <template v-else> FREE </template>
                        </h3>
                    </div>

                    <div v-else class="mb-2 px-2">
                        <div class="d-flex justify-content-between mb-2">
                            <h3 class="mb-0">
                                {{ opt.context.name }}
                            </h3>
                            <h3 class="font-weight-bold mb-0">
                                <template v-if="opt.context.amount">
                                    ${{ opt.context.amount.toFixed(2) }}
                                </template>
                                <template v-else> FREE </template>
                            </h3>
                        </div>
                    </div>
                    <div
                        v-if="
                            opt.context.subitems && opt.context.subitems.length
                        "
                    >
                        <div class="w-100 border-bottom mb-1"></div>
                        <ul>
                            <li
                                class="my-1"
                                v-for="subitem in opt.context.subitems"
                                :key="subitem"
                            >
                                {{ subitem }}
                            </li>
                        </ul>
                    </div>
                </div>
                <h2 class="d-flex justify-content-between mt-5">
                    <div>Total</div>
                    <div>${{ total.toFixed(2) }}</div>
                </h2>
            </div>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'checkout_options',
            errors: [],
        };
    },
    computed: {
        total() {
            var tot = 0;
            this.formsets.pricing_options.forEach((item) => {
                if (
                    item.context.mandatory ||
                    this.boolEq(true, item.sections[0].data.wanted)
                ) {
                    tot += item.context.amount;
                }
            });
            return tot;
        },
    },
    methods: {
        itemIsWanted(item) {
            return item.sections[0].data.wanted || item.context.mandatory;
        },
        continueSubmit() {
            this.errors = [];
            for (var item of this.formsets.pricing_options) {
                if (this.itemIsWanted(item)) {
                    return true;
                }
            }
            this.errors.push(`Please add at least one item before continuing`);
            return false;
        },
    },
};
</script>
