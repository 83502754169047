function isOrdered(date1Str, date2Str) {
	/* takes in two date strings and returns 1 if they're ordered, 0 otherwise */
	let date1 = new Date(date1Str);
	// let date1Parts = date1Str.split("/");
	// let date1 = new Date(date1Parts[2], date1Parts[0] - 1, date1Parts[1]);
	// let date2Parts = date2Str.split("/");
	// let date2 = new Date(date2Parts[2], date2Parts[0] - 1, date2Parts[1]);
	let date2 = new Date(date2Str);
	return date2 >= date1;
}

function beforeNow(date1Str) {
	// let date1Parts = date1Str.split("/");
	let date1 = new Date(date1Str);
	// let date1 = new Date(date1Parts[2], date1Parts[0] - 1, date1Parts[1]);
	let today = new Date();
	return date1 <= today;
}

export { isOrdered, beforeNow };
