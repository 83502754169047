import gen_pdfs from "./gen_pdfs";
import checkout from "./checkout";
import due_info from "./due_info";
import refund_info from "./refund_info";
import no_diff_return from "./no_diff_return";
import review_federal from "./review_federal";
import is_shipping_8843 from "./is_shipping_8843";
import shipping_info from "./shipping_info";
import signature_8843 from "./signature_8843";
import checkout_options from "./checkout_options";
import corresp_location from "./corresp_location";
import review_fica from "./review_fica";

export default {
	checkout: checkout,
	gen_pdfs: gen_pdfs,
	due_info: due_info,
	refund_info: refund_info,
	review_fica: review_fica,
	corresp_location: corresp_location,
	checkout_options: checkout_options,
	signature_8843: signature_8843,
	shipping_info: shipping_info,
	is_shipping_8843: is_shipping_8843,
	review_federal: review_federal,
	no_diff_return: no_diff_return,
};
