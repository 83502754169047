<template>
    <validation-observer ref="validator">
        <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        <fly-col :schema="sections[1].schema" :data="sections[1].data" />
        <h3 class="mb-3 mt-6">Boxes 1-7a</h3>
        <fly-col :schema="sections[2].schema" :data="sections[2].data" />
        <fly-col :schema="sections[3].schema" :data="sections[3].data" />
        <div class="row m-0">
            <div class="col-md p-0 fly-row-col-pr">
                <fly-col
                    :schema="sections[4].schema"
                    :data="sections[4].data"
                />
            </div>
            <div class="col-md fly-row-col-pl p-0">
                <fly-col
                    :schema="sections[5].schema"
                    :data="sections[5].data"
                />
            </div>
        </div>
        <fly-row :schema="sections[6].schema" :data="sections[6].data" />
        <h3 class="mb-3 mt-6">Boxes 12a-12d</h3>
        <fly-row :schema="sections[7].schema" :data="sections[7].data" />
        <fly-col :schema="sections[8].schema" :data="sections[8].data" />
        <h3 class="mb-1 pl-1 mt-6">Boxes 12h-12i</h3>
        <fly-col
            class="mb-3"
            :schema="sections[9].schema"
            :data="sections[9].data"
        />
        <p class="input-label mb-0 pb-0">
            <span class="h5 mb-0 pb-0">12i</span> City or town, state or
            province, ZIP or foreign postal code
        </p>
        <fly-row
            :fillTop="false"
            class="mt-0 pt-0"
            :schema="sections[10].schema"
            :data="sections[10].data"
        />
        <h3 class="mb-1 pl-1 mt-6">Boxes 13f-13g</h3>
        <fly-row :schema="sections[11].schema" :data="sections[11].data" />
        <h3 class="mb-1 pl-1 mt-6">Box 16a</h3>
        <fly-col :schema="sections[12].schema" :data="sections[12].data" />
        <h3 class="mb-1 pl-1 mt-6">Boxes 17a-17c</h3>
        <fly-row :schema="sections[13].schema" :data="sections[13].data" />

        <template v-if="showVisaQualifiers">
            <div
                class="border-bottom w-100 mb-4 mt-3"
                v-if="formsetsPresent(formsets)"
            ></div>

            <div
                v-if="
                    formsets.visa_clarifiers && formsets.visa_clarifiers.length
                "
            >
                <fly-col
                    v-for="vc in formsets.visa_clarifiers"
                    :key="`visa-clarifier-${vc.context.visa_type}`"
                    :data="vc.sections[0].data"
                    :schema="vc.sections[0].schema"
                >
                </fly-col>
            </div>
            <template v-if="showVisaQualifiersIncomeDifferenceError">
                <div
                    class="text-danger lead"
                    v-if="visaQualifiersIncomeDifference != 0"
                >
                    <i class="fas fa-warning mr-2"> </i> Please ensure that the
                    income made on all visas adds up to ${{ fedIncome }}
                </div>
            </template>
        </template>

        <modal v-model="unsupported">
            <template v-slot:title> We're sorry </template>
            <template v-slot:body>
                We don't support 1042-S documents for which the income code is
                not one of the following: 1, 6, 16, 18, 19, 20, 29. Please file
                offline.
            </template>
        </modal>
    </validation-observer>
</template>

<script>
import incomeMixin from './mixins';
import { strToNum } from '@/views/utilities';

export default {
    mixins: [incomeMixin],
    data() {
        return {
            unsupported: false,
            showVisaQualifiersIncomeDifferenceError: false,
        };
    },
    computed: {
        showVisaQualifiers() {
            if (
                !this.context.impacted_codes_for_treaties_changed_visas ||
                !this.context.impacted_codes_for_treaties_changed_visas.length
            ) {
                return false;
            }
            var code = this.sections[2].data.S1042_income_code;
            return this.context.impacted_codes_for_treaties_changed_visas.includes(
                code,
            );
        },
        visaQualifiersIncomeDifference() {
            if (
                !this.showVisaQualifiers ||
                !(
                    this.formsets.visa_clarifiers &&
                    this.formsets.visa_clarifiers.length
                )
            ) {
                return 0;
            }
            var income = this.fedIncome;
            var vc_amt = 0;
            for (var vc of this.formsets.visa_clarifiers) {
                vc_amt += strToNum(vc.sections[0].data.amt);
            }

            var diff = income - vc_amt;

            if (vc_amt < income) {
                if (Math.abs(diff) < 3) {
                    diff = 0;
                }
            }
            return diff;
        },
        fedIncome() {
            return this.sections[2].data.S1042_gross_income;
        },
    },
    methods: {
        incomeCodeValid() {
            return this.sections[2].data.S1042_income_code !== 'other';
        },
        visaQualifiersValid() {
            return this.visaQualifiersIncomeDifference === 0;
        },
        runCustomValidation(payload, shouldValidate) {
            if (shouldValidate) {
                this.unsupported = false;
                if (!this.incomeCodeValid()) {
                    this.unsupported = true;
                    return false;
                }
                if (!this.visaQualifiersValid()) {
                    this.showVisaQualifiersIncomeDifferenceError = true;
                    return false;
                }
            } else {
                payload.is_completed =
                    payload.is_completed &&
                    this.incomeCodeValid() &&
                    this.visaQualifiersValid();
            }
            return true;
        },
    },
    mounted() {
        this.$watch('sections.2.data.S1042_income_code', (newVal) => {
            if (newVal === 'other') {
                this.unsupported = true;
            }
        });
    },
};
</script>
