import intro from './intro';
import is_US_citizen from './is_US_citizen';
import has_green_card from './has_green_card';
import applied_for_green_card from './applied_for_green_card';
import green_card_application_status from './green_card_application_status';
import had_US_income from './had_US_income.vue';
import is_married from './is_married.vue';
import has_dependents from './has_dependents.vue';
import is_dependent from './is_dependent.vue';
import is_US_scholar from './is_US_scholar.vue';
import paid_interest_on_student_loan from './paid_interest_on_student_loan.vue';
import gave_charity from './gave_charity.vue';
import filed_taxes_prev_year from './filed_taxes_prev_year.vue';
import filed_state_taxes_prev_year from './filed_state_taxes_prev_year.vue';

export default {
    intro: intro,
    is_US_citizen: is_US_citizen,
    has_green_card: has_green_card,
    applied_for_green_card: applied_for_green_card,
    green_card_application_status: green_card_application_status,
    had_US_income: had_US_income,
    is_married: is_married,
    has_dependents: has_dependents,
    is_dependent: is_dependent,
    is_US_scholar: is_US_scholar,
    paid_interest_on_student_loan: paid_interest_on_student_loan,
    gave_charity: gave_charity,
    filed_taxes_prev_year: filed_taxes_prev_year,
    filed_state_taxes_prev_year: filed_state_taxes_prev_year,
};
