<template>
    <div>
        <div class="interview-header">
            FICA Refund
        </div>

        <div class="review-card">
            <div class="d-flex justify-content-between mb-3">
                <h3 class="text-success">Your FICA Refund</h3>
                <h3>{{ formatDollarAmount(context.total) }}</h3>
            </div>

            <p class="text-center lead mb-0 font-weight-bold">Income Forms Breakdown</p>
            <p class="text-center mb-3">
                Here are all of the sources of income for which your employer
                erroneously withheld social security or Medicare taxes
            </p>
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th>W-2 Document</th>
                        <th scope="col" class="w-50">FICA Taxes Withheld</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="fica in context.ficas" :key="fica.employer">
                        <th>{{ fica.employer }}</th>
                        <td>
                            {{ formatDollarAmount(fica.amt) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <validation-observer ref="validator"> </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'review_fica',
            noFormSubmit: true,
        };
    },
};
</script>
