<template>
    <div>
        <btn @click="showModal = true" app size="sm">
            <i class="fas fa-file-upload mr-1"></i>
            /
            <i class="fas fa-camera mx-1"></i>
            Scan My {{ docType }}</btn
        >
        <modal v-model="showModal" :click-outside="false" size="lg">
            <template v-slot:title v-if="modalView !== DECIDE_IF_SCAN_VIEW">
                Scan {{ docType }}
            </template>
            <template v-slot:body>
                <template v-if="modalView === CHOOSE_SCAN_METHOD_VIEW">
                    <btn @click="goToFileUploadView" app block color="info">
                        <i class="fas fa-file-upload"></i> Upload From This
                        Device
                        <div class="small">
                            Upload a PDF, JPG, or PNG file of your
                            {{ docType }} from this device
                        </div>
                    </btn>
                    <btn @click="goToTakePhotoView" app block color="info">
                        <i class="fas fa-camera"></i>
                        Take Photo on Mobile Device
                        <div class="small">
                            Scan a secure QR code with your mobile device and
                            take a picture of your {{ docType }}
                        </div>
                    </btn>
                </template>
                <template v-if="modalView === TAKE_PHOTO_VIEW">
                    <div class="text-center">
                        <h3 class="mb-3">
                            Scan the QR code below with your phone's camera
                        </h3>
                        <div class="d-flex justify-content-center mb-3">
                            <loading :size="75" v-if="QRCodeLoading" />
                            <qrcode-vue
                                v-else
                                class="mx-auto"
                                :value="QRCodeLink"
                                :size="150"
                            />
                        </div>
                        <p class="mb-0">
                            You'll be taken to a secure, temporary webpage on
                            our mobile site where you'll be able to take a photo
                            of your {{ docType }} <br />
                            <br />The code expires in <strong>5 minutes</strong>
                        </p>
                        <error-card class="mt-3" v-if="scanStatus === FAILURE">
                            The code above has expired.
                            <a @click="goToTakePhotoView" class="link"
                                >Click here to get a new code</a
                            >
                        </error-card>
                    </div>
                </template>
                <template v-if="modalView === SCAN_COMPLETED_VIEW">
                    <slot name="completion-view-body" :scan-method="scanMethod">
                        completed
                    </slot>
                </template>
                <template v-if="modalView === DECIDE_IF_SCAN_VIEW">
                    <div class="text-center w-lg-75 mx-auto">
                        <div class="interview-header-3 font-weight-bold">
                            How do you want to add your {{ docType }}?
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <btn @click="modalView = CHOOSE_SCAN_METHOD_VIEW" class="lg-icon-btn" block>
                                    <div class="btn-body">
                                        <div class="main-icon">
                                            <mdi>document_scanner</mdi>
                                        </div>
                                        Scan my {{ docType }}
                                    </div>
                                </btn>
                            </div>
                            <div  @click="showModal = false" class="col text-center">
                                <btn class="lg-icon-btn text-center" block>
                                    <div class="main-icon text-center">
                                        <mdi>keyboard</mdi>
                                    </div>
                                    I'll enter it myself
                                </btn>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="modalView === FILE_UPLOAD_VIEW">
                    <div>
                        When uploading your {{ docType }}, keep in mind:
                        <ol>
                            <li>
                                Uploaded PDF files should only contain one page
                            </li>
                            <li>
                                If submitting an image, make sure your
                                {{ docType }} is flat and has good lighting
                            </li>
                        </ol>
                    </div>
                    <div class="dz-dropzone dz-dropzone-boxed">
                        <input
                            type="file"
                            class="file-upload"
                            @change="uploadFile($event.target.files[0])"
                            single
                        />
                        <div class="dz-message p-2">
                            <template v-if="file">
                                <span class="d-block mb-1"
                                    >{{ file.name }}
                                </span>
                                <span class="d-block mb-1">
                                    <template
                                        v-if="file.type === 'application/pdf'"
                                    >
                                        <i class="fas fa-file-alt fa-3x"></i>
                                    </template>
                                    <template
                                        v-if="
                                            file.type === 'image/jpeg' ||
                                            file.type === 'image/png'
                                        "
                                    >
                                        <i class="fas fa-image fa-3x"></i>
                                    </template>
                                </span>
                            </template>
                            <template v-else>
                                <span class="d-block mb-1"
                                    ><i class="fas fa-file-upload fa-2x"></i
                                ></span>
                                <span class="d-block mb-1"
                                    >Browse / Drop File</span
                                >
                                <small class="d-block text-muted"
                                    >Accepted: JPG, PNG, PDF</small
                                >
                            </template>
                        </div>
                    </div>
                    <error-card class="mt-3" :errors="uploadErrors" />
                </template>
            </template>
            <template v-slot:footer>
                <template v-if="modalView === SCAN_COMPLETED_VIEW">
                    <btn
                        @click="$emit('completion-acknowledged')"
                        app
                        size="sm"
                    >
                        Okay
                    </btn>
                </template>
                <template v-else-if="modalView !== DECIDE_IF_SCAN_VIEW">
                    <div class="d-flex justify-content-between w-100">
                        <btn
                            v-if="modalView !== CHOOSE_SCAN_METHOD_VIEW"
                            @click="goToPreviousView"
                            app
                            size="sm"
                            color="info"
                        >
                            Back
                        </btn>
                        <div v-else></div>
                        <div>
                            <btn
                                v-if="modalView === CHOOSE_SCAN_METHOD_VIEW"
                                @click="showModal = false"
                                app
                                size="sm"
                                color="info"
                            >
                                Close
                            </btn>
                            <btn
                                v-if="modalView === FILE_UPLOAD_VIEW"
                                @click="submit"
                                app
                                size="sm"
                                color="primary"
                                :loading="submitLoading"
                                :disabled="!file"
                            >
                                Submit
                            </btn>
                        </div>
                    </div>
                </template>
                <template v-else> <div></div> </template>
            </template>
        </modal>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

const FAILURE = 0;
const SUCCESS = 1;

const FILE_UPLOAD = 1;
const TAKE_PHOTO = 2;

const FILE_UPLOAD_VIEW = FILE_UPLOAD;
const TAKE_PHOTO_VIEW = TAKE_PHOTO;
const CHOOSE_SCAN_METHOD_VIEW = 3;
const SCAN_COMPLETED_VIEW = 4;
const MOBILE_TASK_STATUS_CHECK_INTERVAL = 5; // seconds
const DECIDE_IF_SCAN_VIEW = 5;

export default {
    components: { QrcodeVue },
    props: {
        onSubmit: {
            type: Function,
        },
        getQRCodeLink: {
            type: Function,
        },
        getMobileTaskStatus: {
            type: Function,
        },
        docType: {
            type: String,
        },
    },
    data() {
        return {
            showModal: false,
            modalView: CHOOSE_SCAN_METHOD_VIEW,
            file: null,
            submitLoading: false,
            QRCodeLink: '',
            QRCodeLoading: true,
            scanMethod: null,
            scanStatus: SUCCESS,
            uploadErrors: [],
        };
    },
    beforeDestroy() {
        this.clearMobileTaskChecker();
    },
    methods: {
        showDecideIfScanView() {
            this.modalView = DECIDE_IF_SCAN_VIEW;
            this.showModal = true;
        },
        displayMobileTaskSuccess() {
            this.modalView = SCAN_COMPLETED_VIEW;
            if (!this.showModal) {
                this.showModal = true;
            }
        },
        clearMobileTaskChecker() {
            if (this.mobileTaskChecker != null) {
                clearInterval(this.mobileTaskChecker);
            }
        },
        initiateMobileTaskChecker() {
            this.scanStatus = SUCCESS;
            this.clearMobileTaskChecker();
            this.mobileTaskChecker = setInterval(() => {
                this.getMobileTaskStatus()
                    .then((isCompleted) => {
                        if (isCompleted) {
                            this.modalView = SCAN_COMPLETED_VIEW;
                        }
                    })
                    .catch((err) => {
                        this.scanStatus = FAILURE;
                        this.clearMobileTaskChecker();
                    });
            }, 1000 * MOBILE_TASK_STATUS_CHECK_INTERVAL);
        },
        goToFileUploadView() {
            this.modalView = FILE_UPLOAD_VIEW;
            this.scanMethod = FILE_UPLOAD;
        },
        async goToTakePhotoView() {
            this.modalView = TAKE_PHOTO_VIEW;
            this.scanMethod = TAKE_PHOTO;
            this.QRCodeLoading = true;
            this.QRCodeLink = await this.getQRCodeLink();
            console.log(this.QRCodeLink);
            this.QRCodeLoading = false;
            this.initiateMobileTaskChecker();
        },
        goToPreviousView() {
            if (
                this.modalView === FILE_UPLOAD_VIEW ||
                this.modalView === TAKE_PHOTO_VIEW
            ) {
                this.modalView = CHOOSE_SCAN_METHOD_VIEW;
            }
        },
        uploadFile(file) {
            this.file = file;
        },
        async submit() {
            this.uploadErrors = [];
            try {
                this.submitLoading = true;
                await this.onSubmit(this.file);
                this.modalView = SCAN_COMPLETED_VIEW;
                this.submitLoading = false;
            } catch (error) {
                this.submitLoading = false;
                this.uploadErrors = error.response.data;
            }
        },
    },
    created() {
        this.SUCCESS = SUCCESS;
        this.FAILURE = FAILURE;
        this.FILE_UPLOAD = FILE_UPLOAD;
        this.TAKE_PHOTO = TAKE_PHOTO;
        this.FILE_UPLOAD_VIEW = FILE_UPLOAD_VIEW;
        this.TAKE_PHOTO_VIEW = TAKE_PHOTO_VIEW;
        this.CHOOSE_SCAN_METHOD_VIEW = CHOOSE_SCAN_METHOD_VIEW;
        this.SCAN_COMPLETED_VIEW = SCAN_COMPLETED_VIEW;
        this.DECIDE_IF_SCAN_VIEW = DECIDE_IF_SCAN_VIEW;
        this.mobileTaskChecker = null;
    },
};

export { FILE_UPLOAD, TAKE_PHOTO };
</script>
