<template>
    <div>
        <div class="mb-7">
            <div class="interview-header">
                What's your <strong> mailing address</strong>?

                <div class="interview-subheader font-size-base">
                    <template v-if="context.has_refund">
                        You chose to receive your {{ context.year }} tax
                        refund(s) by a check that will be mailed to you. This
                        address will also be used for any future communication
                        by the IRS, if necessary.
                    </template>

                    <template v-else>
                        It is required by the IRS that you provide an address
                        that you can be reached at for any future communication,
                        if necessary.
                    </template>
                    <br />
                    <br />
                    Please choose below which of your addresses you would like
                    mail regarding your {{ context.year }} tax return to be
                    sent.
                </div>
            </div>
        </div>
        <validation-observer ref="validator">
            <div class="d-flex justify-content-center">
                <fly-input
                    :field="sections[0].schema[0]"
                    :data="sections[0].data"
                />
            </div>
        </validation-observer>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'corresp_location',
        };
    },
    created() {
        this.sections[0].schema[0].larger = true;
    },
};
</script>

<style>
.squished_radio_container_corresp {
    width: 290px;
}
</style>
