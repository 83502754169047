<template>
    <div>
        <div class="interview-header">
            Let's see if you're a
            <strong>California resident</strong>
        </div>
        <validation-observer ref="validator">
            <fly-col :schema="sections[0].schema" :data="sections[0].data" />
        </validation-observer>

        <modal v-model="showJointModal">
            <template v-slot:title> Filing Jointly With Your Spouse </template>

            <template v-slot:body>
                Since your spouse filed their CA tax return with the status of
                married filing jointly, your CA tax return is already included
                in your spouse's joint return. Thus, you are not required to
                file a CA tax return, and may click "Close" to continue filing
                the rest of your tax return.

                <br />
                <br />

                Answered yes by mistake? Click "Close" and change your answer.
            </template>
        </modal>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'california_residency',
            showJointModal: false,
        };
    },
    watch: {
        'sections.0.data': {
            handler(val) {
                var index = -1;
                for (var i = 0; i < this.sections[0].schema.length; i++) {
                    if (
                        this.sections[0].schema[i].name ==
                        'spouse_intention_to_reside'
                    ) {
                        index = i;
                        break;
                    }
                }
                if (index != -1) {
                    var label;
                    if (
                        this.boolEq(false, val.cali_primary_address) &&
                        this.boolEq(false, val.cali_H_visa_location)
                    ) {
                        label = this.context.hard_resident;
                        this.sections[0].schema[index].label = label;
                    } else if (
                        'cali_vehicle_registered' in this.sections[0].data
                    ) {
                        label = this.context.easy_resident;
                        this.sections[0].schema[index].label = label;
                    }
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (
            'spouse_is_filing_jointly_in_other_state' in this.sections[0].data
        ) {
            this.$watch(
                'sections.0.data.spouse_is_filing_jointly_in_other_state',
                (val) => {
                    if (this.boolEq(true, val)) {
                        this.showJointModal = true;
                    } else {
                        this.showJointModal = false;
                    }
                },
            );
        }
    },
};
</script>
