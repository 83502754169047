<template>
    <div>
        <div class="interview-header">
            How much
            <strong>time </strong>did you spend <strong>in each state</strong>?

            <div class="interview-subheader">
                In {{ context.year }}, you spent
                <strong>
                    {{ context.days_in_US_this_year }}
                </strong>
                days in the U.S. Please enter below the number of days you lived
                in each state in {{ context.year }}.
            </div>
        </div>
        <validation-observer ref="validator">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="w-60">State</th>
                        <th scope="col" class="w-35">Days</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(visit, index) in stateVisitsFiltered"
                        :key="visit.displayId"
                    >
                        <td class="align-top">
                            <fly-row
                                no-margin-bottom
                                :data="visit.sections[0].data"
                                :schema="[visit.sections[0].schema[0]]"
                            />
                        </td>
                        <td class="align-top">
                            <fly-row
                                no-margin-bottom
                                :data="visit.sections[0].data"
                                :schema="[visit.sections[0].schema[3]]"
                            />
                        </td>
                        <td>
                            <fs-delete
                                v-if="index != 0"
                                @click="dirty = true"
                                v-model="visit.delete"
                            />
                            <label v-else>&shy;</label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div class="col-sm mb-3">
                    <btn
                        disable-on-forms-purchased
                        @click="addForm"
                        app
                        size="sm"
                        color="info"
                        ><i class="fas fa-plus mr-2"></i> Add State</btn
                    >
                </div>
                <div class="col-sm d-sm-flex justify-content-end">
                    <div>
                        <span class="highlight-icon">
                            <span class="pr-3"> Total: {{ totalDays }}</span>
                            <span
                                >Target:
                                {{ context.days_in_US_this_year }}

                                <tooltip>
                                    <i class="fas fa-info-circle"></i>

                                    <template v-slot:content>
                                        The target number of days is based on
                                        your time spent in the U.S. which we
                                        calculated according to your visits from
                                        the Federal-Residency section.
                                    </template>
                                </tooltip>
                            </span>
                        </span>
                        <div
                            v-if="totalDays < context.days_in_US_this_year"
                            class="d-flex text-danger"
                        >
                            You still need to add
                            {{ context.days_in_US_this_year - totalDays }}
                            more days.
                        </div>
                        <div
                            v-else-if="totalDays > context.days_in_US_this_year"
                            class="d-flex text-danger"
                        >
                            You have
                            {{ totalDays - context.days_in_US_this_year }}
                            too many days.
                        </div>
                        <div class="text-success" v-else>Looks good!</div>
                    </div>
                </div>
            </div>

            <modal v-model="shouldShowExtraStatesRequiredModal">
                <template v-slot:title> Other State Returns Required </template>

                <template v-slot:body>
                    Based on your income, you are required to file a state tax
                    return for {{ otherStates }}, in addition to the tax return
                    that Thriftax creates for you.
                </template>

                <template v-slot:footer>
                    <btn
                        @click="closeExtraStatesModal()"
                        app
                        size="sm"
                        color="secondary"
                    >
                        Close
                    </btn>
                </template>
            </modal>
        </validation-observer>
    </div>
</template>

<script>
import { addFormsetItem, filterDeletedForms } from '@/views/interview/formset';
import baseInterviewMixin from '@/views/interview/mixins';
import { strToNum } from '@/views/utilities';

export default {
    mixins: [baseInterviewMixin],
    data() {
        return {
            pageName: 'state_visits',
            acknowledgesOtherStateReturn: false,
            shouldShowExtraStatesRequiredModal: false,
            otherStates: null,
        };
    },
    computed: {
        stateVisitsFiltered() {
            return filterDeletedForms({ formset: this.formsets.state_visits });
        },
        totalDays() {
            let total = 0;
            this.stateVisitsFiltered.forEach((visit) => {
                if (visit.sections[0].data.days_present) {
                    total += strToNum(visit.sections[0].data.days_present);
                }
            });
            return total;
        },
        backendErrors() {
            return this.$store.getters.backendErrors_state_visits;
        },
    },

    methods: {
        getSwitchDirection() {
            return 'jump';
        },
        closeExtraStatesModal() {
            this.shouldShowExtraStatesRequiredModal = false;
            this.$store.commit('updateLastCompletedPage', 'state_visits');
            this.$store.dispatch('moveWithoutSubmitting', {
                shouldSubmit: this.isDirty(),
                direction: 'next',
            });
        },
        addForm() {
            addFormsetItem(this.formsets.state_visits);
        },

        runCustomIsDirty() {
            return this.dirty || this.backendErrors.length;
        },
        runCustomValidation() {
            return this.totalDays === this.context.days_in_US_this_year;
        },
        postSubmit(params) {
            var response = params.response;
            if (response.data.other_state_returns_required) {
                if (!this.acknowledgesOtherStateReturn) {
                    var error = {
                        code: 'need_other_return_states',
                        message: response.data.other_state_returns_required,
                    };
                    this.$store.commit(`addError_state_visits`, error);
                    this.$store.commit('changeLoading', false);
                    return { continue: false };
                }
            }
        },
    },
    created() {
        this.backendErrors.forEach((error) => {
            if (error.code == 'need_other_return_states') {
                this.otherStates = error.message;
                this.shouldShowExtraStatesRequiredModal = true;
                this.dirty = true;
            }
        });
        this.$store.commit('clearErrors_state_visits');
    },
};
</script>

<style scoped>
.fancy-checkbox input[type='checkbox'] {
    display: none;
}
.fancy-checkbox {
    cursor: pointer;
    font-size: 19px;
}
.visit_row {
    margin-bottom: 2px !important;
}
</style>
