<template>
    <div>
        <div class="interview-header">
            Did you
            <strong> hold a U.S. green card</strong>
            in {{ context.year }}?
            <header-tooltip>
                I have an open application for a green card
                <template v-slot:content>
                    If your current Green Card application status is either
                    denied or pending, select "No".
                </template>
            </header-tooltip>
        </div>

        <validation-observer ref="validator">
            <fly-col
                :schema="sections[0].schema"
                :data="sections[0].data"
            ></fly-col>
        </validation-observer>

        <modal v-model="unsupported">
            <template v-slot:title> We're Sorry </template>
            <template v-slot:body>
                Thriftax does not support tax filing for green card holders and
                US residents.
                <br />
                <br />
                Answered yes by mistake? Click "Close" and change your answer.
            </template>
        </modal>
    </div>
</template>

<script>
import baseInterviewMixin from '@/views/interview/mixins';
import { chunkyRadioPageMixin } from '@/views/interview/mixins';

export default {
    mixins: [baseInterviewMixin, chunkyRadioPageMixin],
    data() {
        return {
            pageName: 'has_green_card',
            unsupported: false,
        };
    },
    methods: {
        continueSubmit() {
            var has_green_card = this.sections[0].data.has_green_card;
            if (has_green_card === 'true' || has_green_card === true) {
                this.unsupported = true;
                return false;
            }
            return true;
        },
    },
};
</script>
